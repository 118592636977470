import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';

import {SharedModule} from '../shared/shared.module';

import {UserMapperService} from './mapper/user-mapper.service';
import {UserApiService} from './services/user-api.service';

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
  ],
  providers: [
    UserApiService,
    UserMapperService,
  ],
})
export class UserApiModule {
}

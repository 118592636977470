import { SearchCriteriaPageableDto } from '../../shared/classes/search-criteria-pageable-dto';
import { TaskStatus } from '../enums/task-status.enum';

export class TaskSearchCriteriaDto extends SearchCriteriaPageableDto {
  public beginFrom: Date;
  public beginTo: Date;
  public deadlineFrom: Date;
  public deadlineTo: Date;
  public ids: string[];
  public status: TaskStatus;
  public targetAssetIds: string[];
  public myTasks: boolean;
  public userGroupIds: string[];
  public partialAssetName: string;

  public constructor(taskSearchCriteria?: Partial<TaskSearchCriteriaDto>) {
    super(taskSearchCriteria);
    Object.assign(this, taskSearchCriteria);
  }
}

<div [formGroup]="form" class="mat-caption mt-12 mb-4 text-center">
  <ng-container *ngIf="!phoenixFormControl.options['callback']">
    <button (click)="buttonCallback(phoenixFormControl.options['type']?phoenixFormControl.options['style']:'submit')"
      *ngIf="!phoenixFormControl.options['style']" [color]="buttonColor"
      [disabled]="phoenixFormControl.disabled || phoenixFormControl.options['disabled']" mat-button type="submit">
      <mat-spinner *ngIf="showSpinner" [diameter]="20"></mat-spinner>
      {{ phoenixFormControl.label | translate }}
    </button>
    <button (click)="buttonCallback(phoenixFormControl.options['type']?phoenixFormControl.options['style']:'submit')"
      *ngIf="phoenixFormControl.options['style'] === 'raised'" [color]="buttonColor"
      [disabled]="phoenixFormControl.disabled || phoenixFormControl.options['disabled']" mat-raised-button
      type="submit">
      <mat-spinner *ngIf="showSpinner" [diameter]="20"></mat-spinner>
      {{ phoenixFormControl.label | translate }}
    </button>
    <button (click)="buttonCallback(phoenixFormControl.options['type']?phoenixFormControl.options['style']:'submit')"
      *ngIf="phoenixFormControl.options['style'] === 'mini-fab-icon'" [color]="buttonColor"
      [disabled]="phoenixFormControl.disabled || phoenixFormControl.options['disabled']" mat-mini-fab class="mini-fab"
      type="submit">
      <mat-icon [inline]="true" [ngStyle]="getIconStyle()" class="mini-fab-icon notranslate">{{ phoenixFormControl.value
        }}
      </mat-icon>
    </button>
  </ng-container>
  <ng-container *ngIf="phoenixFormControl.options['callback']">
    <button (click)="callbackFromOptions()" *ngIf="!phoenixFormControl.options['style']" [color]="buttonColor"
      [disabled]="phoenixFormControl.disabled || phoenixFormControl.options['disabled']" mat-button type="button">
      <mat-spinner *ngIf="showSpinner" [diameter]="20"></mat-spinner>
      {{ phoenixFormControl.label | translate }}
    </button>
    <button (click)="callbackFromOptions()" *ngIf="phoenixFormControl.options['style'] === 'raised'"
      [color]="buttonColor" [disabled]="phoenixFormControl.disabled || phoenixFormControl.options['disabled']"
      mat-raised-button type="button">
      <mat-spinner *ngIf="showSpinner" [diameter]="20"></mat-spinner>
      {{ phoenixFormControl.label | translate }}
    </button>
    <button (click)="callbackFromOptions()" *ngIf="phoenixFormControl.options['style'] === 'mini-fab-icon'"
      [color]="buttonColor" [disabled]="phoenixFormControl.disabled || phoenixFormControl.options['disabled']"
      mat-mini-fab class="mini-fab" type="button">
      <mat-icon [inline]="true" [ngStyle]="getIconStyle()" class="mini-fab-icon notranslate">{{ phoenixFormControl.value
        }}
      </mat-icon>
    </button>
  </ng-container>
  <div *ngIf="phoenixFormControl.options['hint']" class="mat-hint">
    {{ phoenixFormControl.options['hint'] | translate }}
  </div>
</div>
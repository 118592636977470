import { MatDialogRef } from '@angular/material/dialog';

import { PhoenixDialogComponent } from '../phoenix-dialog/phoenix-dialog.component';

export class PhoenixDialogResult {
  public dialogRef: MatDialogRef<PhoenixDialogComponent>;
  public formData: Object;
  public result: string;
  public spinner: boolean;
}

import {FileAttachmentDto} from './file-attachment-dto';
import {UrlAttachmentDto} from './url-attachment-dto';

export class AttachmentsDto {
  public files: FileAttachmentDto[];
  public urls: UrlAttachmentDto[];

  public constructor(attachmentsDto?: Partial<AttachmentsDto>) {
    Object.assign(this, attachmentsDto);
  }
}

import {Component, Input} from '@angular/core';

import {PhoenixFormDropdown} from './classes/phoenix-form-dropdown';

@Component({
  selector: 'phoenix-form-dropdown',
  templateUrl: './phoenix-form-dropdown.component.html',
  styleUrls: ['./phoenix-form-dropdown.component.scss'],
})
export class PhoenixFormDropdownComponent {
  // tslint:disable-next-line
  @Input() public element: PhoenixFormDropdown<any>;
}

import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {tap} from 'rxjs/operators';

import {PhoenixSnackbarService} from '../../@phoenix/components/phoenix-snackbar/phoenix-snackbar.service';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {

  public constructor(
    private snackBarService: PhoenixSnackbarService,
  ) {
  }

  public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      tap(() => {
      }, (event: HttpEvent<any>) => {
        if (event instanceof HttpErrorResponse) {
          if (req.method == 'DELETE' && req.url.startsWith('/api/users/user/')) {
            let pathSegments = req.url.split('/');
            if (pathSegments.length > 5 && pathSegments[5] != 'tenant') {
              this.snackBarService.openPhoenixHttpErrorSnackbar(event);
            }
          } else {
            this.snackBarService.openPhoenixHttpErrorSnackbar(event);
          }
        }
      }));
  }
}

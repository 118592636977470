import {Injectable} from '@angular/core';
import {plainToClass} from 'class-transformer';

import {StatisticsSearchCriteriaDto} from '../../dto.module';
import {StatisticsSearchCriteria} from '../api/statistics-search-criteria';

@Injectable({
  providedIn: 'root',
})
export class StatisticsMapperService {

  public constructor() {
  }

  public statisticsSearchCriteriaDtoToStatisticsSearchCriteria(statisticsSearchCriteriaDto: StatisticsSearchCriteriaDto): StatisticsSearchCriteria {
    return plainToClass(StatisticsSearchCriteria, statisticsSearchCriteriaDto);
  }
}

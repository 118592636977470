import {ValueTypeDto} from './value-type-dto.enum';

export class MeasurementValueTypeDto {
  public id: string = undefined;
  public key: string = undefined;
  public name: string = undefined;
  public type: ValueTypeDto = undefined;
  public defaultValue: string = undefined;
  public unit: string = undefined;
  public children: MeasurementValueTypeDto[] = undefined;

  public constructor(measurementValueTypeDto?: Partial<MeasurementValueTypeDto>) {
    Object.assign(this, measurementValueTypeDto);
  }
}

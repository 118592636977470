import {isValid} from 'date-fns';

import {SearchCriteriaPageable} from '../../shared/classes/search-criteria-pageable';

import {AlarmStatus} from './alarm-status.enum';

export class AlarmSearchCriteria extends SearchCriteriaPageable {
  public alarmId?: string = undefined;
  public dateFrom?: Date = undefined;
  public assetIds?: string[] = undefined;
  public dateTo?: Date = undefined;
  public statuses?: AlarmStatus[] = undefined;
  public userIds?: number[] = undefined;

  public constructor(alarmSearchCriteria?: Partial<AlarmSearchCriteria>) {
    super(alarmSearchCriteria);
    Object.assign(this, alarmSearchCriteria);
  }

  public toJSON(): Object {
    return {
      alarmId: this.alarmId,
      dateFrom: (this.dateFrom && isValid(this.dateFrom)) ? this.dateFrom.toISOString() : undefined,
      dateTo: (this.dateTo && isValid(this.dateTo)) ? this.dateTo.toISOString() : undefined,
      statuses: this.statuses,
      userIds: this.userIds,
      assetIds: this.assetIds,
      page: this.page,
      size: this.size,
    };
  }
}

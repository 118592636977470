import {ExtendedInformationDto} from './extended-information-dto';
import {MeasurementValueTypeDto} from './measurement-value-type-dto';

export class MeasurementTypeDto {
  public description: string = undefined;
  public extendedInformations: ExtendedInformationDto[] = undefined;
  public id: string = undefined;
  public key: string = undefined;
  public name: string = undefined;
  public tags: string[] = undefined;
  public valueTypes: MeasurementValueTypeDto[] = undefined;
  public version: string = undefined;
  public beginDate: Date = undefined;
  public endDate: Date = undefined;

  public constructor(measurementTypeDto?: Partial<MeasurementTypeDto>) {
    Object.assign(this, measurementTypeDto);
  }
}

import { Group } from '../../group/api/group';
import { JsonExportable } from '../../shared/classes/json-exportable';

export class TenantUpdateRequest implements JsonExportable {
  public name: string = undefined;
  public description?: string = undefined;
  public roles: string[] = undefined;
  public providers: string[] = undefined;
  public sensorTypeThreshold: string = undefined;
  public mobileSensorCheckInterval: string = undefined;
  public stationarySensorCheckInterval: string = undefined;

  public constructor(tenantRequest?: Partial<TenantUpdateRequest>) {
    Object.assign(this, tenantRequest);
  }

  public toJSON(): Object {
    return {
      name: this.name,
      roles: this.roles,
      description: this.description,
      providers: this.providers,
      sensorTypeThreshold: this.sensorTypeThreshold,
      mobileSensorCheckInterval: this.mobileSensorCheckInterval,
      stationarySensorCheckInterval: this.stationarySensorCheckInterval,
    };
  }
}

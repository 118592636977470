export enum AssetTags {
  automatic = 'automatic',
  counter = 'counter',
  task = 'task',
  manual = 'manual',
  checklist = 'checklist',
  asset = 'asset',
  checkpoint = 'checkpoint',
  measurementPoint = 'measurementPoint',
  sensor = 'sensor',
  tenant = 'tenant',
  thresholdId = 'thresholdId',
  dynamic = 'dynamic'
}

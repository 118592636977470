import { Group } from '@phoenix/gapicon/group/api/group';
import { TaskConfigRepeat } from './task-config-repeat';

export class TaskConfig {
  public createdDate: Date;
  public duration: number;
  public id: string;
  public repeat: TaskConfigRepeat;
  public startDate: Date;
  public timezone: string;
  public assignedUserGroupIds: string[] = [];

  public constructor(partial?: Partial<TaskConfig>) {
    Object.assign(this, partial);
  }
}

import {Component} from '@angular/core';

@Component({
  selector: 'phoenix-page-header',
  templateUrl: './phoenix-page-header.component.html',
  styleUrls: ['./phoenix-page-header.component.scss'],
})
export class PhoenixPageHeaderComponent {

  public constructor() {
  }


}

import { HttpParams } from '@angular/common/http';
import * as _ from 'lodash-es';

import { JsonExportable } from './json-exportable';

export abstract class SearchCriteria implements JsonExportable {

  protected constructor(searchCriteria?: Partial<SearchCriteria>) {
    Object.assign(this, searchCriteria);
  }

  public getRequestQueryParams(): HttpParams {
    const json: Object = this.toJSON();
    let params: HttpParams = new HttpParams();


    for (const key of Object.keys(json)) {
      let value: any = json[key];

      if (_.isArray(value)) {
        value = value.join(',');
      }

      if (!_.isNil(value)) {
        params = params.append(key, value);
      }
    }
    return params;
  }

  public abstract toJSON(): Object;
}

import {JsonExportable} from '../../shared/classes/json-exportable';

export class Property implements JsonExportable {
  public id: number = undefined;
  public name: string = undefined;
  public description: string = undefined;
  public value: string = undefined;
  public type: string = undefined;

  public constructor(property?: Partial<Property>) {
    Object.assign(this, property);
  }

  public toJSON(): Object {
    return {
      id: this.id,
      name: this.name,
      description: this.description,
      value: this.value,
      type: this.type,
    };
  }
}

import { SearchCriteriaPageableDto } from '../../shared/classes/search-criteria-pageable-dto';

export class UserSearchCriteriaDto extends SearchCriteriaPageableDto {
  public firstname: string = undefined;
  public lastname: string = undefined;
  public email: string = undefined;
  public company: string = undefined;
  public role: string = undefined;
  public name: string = undefined;

  public constructor(userSearchCriteriaDto?: Partial<UserSearchCriteriaDto>) {
    super(userSearchCriteriaDto);
    Object.assign(this, userSearchCriteriaDto);
  }
}

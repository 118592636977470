import {SensorConfigValueDto} from './sensor-config-value-dto';

export class SensorTypeDto {
  public id: string = undefined;
  public configurations: SensorConfigValueDto[] = undefined;
  public registrationParams: SensorConfigValueDto[] = undefined;
  public measurementTypes: string[] = undefined;
  public name: string = undefined;
  public key: string = undefined;

  public constructor(sensorType?: Partial<SensorTypeDto>) {
    Object.assign(this, sensorType);
  }
}

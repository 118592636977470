import { Injectable } from '@angular/core';
import { plainToClass } from 'class-transformer';
import * as _ from 'lodash-es';

import { AssetDto, AssetSearchCriteriaDto, FileAttachmentDto, TaskConfigDto, TaskConfigRepeatDto, TaskConfigRepeatTypeDto, UrlAttachmentDto } from '../../dto.module';
import { LatestMeasurementMapperService } from '../../measurement/mapper/latest-measurement-mapper.service';
import { PropertyMapperService } from '../../property/mapper/property-mapper.service';
import { SensorMapperService } from '../../sensor/mapper/sensor-mapper.service';
import { Asset } from '../api/asset';
import { AssetSearchCriteria } from '../api/asset-search-criteria';
import { AssetStatistics } from '../api/asset-statistics';
import { Attachments } from '../api/attachments';
import { FileAttachment } from '../api/file-attachment';
import { TaskConfigRepeat } from '../api/task-config-repeat';
import { TaskConfigRepeatType } from '../api/task-config-repeat-type.enum';
import { TaskConfig } from '../api/taskConfig';
import { TypeCount } from '../api/type-count';
import { UrlAttachment } from '../api/url-attachment';
import { MeasurementPointMapperService } from '../asset-types/measurement-point/mapper/measurement-point-mapper.service';
import { TenantMapperService } from '../asset-types/tenant/mapper/tenant-mapper.service';
import { AssetStatisticsDto } from '../dto/asset-statistics-dto';
import { AttachmentsDto } from '../dto/attachments-dto';
import { TypeCountDto } from '../dto/type-count-dto';
import { UserGroupMapperService } from "../../group/mapper/usergroup-mapper.service";
import { TimeWindowConfigIntervallDto } from '../dto/time-window-config-intervall-dto';
import { TimeWindowConfigIntervall } from '../api/time-window-config-intervall';
import { TimeWindowConfigDayDto } from '../dto/time-window-config-day-dto';
import { TimeWindowConfigDay } from '../api/time-window-config-day';
import { TimeWindowConfig } from '../api/time-window-config';
import { TimeWindowConfigDto } from '../dto/time-window-config-dto';
import { TimeWindowHelper } from './time-window-helper';
import { DynamicAssetDto } from '../dto/dynamic-asset-dto';
import { DynamicAsset } from '../api/dynamic-asset';

@Injectable({
  providedIn: 'root',
})
export class AssetMapperService {

  public constructor(
    private measurementMapper: LatestMeasurementMapperService,
    private measurementPointMapper: MeasurementPointMapperService,
    private tenantMapper: TenantMapperService,
    private sensorMapper: SensorMapperService,
    private propertyMapper: PropertyMapperService,
    private userGroupMapperService: UserGroupMapperService,
  ) {
  }

  public assetArrayToAssetDtoArray(assets: Asset[]): AssetDto[] {
    return assets.map((asset: Asset) => this.assetToAssetDto(asset));
  }

  public assetDtoArrayToAssetArray(assetDtos: AssetDto[]): Asset[] {
    return _.map(assetDtos, (assetDto: AssetDto) => this.assetDtoToAsset(assetDto));
  }

  public assetDtoToAsset(assetDto: AssetDto): Asset {
    const asset: Asset = plainToClass(Asset, assetDto);
    if (asset) {
      asset.lastMeasurement = assetDto && assetDto.lastMeasurement ? this.measurementMapper.measurementDtoToMeasurement(assetDto.lastMeasurement) : undefined;
      asset.measurementPoint = assetDto.measurementPoint ? this.measurementPointMapper.measurementPointDtoToMeasurementPoint(assetDto.measurementPoint) : undefined;
      asset.tenant = assetDto.tenant ? this.tenantMapper.tenantDtoToTenant(assetDto.tenant) : undefined;
      asset.childStatistics = assetDto.childStatistics ? this.assetStatisticsDtoToAssetStatistics(assetDto.childStatistics) : undefined;
      asset.sensor = assetDto.sensor ? this.sensorMapper.sensorDtoToSensor(assetDto.sensor) : undefined;
      asset.statistics = assetDto.statistics ? this.assetStatisticsDtoToAssetStatistics(assetDto.statistics) : undefined;
      asset.properties = assetDto.properties ? this.propertyMapper.propertyDtoArrayToPropertyArray(assetDto.properties) : undefined;
      asset.attachments = assetDto.attachments ? this.attachmentsDtoToAttachments(assetDto.attachments) : undefined;
      asset.taskConfigs = assetDto.taskConfigs ? this.taskConfigDtosToTaskConfigs(assetDto.taskConfigs) : [];
      asset.assignedUserGroups = assetDto.assignedUserGroups ? this.userGroupMapperService.groupsDtoToGroups(assetDto.assignedUserGroups) : [];
      asset.timeWindowConfig = assetDto.timeWindowConfig ? this.timeWindowConfigDtoToTimeWindowConfig(assetDto.timeWindowConfig) : undefined;
      asset.costCenterCode = assetDto.costCenterCode ? assetDto.costCenterCode : undefined;
      asset.costCenterType = assetDto.costCenterType ? assetDto.costCenterType : undefined;
      asset.language = assetDto.language ? assetDto.language : undefined;
      asset.provider = assetDto.provider ? assetDto.provider : undefined;
      //asset.sensorTypeThreshold = assetDto.sensorTypeThreshold ? assetDto.sensorTypeThreshold : undefined;
    }
    return asset;
  }

  public dynamicAssetsDtoToDynamicAssets(assetsDto: DynamicAssetDto[]): DynamicAsset[] {
    return assetsDto.map(assetDto => this.dynamicAssetDtoToDynamicAsset(assetDto));
  }

  public dynamicAssetDtoToDynamicAsset(assetDto: DynamicAssetDto): DynamicAsset {
    const asset: DynamicAsset = plainToClass(DynamicAsset, assetDto);
    if (asset) {
      asset.lastMeasurement = assetDto && assetDto.lastMeasurement ? this.measurementMapper.measurementDtoToMeasurement(assetDto.lastMeasurement) : undefined;
      asset.measurementPoint = assetDto.measurementPoint ? this.measurementPointMapper.measurementPointDtoToMeasurementPoint(assetDto.measurementPoint) : undefined;
      asset.tenant = assetDto.tenant ? this.tenantMapper.tenantDtoToTenant(assetDto.tenant) : undefined;
      asset.childStatistics = assetDto.childStatistics ? this.assetStatisticsDtoToAssetStatistics(assetDto.childStatistics) : undefined;
      asset.sensor = assetDto.sensor ? this.sensorMapper.sensorDtoToSensor(assetDto.sensor) : undefined;
      asset.statistics = assetDto.statistics ? this.assetStatisticsDtoToAssetStatistics(assetDto.statistics) : undefined;
      //asset.properties = assetDto.properties ? this.propertyMapper.propertyDtoArrayToPropertyArray(assetDto.properties) : undefined;
      asset.properties = undefined;
      asset.attachments = assetDto.attachments ? this.attachmentsDtoToAttachments(assetDto.attachments) : undefined;
      //asset.taskConfigs = assetDto.taskConfigs ? this.taskConfigDtosToTaskConfigs(assetDto.taskConfigs) : [];
      asset.taskConfigs = undefined;
      //asset.assignedUserGroups = assetDto.assignedUserGroups ? this.userGroupMapperService.groupsDtoToGroups(assetDto.assignedUserGroups) : [];
      asset.timeWindowConfig = assetDto.timeWindowConfig ? this.timeWindowConfigDtoToTimeWindowConfig(assetDto.timeWindowConfig) : undefined;
      asset.costCenterCode = assetDto.costCenterCode ? assetDto.costCenterCode : undefined;
      asset.costCenterType = assetDto.costCenterType ? assetDto.costCenterType : undefined;
      asset.language = assetDto.language ? assetDto.language : undefined;
      asset.assignedUserGroups = undefined;
      asset.tags = undefined;
      asset.provider = assetDto.provider ? assetDto.provider : undefined;

    }
    return asset;
  }

  public assetSearchCriteriaDtoToAssetSearchCriteria(assetSearchCriteriaDto: AssetSearchCriteriaDto): AssetSearchCriteria {
    return plainToClass(AssetSearchCriteria, assetSearchCriteriaDto);
  }

  public assetSearchCriteriaToAssetSearchCriteriaDto(assetSearchCriteria: AssetSearchCriteria): AssetSearchCriteriaDto {
    return plainToClass(AssetSearchCriteriaDto, assetSearchCriteria);
  }

  public assetStatisticsArrayToAssetStatisticsDtoArray(assetsStatistics: AssetStatistics[]): AssetStatisticsDto[] {
    return _.map(assetsStatistics, (assetStatistics: AssetStatistics) => this.assetStatisticsToAssetStatisticsDto(assetStatistics));
  }

  public assetStatisticsDtoArrayToAssetStatisticsArray(assetStatisticsDtos: AssetStatisticsDto[]): AssetStatistics[] {
    return _.map(assetStatisticsDtos, (assetStatisticsDto: AssetStatisticsDto) => this.assetStatisticsDtoToAssetStatistics(assetStatisticsDto));
  }

  public assetStatisticsDtoToAssetStatistics(assetStatisticsDto: AssetStatisticsDto): AssetStatistics {
    return new AssetStatistics({
      activeNotificationTypesCount: _.map(assetStatisticsDto.activeNotificationTypesCount, (typeCount: TypeCountDto) => plainToClass(TypeCount, typeCount)),
      assetTypesCount: _.map(assetStatisticsDto.assetTypesCount, (typeCount: TypeCountDto) => plainToClass(TypeCount, typeCount)),
      violationCount: assetStatisticsDto.violationCount,
      inactiveNotificationTypesCount: _.map(assetStatisticsDto.inactiveNotificationTypesCount, (typeCount: TypeCountDto) => plainToClass(TypeCount, typeCount)),
    });
  }

  public assetStatisticsToAssetStatisticsDto(assetStatistics: AssetStatistics): AssetStatisticsDto {
    return new AssetStatisticsDto({
      activeNotificationTypesCount: _.map(assetStatistics.activeNotificationTypesCount, (typeCount: TypeCount) => plainToClass(TypeCountDto, typeCount)),
      assetTypesCount: _.map(assetStatistics.assetTypesCount, (typeCount: TypeCount) => plainToClass(TypeCountDto, typeCount)),
      violationCount: assetStatistics.violationCount,
      positiveCount: assetStatistics.positiveCount,
      inactiveNotificationTypesCount: _.map(assetStatistics.inactiveNotificationTypesCount, (typeCount: TypeCount) => plainToClass(TypeCountDto, typeCount)),
    });
  }

  public assetToAssetDto(asset: Asset): AssetDto {
    const assetDto: AssetDto = plainToClass(AssetDto, asset);
    if (assetDto) {
      assetDto.lastMeasurement = asset && asset.lastMeasurement ? this.measurementMapper.measurementToMeasurementDto(asset.lastMeasurement) : undefined;
      assetDto.measurementPoint = asset.measurementPoint ? this.measurementPointMapper.measurementPointToMeasurementPointDto(asset.measurementPoint) : undefined;
      assetDto.tenant = asset.tenant ? this.tenantMapper.tenantToTenantDto(asset.tenant) : undefined;
      assetDto.childStatistics = asset.childStatistics ? this.assetStatisticsToAssetStatisticsDto(asset.childStatistics) : undefined;
      assetDto.sensor = asset.sensor ? this.sensorMapper.sensorToSensorDto(asset.sensor) : undefined;
      assetDto.statistics = asset.statistics ? this.assetStatisticsToAssetStatisticsDto(asset.statistics) : undefined;
      assetDto.properties = asset.properties ? this.propertyMapper.propertyArrayToPropertyDtoArray(asset.properties) : undefined;
      assetDto.attachments = asset.attachments ? this.attachmentsToAttachmentsDto(asset.attachments) : undefined;
      assetDto.taskConfigs = asset.taskConfigs ? this.taskConfigsToTaskConfigsDto(asset.taskConfigs) : [];
      assetDto.assignedUserGroups = asset.assignedUserGroups ? this.userGroupMapperService.groupsToGroupsDto(asset.assignedUserGroups) : [];
      assetDto.timeWindowConfig = asset.timeWindowConfig ? this.timeWindowConfigToTimeWindowConfigDto(asset.timeWindowConfig) : undefined;
      assetDto.costCenterCode = asset.costCenterCode ? asset.costCenterCode : undefined;
      assetDto.costCenterType = asset.costCenterType ? asset.costCenterType : undefined;
      assetDto.language = asset.language ? asset.language : undefined;
      assetDto.provider = asset.provider ? asset.provider : undefined;
      //assetDto.sensorTypeThreshold = asset.sensorTypeThreshold ? asset.sensorTypeThreshold : undefined;
    }
    return assetDto;
  }

  public dynamicAssetToDynamicAssetDto(asset: DynamicAsset): DynamicAssetDto {
    const assetDto: DynamicAssetDto = plainToClass(DynamicAssetDto, asset);
    if (assetDto) {
      assetDto.lastMeasurement = asset && asset.lastMeasurement ? this.measurementMapper.measurementToMeasurementDto(asset.lastMeasurement) : undefined;
      assetDto.measurementPoint = asset.measurementPoint ? this.measurementPointMapper.measurementPointToMeasurementPointDto(asset.measurementPoint) : undefined;
      assetDto.tenant = asset.tenant ? this.tenantMapper.tenantToTenantDto(asset.tenant) : undefined;
      assetDto.childStatistics = asset.childStatistics ? this.assetStatisticsToAssetStatisticsDto(asset.childStatistics) : undefined;
      assetDto.sensor = asset.sensor ? this.sensorMapper.sensorToSensorDto(asset.sensor) : undefined;
      assetDto.statistics = asset.statistics ? this.assetStatisticsToAssetStatisticsDto(asset.statistics) : undefined;
      //assetDto.properties = asset.properties ? this.propertyMapper.propertyArrayToPropertyDtoArray(asset.properties) : undefined;
      assetDto.attachments = asset.attachments ? this.attachmentsToAttachmentsDto(asset.attachments) : undefined;
      //assetDto.taskConfigs = asset.taskConfigs ? this.taskConfigsToTaskConfigsDto(asset.taskConfigs) : [];
      //assetDto.assignedUserGroups = asset.assignedUserGroups ? this.userGroupMapperService.groupsToGroupsDto(asset.assignedUserGroups) : [];
      assetDto.timeWindowConfig = asset.timeWindowConfig ? this.timeWindowConfigToTimeWindowConfigDto(asset.timeWindowConfig) : undefined;
      assetDto.costCenterCode = asset.costCenterCode ? asset.costCenterCode : undefined;
      assetDto.costCenterType = asset.costCenterType ? asset.costCenterType : undefined;
      assetDto.language = asset.language ? asset.language : undefined;
      assetDto.provider = asset.provider ? asset.provider : undefined;
    }
    return assetDto;
  }

  public dynamicAssetsToDynamicAssetsDto(assets: DynamicAsset[]): DynamicAssetDto[] {
    console.log("dynamicAssetsToDynamicAssetsDto called with assets:", assets);
    return assets.map((asset) => this.dynamicAssetToDynamicAssetDto(asset))
  }

  public attachmentsDtoToAttachments(attachments: AttachmentsDto): Attachments {
    return new Attachments({
      files: _.map(attachments.files, (fileAttachment: FileAttachmentDto) => plainToClass(FileAttachment, fileAttachment)),
      urls: _.map(attachments.urls, (urlAttachment: UrlAttachmentDto) => plainToClass(UrlAttachment, urlAttachment)),
    });
  }

  public attachmentsToAttachmentsDto(attachments: Attachments): AttachmentsDto {
    return new AttachmentsDto({
      files: _.map(attachments.files, (fileAttachment: FileAttachment) => plainToClass(FileAttachmentDto, fileAttachment)),
      urls: _.map(attachments.urls, (urlAttachment: UrlAttachment) => plainToClass(UrlAttachmentDto, urlAttachment)),
    });
  }

  /**
   * Convert an array of TaskConfigDtos to an array of TaskConfig objects
   * @param taskConfigDtos the TaskConfigDtos that should be converted
   */
  public taskConfigDtosToTaskConfigs(taskConfigDtos: TaskConfigDto[]): TaskConfig[] {
    return _.map(taskConfigDtos, (taskConfigDto: TaskConfigDto) => new TaskConfig({
      createdDate: taskConfigDto.createdDate,
      duration: taskConfigDto.duration,
      id: taskConfigDto.id,
      repeat: taskConfigDto.repeat ? this.taskConfigRepeatDtoToTaskConfigRepeat(taskConfigDto.repeat) : undefined,
      startDate: taskConfigDto.startDate,
      timezone: taskConfigDto.timezone,
      assignedUserGroupIds: taskConfigDto.assignedUserGroupIds ? taskConfigDto.assignedUserGroupIds : []
    }));
  }

  public taskConfigRepeatDtoToTaskConfigRepeat(taskConfigRepeatDto: TaskConfigRepeatDto): TaskConfigRepeat {
    return new TaskConfigRepeat({
      value: taskConfigRepeatDto.value,
      type: TaskConfigRepeatType[taskConfigRepeatDto.type],
      config: taskConfigRepeatDto.config,
    });
  }

  public taskConfigRepeatToTaskConfigRepeatDto(taskConfigRepeat: TaskConfigRepeat): TaskConfigRepeatDto {
    return new TaskConfigRepeatDto({
      value: taskConfigRepeat.value,
      type: TaskConfigRepeatTypeDto[taskConfigRepeat.type],
      config: taskConfigRepeat.config,
    });
  }

  /**
   * Convert an array of TaskConfig objects to an array of TaskConfigDtos
   * @param taskConfigs the TaskConfig objects that should be converted
   */
  public taskConfigsToTaskConfigsDto(taskConfigs: TaskConfig[]): TaskConfigDto[] {
    return _.map(taskConfigs, (taskConfig: TaskConfig) => new TaskConfigDto({
      createdDate: taskConfig.createdDate,
      duration: taskConfig.duration,
      id: taskConfig.id,
      repeat: taskConfig.repeat ? this.taskConfigRepeatToTaskConfigRepeatDto(taskConfig.repeat) : undefined,
      startDate: taskConfig.startDate,
      timezone: taskConfig.timezone,
      assignedUserGroupIds: taskConfig.assignedUserGroupIds ? taskConfig.assignedUserGroupIds : []
    }));
  }

  /**
   * Convert a TimeWindowConfigDto object to a TimeWindowConfig object
   * @param timeWindowConfigDto the TimeWindowConfigDto object that should be converted
   */
  public timeWindowConfigDtoToTimeWindowConfig(timeWindowConfigDto: TimeWindowConfigDto): TimeWindowConfig {
    timeWindowConfigDto = TimeWindowHelper.convertTimeWindowConfigDtoFromLocaltoUTC(timeWindowConfigDto);
    return new TimeWindowConfig({
      monday: this.timeWindowConfigDayDtoToTimeWindowConfigDay(timeWindowConfigDto.monday),
      tuesday: this.timeWindowConfigDayDtoToTimeWindowConfigDay(timeWindowConfigDto.tuesday),
      wednesday: this.timeWindowConfigDayDtoToTimeWindowConfigDay(timeWindowConfigDto.wednesday),
      thursday: this.timeWindowConfigDayDtoToTimeWindowConfigDay(timeWindowConfigDto.thursday),
      friday: this.timeWindowConfigDayDtoToTimeWindowConfigDay(timeWindowConfigDto.friday),
      saturday: this.timeWindowConfigDayDtoToTimeWindowConfigDay(timeWindowConfigDto.saturday),
      sunday: this.timeWindowConfigDayDtoToTimeWindowConfigDay(timeWindowConfigDto.sunday),
      dstOffset: timeWindowConfigDto.dstOffset
    });
  }

  /**
   * Convert a TimeWindowConfig object to a TimeWindowConfigDto object
   * @param timeWindowConfig the TimeWindowConfig object that should be converted
   */
  public timeWindowConfigToTimeWindowConfigDto(timeWindowConfig: TimeWindowConfig): TimeWindowConfigDto {
    let timeWindowConfigDto = new TimeWindowConfigDto({
      monday: this.timeWindowConfigDayToTimeWindowConfigDayDto(timeWindowConfig.monday),
      tuesday: this.timeWindowConfigDayToTimeWindowConfigDayDto(timeWindowConfig.tuesday),
      wednesday: this.timeWindowConfigDayToTimeWindowConfigDayDto(timeWindowConfig.wednesday),
      thursday: this.timeWindowConfigDayToTimeWindowConfigDayDto(timeWindowConfig.thursday),
      friday: this.timeWindowConfigDayToTimeWindowConfigDayDto(timeWindowConfig.friday),
      saturday: this.timeWindowConfigDayToTimeWindowConfigDayDto(timeWindowConfig.saturday),
      sunday: this.timeWindowConfigDayToTimeWindowConfigDayDto(timeWindowConfig.sunday),
      dstOffset: timeWindowConfig.dstOffset
    });
    timeWindowConfigDto = TimeWindowHelper.convertTimeWindowConfigDtoFromUTCtoLocal(timeWindowConfigDto);
    return timeWindowConfigDto;
  }

  /**
   * Convert a TimeWindowConfigDayDto object to a TimeWindowConfigDay object
   * @param timeWindowConfigDayDto the TimeWindowConfigDayDto object that should be converted
   */
  public timeWindowConfigDayDtoToTimeWindowConfigDay(timeWindowConfigDayDto: TimeWindowConfigDayDto): TimeWindowConfigDay {
    return new TimeWindowConfigDay({
      enable: timeWindowConfigDayDto.enable,
      intervalls: this.timeWindowConfigIntervallDtosToTimeWindowConfigIntervalls(timeWindowConfigDayDto.intervalls)
    });
  }

  /**
   * Convert a TimeWindowConfigDay object to a TimeWindowConfigDayDto object
   * @param timeWindowConfigDay the TimeWindowConfigDay object that should be converted
   */
  public timeWindowConfigDayToTimeWindowConfigDayDto(timeWindowConfigDay: TimeWindowConfigDay): TimeWindowConfigDayDto {
    return new TimeWindowConfigDayDto({
      enable: timeWindowConfigDay.enable,
      intervalls: this.timeWindowConfigIntervallsToTimeWindowConfigIntervallDtos(timeWindowConfigDay.intervalls)
    });
  }

  /**
   * Convert a TimeWindowConfigIntervallDto object to a TimeWindowConfigIntervall object
   * @param timeWindowConfigIntervallDto the TimeWindowConfigIntervallDto object that should be converted
   */
  public timeWindowConfigIntervallDtoToTimeWindowConfigIntervall(timeWindowConfigIntervallDto: TimeWindowConfigIntervallDto): TimeWindowConfigIntervall {
    return new TimeWindowConfigIntervall({
      startTime: timeWindowConfigIntervallDto.startTime,
      endTime: timeWindowConfigIntervallDto.endTime
    });
  }

  /**
   * Convert a TimeWindowConfigIntervall object to a TimeWindowConfigIntervallDto object
   * @param timeWindowConfigIntervall the TimeWindowConfigIntervall object that should be converted
   */
  public timeWindowConfigIntervallToTimeWindowConfigIntervallDto(timeWindowConfigIntervall: TimeWindowConfigIntervall): TimeWindowConfigIntervallDto {
    return new TimeWindowConfigIntervallDto({
      startTime: timeWindowConfigIntervall.startTime,
      endTime: timeWindowConfigIntervall.endTime
    });
  }

  /**
   * Convert an array of TimeWindowConfigIntervallDto objects to an array of TimeWindowConfigIntervalls
   * @param timeWindowConfigIntervallDtos the TimeWindowConfigIntervallDto objects that should be converted
   */
  public timeWindowConfigIntervallDtosToTimeWindowConfigIntervalls(timeWindowConfigIntervallDtos: TimeWindowConfigIntervallDto[]): TimeWindowConfigIntervall[] {
    return _.map(timeWindowConfigIntervallDtos, (timeWindowConfigIntervallDto: TimeWindowConfigIntervallDto) => new TimeWindowConfigIntervall({
      startTime: timeWindowConfigIntervallDto.startTime,
      endTime: timeWindowConfigIntervallDto.endTime
    }));
  }

  /**
   * Convert an array of TimeWindowConfigIntervall objects to an array of TimeWindowConfigIntervallDtos
   * @param timeWindowConfigIntervalls the TimeWindowConfigIntervall objects that should be converted
   */
  public timeWindowConfigIntervallsToTimeWindowConfigIntervallDtos(timeWindowConfigIntervalls: TimeWindowConfigIntervall[]): TimeWindowConfigIntervallDto[] {
    return _.map(timeWindowConfigIntervalls, (timeWindowConfigIntervall: TimeWindowConfigIntervall) => new TimeWindowConfigIntervallDto({
      startTime: timeWindowConfigIntervall.startTime,
      endTime: timeWindowConfigIntervall.endTime
    }));
  }
}

import { BaseEntity } from '../../shared/classes/base-entity';
import { JsonExportable } from '../../shared/classes/json-exportable';

export class Threshold extends BaseEntity implements JsonExportable {
  public valueMin: string = undefined;
  public valueMax: string = undefined;
  public alarmMin: string = undefined;
  public alarmMax: string = undefined;
  public assetsCount: number = undefined;
  public alarmConfig: string = 'FIRST'

  public constructor(threshold?: Partial<Threshold>) {
    super(threshold);
    Object.assign(this, threshold);
  }

  public toJSON(): Object {
    return {
      id: this.id,
      name: this.name,
      description: this.description,
      valueMin: this.valueMin,
      valueMax: this.valueMax,
      alarmMin: this.alarmMin,
      alarmMax: this.alarmMax,
      assetsCount: this.assetsCount,
      properties: this.properties,
      tags: this.tags,
      tenantId: this.tenantId,
      alarmConfig: this.alarmConfig
    };
  }
}

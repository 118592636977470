<phoenix-dialog-new [buttons]="data.buttons" [subtitle]="data.subtitle" [title]="data.title">
  <div class="text-center text-semibold mt-20 text-truncate">{{'TASKCONFIG.ANNUALLY.DESCRIPTION' | translate}}</div>
  <form [formGroup]="data.formGroup">
    <div fxLayout="column">
      <mat-form-field>
        <label class="mr-8" matPrefix>&nbsp;&nbsp;&nbsp;&nbsp;{{'TASKCONFIG.ANNUALLY.START.PREFIX' | translate}}</label>
        <!--<mat-placeholder>{{'TASKCONFIG.ANNUALLY.START.PLACEHOLDER' | translate}}</mat-placeholder>-->
        <mat-datetimepicker-toggle [for]="datetimePickerStart" matSuffix></mat-datetimepicker-toggle>
        <mat-datetimepicker #datetimePickerStart [startAt]="today" timeInterval="5" type="datetime">
        </mat-datetimepicker>
        <input [matDatetimepicker]="datetimePickerStart" [min]="data.startDatePicker" class="text-center"
          formControlName="start" matInput readonly
          placeholder="{{'TASKCONFIG.ANNUALLY.START.PLACEHOLDER' | translate}}">
        <label class="ml-8" matSuffix>{{'TASKCONFIG.ANNUALLY.START.SUFFIX' | translate}}</label>
      </mat-form-field>
      <mat-form-field>
        <label class="mr-8" matPrefix>&nbsp;&nbsp;&nbsp;&nbsp;{{'TASKCONFIG.ANNUALLY.END.PREFIX' | translate}}</label>
        <!--<mat-placeholder>{{'TASKCONFIG.ANNUALLY.END.PLACEHOLDER' | translate}}</mat-placeholder>-->
        <mat-datetimepicker-toggle [for]="datetimePickerEnd" matSuffix></mat-datetimepicker-toggle>
        <mat-datetimepicker #datetimePickerEnd timeInterval="5" type="datetime"></mat-datetimepicker>
        <input [matDatetimepicker]="datetimePickerEnd" [min]="getStartDate()" class="text-center" formControlName="end"
          matInput readonly placeholder="{{'TASKCONFIG.ANNUALLY.END.PLACEHOLDER' | translate}}">
        <label class="ml-8" matSuffix>{{'TASKCONFIG.ANNUALLY.END.SUFFIX' | translate}}</label>
      </mat-form-field>
      <mat-radio-group [(ngModel)]="typeSelector" [ngModelOptions]="{standalone: true}">
        <div fxLayout="row">
          <mat-radio-button (click)="resetDaysAndMonth2()" [value]="1">{{'TASKCONFIG.RADIOBUTTONS.WEEKDAYS' |
            translate}}</mat-radio-button>
          <mat-form-field fxFlex="20">
            <label class="mr-8" matPrefix>&nbsp;&nbsp;&nbsp;&nbsp;{{'TASKCONFIG.ANNUALLY.WEEKDAYS.PREFIX' |
              translate}}</label>
            <mat-select [disabled]="typeSelector === 2 || !typeSelector"
              [placeholder]="'TASKCONFIG.ANNUALLY.WEEKDAYS.TURNUS' | translate" class="text-right"
              formControlName="week">
              <mat-option *ngFor="let repeat of week" [value]="repeat.value">{{'TASKCONFIG.WEEK.' +
                repeat.label.toUpperCase() | translate}}</mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field fxFlex="20">
            <mat-select [disabled]="typeSelector === 2 || !typeSelector"
              [placeholder]="'TASKCONFIG.ANNUALLY.WEEKDAYS.PLACEHOLDER' | translate" class="text-right"
              formControlName="weekdays">
              <mat-option *ngFor="let day of weekdays" [value]="day.value">{{'TASKCONFIG.WEEKDAYS.' +
                day.label.toUpperCase() | translate}}</mat-option>
            </mat-select>
            <span class="ml-8" matSuffix>{{'TASKCONFIG.ANNUALLY.WEEKDAYS.SUFFIX' | translate}}</span>
          </mat-form-field>
          <mat-form-field>
            <label class="mr-8" matPrefix>&nbsp;&nbsp;&nbsp;&nbsp;{{'TASKCONFIG.ANNUALLY.MONTH.PREFIX' |
              translate}}</label>
            <mat-select [disabled]="typeSelector === 2 || !typeSelector"
              [placeholder]="'TASKCONFIG.ANNUALLY.MONTH.PLACEHOLDER' | translate" class="text-right"
              formControlName="month">
              <mat-option *ngFor="let month of months" [value]="month.value">{{'TASKCONFIG.MONTHS.' +
                month.label.toUpperCase() | translate}}</mat-option>
            </mat-select>
            <span class="ml-8" matSuffix>{{'TASKCONFIG.ANNUALLY.MONTH.SUFFIX' | translate}}</span>
          </mat-form-field>
        </div>
        <div fxLayout="row">
          <mat-radio-button (click)="resetWeekdays()" [value]="2">{{'TASKCONFIG.RADIOBUTTONS.MONTHDAYS' | translate}}
          </mat-radio-button>
          <mat-form-field fxFlex="40">
            <label class="mr-8" matPrefix>&nbsp;&nbsp;&nbsp;&nbsp;{{'TASKCONFIG.ANNUALLY.MONTH.PREFIX' |
              translate}}</label>
            <mat-select (valueChange)="setMaxDays($event)" [disabled]="typeSelector === 1 || !typeSelector"
              [placeholder]="'TASKCONFIG.ANNUALLY.MONTH.PLACEHOLDER' | translate" class="text-right"
              formControlName="month2">
              <mat-option *ngFor="let month of months" [value]="month.value">{{'TASKCONFIG.MONTHS.' +
                month.label.toUpperCase() | translate}}</mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field>
            <label class="mr-8" matPrefix>&nbsp;&nbsp;&nbsp;&nbsp;{{'TASKCONFIG.ANNUALLY.DAYS.PREFIX' |
              translate}}</label>
            <mat-select [disabled]="typeSelector === 1 || !typeSelector"
              [placeholder]="'TASKCONFIG.ANNUALLY.DAYS.PLACEHOLDER' | translate" class="text-right"
              formControlName="days" multiple>
              <mat-option *ngFor="let day of getDays()" [value]="day.value">{{day.label}}</mat-option>
            </mat-select>
            <span class="ml-8" matSuffix>{{'TASKCONFIG.ANNUALLY.MONTH.SUFFIX' | translate}}</span>
          </mat-form-field>
        </div>
      </mat-radio-group>
      <mat-form-field>
        <mat-select [placeholder]="'TASKCONFIG.ASSIGNGROUPS.ADDREMOVE' | translate" class="text-left"
          formControlName="assignedUserGroupIds" multiple>
          <mat-option *ngFor="let userGroup of userGroups" [value]="userGroup.id">{{userGroup.name}}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </form>
</phoenix-dialog-new>
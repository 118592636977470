
import {AssetDto} from '../../../../gapicon/asset/dto/asset-dto';

export class PhoenixAssetBranchSelectorDialogData {
    public title: string;
    public subtitle: string;
    public cancelButton: boolean = false;
    public assetBranchRootElement: AssetDto[];

    public constructor(phoenixAssetBranchSelectorDialogData?: Partial<PhoenixAssetBranchSelectorDialogData>) {
        Object.assign(this, phoenixAssetBranchSelectorDialogData);
    }
}

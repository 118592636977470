import { JsonExportable } from '../../shared/classes/json-exportable';

export class AlarmCount implements JsonExportable {

    public monthYear: string = undefined;
    public activeInactiveCount: number = undefined;
    public closedCount: number = undefined;

    public constructor(alarmCount?: Partial<AlarmCount>) {
        Object.assign(this, alarmCount);
    }

    public toJSON(): Object {
        return {
            monthYear: this.monthYear,
            activeInactiveCount: this.activeInactiveCount,
            closedCount: this.closedCount
        };
    }
}

import { AssetDto, PropertyDto } from '../../dto.module';
import { UserInfoDto } from '../../user/dto/user-info-dto';

import { MeasurementValueDto } from './measurement-value-dto';

export class MeasurementDto {
  public id: string = undefined;
  public sensorId: string = undefined;
  public asset: AssetDto = undefined;
  public measurementValues: MeasurementValueDto[] = undefined;
  public timestamp: Date = undefined;
  public type: string = undefined;
  public properties: PropertyDto[] = undefined;
  public user: UserInfoDto = undefined;
  public device: { accountId: string, login: string } = undefined;
  public attachments: string[] = undefined;

  public acknowledgment: string = undefined;
  public approver: string = undefined;

  public constructor(measurementDto?: Partial<MeasurementDto>) {
    Object.assign(this, measurementDto);
  }
}

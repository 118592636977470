import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { FuseSharedModule } from '@fuse/shared.module';
import { TranslateModule } from '@ngx-translate/core';
import { PhoenixCommunicationSubject } from '@phoenix/services/phoenix-communication-service/phoenix-communication-subject.enum';
import { PhoenixCommunicationService } from '@phoenix/services/phoenix-communication-service/phoenix-communication.service';
import { takeUntil } from 'rxjs/operators';

import { PhoenixIcon } from '../../../../@phoenix/enums/phoenix-icon.enum';
import { CanLoadGuard } from '../../../guards/can-load.guard';
import { Permission, permissionIds } from '../../../guards/permission';
import { navbarGroups } from '../../../layout/components/navbar/navbar-groups/navbar-groups.module';
import { NavigationItem } from '../../../navigation/classes/navigation-item';
import { NavigationService } from '../../../navigation/navigation.service';
import { MainModule } from '../../classes/main-module';
//TODO copy from user change it
const permission: Permission = new Permission(permissionIds.TENANTADMINISTRATION.USERMANAGEMENT.read);
const routes: Routes = [
  {
    path: 'tenantgroupmanagement',
    loadChildren: () => import('./groupmanagement-tenant-content/groupmanagement-tenant-content.module').then(m => m.GroupmanagementTenantContentModule),
    canLoad: [CanLoadGuard],
    data: { permission: permission },
  },
];

@NgModule({
  imports: [
    RouterModule.forChild(routes),
    TranslateModule,
    FuseSharedModule,
  ],
  declarations: [],
})
export class GroupmanagementTenantModule extends MainModule {
  private translationKey: string = 'NAV.GROUPSMANAGEMENT.TENANT';
  private navItem: NavigationItem = new NavigationItem(
    'groupmanagement-tenant',
    2,
    false,
    this.translationKey,
    '/tenantgroupmanagement',
    PhoenixIcon.GROUPS,
  );

  public constructor(
    private navigationService: NavigationService,
    private communicationService: PhoenixCommunicationService,
  ) {
    super();
    this.communicationService.getObservable(PhoenixCommunicationSubject.AssetBranchChanged)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(() => {
        this.navigationService.refreshNavigation(this.navItem, navbarGroups.tenantAdministration.id, permission);
      });
  }
}


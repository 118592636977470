<div mat-dialog-title class="title-wrapper {{ color }}">
  <div class="main-title h1">{{ data.headline | translate: translationParams }}</div>
  <div class="sub-title h4 hint-text">{{ data.subHeadline | translate: translationParams }}</div>
</div>
<div class="content">
  <mat-dialog-content>
    <h2 *ngIf="data.bodyTitle">{{ data.bodyTitle | translate }}</h2>
    <form class="mt-20" *ngIf="form" [formGroup]="form" (submit)="formSubmit()">
      <div fxLayout="row" fxLayoutGap="10px">
        <div *ngFor="let column of data.formControls" class="phoenixDialogFormControlWrapper">
          <phoenix-dialog-form-control *ngFor="let formControl of column" [phoenixFormControl]="formControl"
            [form]="form" [translationParams]="translationParams"></phoenix-dialog-form-control>
        </div>
      </div>
    </form>
    <div *ngIf="data.body">
      {{ data.body }}
    </div>
  </mat-dialog-content>
  <mat-dialog-actions *ngIf="data.buttons">
    <div class="button-container text-center mb-24">
      <mat-divider class="mt-24 mb-24" *ngIf="data.buttons"></mat-divider>
      <button mat-button class="ml-4" color="primary" *ngIf="data.buttons.cancel" (click)="closeDialog('cancel')">
        {{ data.buttons.cancel | translate }}
      </button>
      <button mat-button class="ml-4" color="primary" *ngIf="data.buttons.previous" (click)="closeDialog('previous')">
        {{ data.buttons.previous | translate }}
      </button>
      <button [disabled]="(form ? !form.valid : false) || showSpinner" mat-raised-button class="ml-4" [color]="color"
        *ngIf="data.buttons.ok" (click)="closeDialog('ok')">{{ data.buttons.ok | translate }}
        <mat-spinner [diameter]="20" *ngIf="showSpinner"></mat-spinner>
      </button>
      <button [disabled]="(form ? !form.valid : false) || showSpinner" mat-raised-button class="ml-4" [color]="color"
        *ngIf="data.buttons.save" (click)="closeDialog('save')">{{ data.buttons.save | translate }}
        <mat-spinner [diameter]="20" *ngIf="showSpinner"></mat-spinner>
      </button>
      <button [disabled]="(form ? !form.valid : false) || showSpinner" mat-raised-button class="ml-4" color="warn"
        *ngIf="data.buttons.delete" (click)="closeDialog('delete')">{{ data.buttons.delete | translate }}
        <mat-spinner [diameter]="20" *ngIf="showSpinner"></mat-spinner>
      </button>
      <button mat-button class="ml-4" color="primary" *ngIf="data.buttons.exit" (click)="closeDialog('exit')">
        {{ data.buttons.exit | translate }}
      </button>
      <ng-container *ngFor="let primaryRaisedButton of data.buttons.primaryRaisedButtons">
        <button [disabled]="(form ? !form.valid : false)" mat-button class="ml-4" color="primary" mat-raised-button
          (click)="closeDialog(primaryRaisedButton.closeString)">
          {{ primaryRaisedButton.label | translate }}
        </button>
      </ng-container>
    </div>
  </mat-dialog-actions>
</div>
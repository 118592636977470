import {Injectable} from '@angular/core';
import {AssetDto} from '../../@phoenix/gapicon/asset/dto/asset-dto';
import {UserDto} from '../../@phoenix/gapicon/user/dto/user-dto';

@Injectable({
  providedIn: 'root',
})
export class AppInitStorageService {

  private availableTenants: AssetDto[];
  private availableAssetBranches: AssetDto[];
  private user: UserDto;

  public getUser(): UserDto {
    return {...this.user};
  }

  public getAvailableTenants(): AssetDto[] {
    return this.availableTenants ? this.availableTenants.slice() : [];
  }

  public setAvailableTenants(availableTenants: AssetDto[]): void {
    this.availableTenants = availableTenants;
  }

  public getAvailableAssetBranches(): AssetDto[] {
    return this.availableAssetBranches ? this.availableAssetBranches.slice() : [];
  }

  public setAvailableAssetBranches(availableAssetBranches: AssetDto[]): void {
    this.availableAssetBranches = availableAssetBranches;
  }

  public setUser(user: UserDto): void {
    this.user = user;
  }
}

import {UntypedFormGroup} from '@angular/forms';

import {PhoenixFormElementType} from '../../enums/phoenix-form-element-type.enum';
import {PhoenixFormElement} from '../../interfaces/phoenix-form-element';

export class PhoenixFormTextarea implements PhoenixFormElement {
  public formGroup: UntypedFormGroup;
  public formControlName: string;
  public type: PhoenixFormElementType = PhoenixFormElementType.TextArea;

  public placeholder: string;
  public value: string;
  public hint: string;
  public minlength: number;
  public maxlength: number;
  public readonly: boolean;
  public required: boolean;
  public disabled: boolean;

  public constructor(formGroup: UntypedFormGroup,
                     formControlName: string,
                     placeholder: string = '',
                     value: string = '',
                     hint: string = '',
                     minlength?: number,
                     maxlength?: number,
                     required: boolean = false,
                     disabled: boolean = false,
                     readonly: boolean = false) {
    this.formGroup = formGroup;
    this.formControlName = formControlName;
    this.placeholder = placeholder;
    this.value = value;
    this.hint = hint;
    this.minlength = minlength;
    this.maxlength = maxlength;
    this.required = required;
    this.disabled = disabled;
    this.readonly = readonly;
  }
}

import {JsonExportable} from '../../shared/classes/json-exportable';

import {ValueType} from './value-type.enum';

export class MeasurementValueType implements JsonExportable {
  public id: string = undefined;
  public key: string = undefined;
  public name: string = undefined;
  public type: ValueType = undefined;
  public defaultValue: string = undefined;
  public unit: string = undefined;
  public children: MeasurementValueType[] = undefined;

  public constructor(measurementValueType?: Partial<MeasurementValueType>) {
    Object.assign(this, measurementValueType);
  }

  public toJSON(): Object {
    return {
      id: this.id,
      key: this.key,
      name: this.name,
      type: this.type,
      defaultValue: this.defaultValue,
      unit: this.unit,
      children: this.children,
    };
  }


}

import { format, isValid } from 'date-fns';

import { Asset } from '../../asset/api/asset';
import { Property } from '../../property/api/property';
import { JsonExportable } from '../../shared/classes/json-exportable';
import { UserInfo } from '../../user/api/user-info';

import { MeasurementValue } from './measurement-value';

export class Measurement implements JsonExportable {
  public id: string = undefined;
  public sensorId: string = undefined;
  public asset: Asset = undefined;
  public measurementValues: MeasurementValue[] = undefined;
  public timestamp: Date = undefined;
  public type: string = undefined;
  public properties: Property[] = undefined;
  public user: UserInfo = undefined;
  public device: { accountId: string, login: string } = undefined;
  public attachments: string[] = undefined;

  public acknowledgment: string = undefined;
  public approver: string = undefined;

  public constructor(measurement?: Partial<Measurement>) {
    Object.assign(this, measurement);
  }

  public toJSON(): Object {
    return {
      id: this.id,
      sensorId: this.sensorId,
      asset: this.asset,
      measurementValues: this.measurementValues,
      timestamp: (this.timestamp && isValid(this.timestamp)) ? this.timestamp.toISOString() : undefined,
      type: this.type,
      properties: this.properties,
      user: this.user,
      device: this.device,
      attachments: this.attachments,
      acknowledgment: this.acknowledgment,
      approver: this.approver
    };
  }
}

import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';

import {FileApiModule} from '../file/file-api.module';
import {SharedModule} from '../shared/shared.module';

import {MeasurementMapperService} from './mapper/measurement-mapper.service';
import {MeasurementApiService} from './services/measurement-api.service';


@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    FileApiModule,
  ],
  providers: [
    MeasurementApiService,
    MeasurementMapperService,
  ],
})
export class MeasurementApiModule {
}

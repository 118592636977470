import { CommonModule, registerLocaleData } from '@angular/common';
import localeDe from '@angular/common/locales/de';
import { LOCALE_ID, NgModule } from '@angular/core';
import { FlexLayoutModule } from 'ngx-flexible-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatOptionModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MAT_DATETIME_FORMATS, MatDatetimepickerModule, MatNativeDatetimeModule } from '@mat-datetimepicker/core';
import { TranslateModule } from '@ngx-translate/core';

import { PhoenixDialogNewModule } from '../../../components/phoenix-dialog-new/phoenix-dialog-new.module';
import { PhoenixFormModule } from '../../../components/phoenix-form/phoenix-form.module';

import { PhoenixTaskConfigDialogAnnuallyComponent } from './phoenix-task-config-dialog-annually/phoenix-task-config-dialog-annually.component';
import { PhoenixTaskConfigDialogDailyComponent } from './phoenix-task-config-dialog-daily/phoenix-task-config-dialog-daily.component';
import { PhoenixTaskConfigDialogMonthlyComponent } from './phoenix-task-config-dialog-monthly/phoenix-task-config-dialog-monthly.component';
import { PhoenixTaskConfigDialogOnceComponent } from './phoenix-task-config-dialog-once/phoenix-task-config-dialog-once.component';
import { PhoenixTaskConfigDialogWeeklyComponent } from './phoenix-task-config-dialog-weekly/phoenix-task-config-dialog-weekly.component';
import { PhoenixTaskConfigDialogComponent } from './phoenix-task-config-dialog.component';
import { PhoenixTaskConfigDialogService } from './phoenix-task-config-dialog.service';

registerLocaleData(localeDe);     // de-DE

@NgModule({
    imports: [
        CommonModule,
        TranslateModule,
        FlexLayoutModule,
        ReactiveFormsModule,
        FormsModule,
        MatFormFieldModule,
        MatMenuModule,
        MatIconModule,
        MatButtonModule,
        MatInputModule,
        MatDatepickerModule,
        MatNativeDatetimeModule,
        MatDatetimepickerModule,
        MatListModule,
        MatExpansionModule,
        MatSelectModule,
        MatOptionModule,
        MatRadioModule,
        PhoenixDialogNewModule,
        PhoenixFormModule,
    ],
    declarations: [
        PhoenixTaskConfigDialogComponent,
        PhoenixTaskConfigDialogOnceComponent,
        PhoenixTaskConfigDialogDailyComponent,
        PhoenixTaskConfigDialogWeeklyComponent,
        PhoenixTaskConfigDialogMonthlyComponent,
        PhoenixTaskConfigDialogAnnuallyComponent,
    ],
    providers: [
        PhoenixTaskConfigDialogService,
        { provide: LOCALE_ID, useValue: 'de' },
        {
            provide: MAT_DATETIME_FORMATS,
            useValue: {
                parse: {},
                display: {
                    dateInput: { year: 'numeric', month: '2-digit', day: '2-digit' },
                    monthInput: { month: 'long' },
                    datetimeInput: { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit' },
                    timeInput: { hour: '2-digit', minute: '2-digit' },
                    monthYearLabel: { year: 'numeric', month: 'short' },
                    dateA11yLabel: { year: '2-digit', month: 'long', day: 'numeric' },
                    monthYearA11yLabel: { year: 'numeric', month: 'long' },
                    popupHeaderDateLabel: { weekday: 'short', month: 'short', day: '2-digit' },
                },
            },
        },
    ]
})
export class PhoenixTaskConfigDialogModule {
}

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, reduce } from 'rxjs/operators';

import { Asset } from '../../asset/api/asset';
import { AssetMapperService } from '../../asset/mapper/asset-mapper.service';
import { AssetDto } from '../../dto.module';
import { SharedService } from '../../shared/services/shared.service';
import { Sensor } from '../api/sensor';
import { SensorType } from '../api/sensor-type';
import { SensorRegistrationDto } from '../dto/sensor-registration-dto';
import { SensorSearchCriteriaDto } from '../dto/sensor-search-criteria-dto';
import { SensorTypeDto } from '../dto/sensor-type-dto';
import { SensorTypeSearchCriteriaDto } from '../dto/sensor-type-search-criteria-dto';
import { SensorMapperService } from '../mapper/sensor-mapper.service';
import { SensorRegistrationMapperService } from '../mapper/sensor-registration-mapper.service';
import { SensorTypeMapperService } from '../mapper/sensor-type-mapper.service';

@Injectable()
export class SensorApiService {

  private _sensors: string = 'sensors';
  private _sensor: string = 'sensor';
  private _sensorType: string = 'sensorType';

  /**
   *
   * @param http
   * @param sharedService
   * @param sensorMapper
   */
  public constructor(private http: HttpClient,
    private sharedService: SharedService,
    private sensorMapper: SensorMapperService,
    private sensorRegistrationMapper: SensorRegistrationMapperService,
    private sensorTypeMapper: SensorTypeMapperService,
    private assetMapper: AssetMapperService,
  ) {
  }

  /**
   * Gets all sensors matching the search criteria
   * @param sensorSearchCriteriaDto
   * @param withFurtherPages
   * @returns
   */
  public getSensors(
    sensorSearchCriteriaDto: SensorSearchCriteriaDto = new SensorSearchCriteriaDto(),
    withFurtherPages: boolean = true,
  ): Observable<AssetDto[]> {
    return this.sharedService.httpGetWithPagination<Asset>(
      this.sharedService.buildApiUrl(this._sensors),
      this.sensorMapper.sensorSearchCriteriaDtoToSensorSearchCriteria(sensorSearchCriteriaDto),
      withFurtherPages,
    ).pipe(
      map((sensors: Asset[]) => this.assetMapper.assetArrayToAssetDtoArray(sensors)),
      reduce((all: AssetDto[], current: AssetDto[]) => all.concat(current)),
    );
  }

  /**
   * Creates an sensor by given SensorDto.
   * @returns
   * @param sensorRegistrationDto
   */
  public registerSensor(sensorRegistrationDto: SensorRegistrationDto): Observable<AssetDto> {
    return this.http.post<Asset>(
      this.sharedService.buildApiUrl(this._sensors, this._sensor),
      this.sensorRegistrationMapper.sensorRegistrationDtoToSensorRegistration(sensorRegistrationDto),
    ).pipe(
      map((sensor: Asset) => this.assetMapper.assetToAssetDto(sensor)),
    );
  }

  /**
   * Deletes a sensor by given AssetDto
   * @param sensorDto
   * @returns
   */
  public deleteSensor(sensorDto: AssetDto): Observable<void> {
    return this.deleteSensorById(sensorDto.id);
  }

  /**
   * Deletes a sensor by given sensor ID.
   * @param id
   * @returns
   */
  public deleteSensorById(id: string): Observable<void> {
    return this.http.delete<void>(
      this.sharedService.buildApiUrl(this._sensors, this._sensor, id),
    );
  }

  /**
   * Gets a sensor by given ID.
   * @param id
   * @returns
   */
  public getSensorById(id: string): Observable<AssetDto> {
    return this.http.get<Asset>(
      this.sharedService.buildApiUrl(this._sensors, this._sensor, id),
    ).pipe(
      map((sensor: Asset) => this.assetMapper.assetToAssetDto(sensor)),
    );
  }

  /**
   * Gets a sensor by given AssetDto.
   * @param sensorDto
   * @returns
   */
  public getSensor(sensorDto: AssetDto): Observable<AssetDto> {
    return this.getSensorById(sensorDto.id);
  }

  /**
   * Updates a sensor by given AssetDto.
   * @param sensorDto
   * @returns
   */
  public updateSensor(sensorDto: AssetDto): Observable<AssetDto> {
    return this.http.put<Asset>(
      this.sharedService.buildApiUrl(this._sensors, this._sensor, sensorDto.id),
      this.assetMapper.assetDtoToAsset(sensorDto),
    ).pipe(
      map((sensor: Asset) => this.assetMapper.assetToAssetDto(sensor)),
    );
  }


  /**
   * Gets all sensor types matching the search criteria
   * @param sensorTypeSearchCriteriaDto
   * @param withFurtherPages
   * @returns
   */
  public getSensorTypes(
    sensorTypeSearchCriteriaDto: SensorTypeSearchCriteriaDto = new SensorTypeSearchCriteriaDto(),
    withFurtherPages: boolean = true,
  ): Observable<SensorTypeDto[]> {
    return this.sharedService.httpGetWithPagination<SensorType>(
      this.sharedService.buildApiUrl(this._sensors, this._sensorType),
      this.sensorTypeMapper.sensorTypeSearchCriteriaDtoToSensorTypeSearchCriteria(sensorTypeSearchCriteriaDto),
      withFurtherPages,
    ).pipe(
      map((sensorsTypes: SensorType[]) => this.sensorTypeMapper.sensorTypeArrayToSensorTypeDtoArray(sensorsTypes)),
      reduce((all: SensorTypeDto[], current: SensorTypeDto[]) => all.concat(current)),
    );
  }

  /**
   * Gets an sensor type by given ID.
   * @param sensorTypeId
   * @returns
   */
  public getSensorTypeById(sensorTypeId: string): Observable<SensorTypeDto> {
    return this.http.get<SensorType>(
      this.sharedService.buildApiUrl(this._sensors, this._sensorType, sensorTypeId),
    ).pipe(
      map((sensorType: SensorType) => this.sensorTypeMapper.sensorTypeToSensorTypeDto(sensorType)),
    );
  }

  /**
   * Gets an sensor type by given SensorTypeDto.
   * @param sensorTypeDto
   * @returns
   */
  public getSensorType(sensorTypeDto: SensorTypeDto): Observable<SensorTypeDto> {
    return this.getSensorTypeById(sensorTypeDto.id);
  }

  /**
   * Creates an sensor type by given SensorTypeDto.
   * @param sensorTypeDto
   * @returns
   */
  public createSensorType(sensorTypeDto: SensorTypeDto): Observable<SensorTypeDto> {
    return this.http.post<SensorType>(
      this.sharedService.buildApiUrl(this._sensors, this._sensorType),
      this.sensorTypeMapper.sensorTypeDtoToSensorType(sensorTypeDto),
    ).pipe(
      map((sensorType: SensorType) => this.sensorTypeMapper.sensorTypeToSensorTypeDto(sensorType)),
    );
  }

  /**
   * Updates an sensor type by given SensorDto.
   * @param sensorTypeDto
   * @returns
   */
  public updateSensorType(sensorTypeDto: SensorTypeDto): Observable<SensorTypeDto> {
    return this.http.put<SensorType>(
      this.sharedService.buildApiUrl(this._sensors, this._sensorType, sensorTypeDto.id),
      this.sensorTypeMapper.sensorTypeDtoToSensorType(sensorTypeDto),
    ).pipe(
      map((sensorType: SensorType) => this.sensorTypeMapper.sensorTypeToSensorTypeDto(sensorType)),
    );
  }

  /**
   * Deletes an sensor type by given sensorTypeDto
   * @param sensorTypeDto
   * @returns
   */
  public deleteSensorType(sensorTypeDto: SensorTypeDto): Observable<void> {
    return this.deleteSensorTypeById(sensorTypeDto.id);
  }

  /**
   * Deletes an sensor type by given sensor type ID.
   * @param id
   * @returns
   */
  public deleteSensorTypeById(id: string): Observable<void> {
    return this.http.delete<void>(
      this.sharedService.buildApiUrl(this._sensors, this._sensorType, id.toString()),
    );
  }
}

import {PropertyDto} from '../../dto.module';

export abstract class BaseDto {
  public id: string = undefined;
  public name: string = undefined;
  public description: string = undefined;
  public tags: string[] = [];
  public tenantId: string = undefined;
  public properties: PropertyDto[] = [];

  protected constructor(baseDto?: Partial<BaseDto>) {
    Object.assign(this, baseDto);
  }
}

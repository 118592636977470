import { JsonExportable } from '../../../../shared/classes/json-exportable';
import { Threshold } from '../../../../threshold/api/threshold';


export class MeasurementPoint implements JsonExportable {
  public sensorId: String = undefined;
  public type: string = undefined;
  public threshold: Threshold = undefined;
  //public alarmConfig: string = 'FIRST';

  public constructor(measurementPoint?: Partial<MeasurementPoint>) {
    Object.assign(this, measurementPoint);
  }

  public toJSON(): Object {
    return {
      sensorId: this.sensorId,
      type: this.type,
      threshold: this.threshold,
      //alarmConfig: this.alarmConfig
    };
  }
}

import {UntypedFormGroup} from '@angular/forms';

import {PhoenixFormElementType} from '../../enums/phoenix-form-element-type.enum';
import {PhoenixFormElement} from '../../interfaces/phoenix-form-element';

export class PhoenixFormIconButton implements PhoenixFormElement {
  public formGroup: UntypedFormGroup;
  public formControlName: string;
  public type: PhoenixFormElementType = PhoenixFormElementType.IconButton;

  public icon: string;
  public disabled: () => boolean;
  public click: () => void;


  public constructor(formGroup: UntypedFormGroup, formControlName: string, icon: string, disabled: () => boolean, click: () => void) {
    this.formGroup = formGroup;
    this.formControlName = formControlName;
    this.icon = icon;
    this.disabled = disabled;
    this.click = click;
  }
}

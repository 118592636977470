import {Component, Input} from '@angular/core';

import {PhoenixButton} from './phoenix-button';

@Component({
  selector: 'phoenix-button',
  templateUrl: './phoenix-button.component.html',
  styleUrls: ['./phoenix-button.component.scss'],
})
export class PhoenixButtonComponent {
  @Input() public button: PhoenixButton;
}

<ng-container [formGroup]="element.formGroup">
  <div class="mt-12 mb-12">
    <mat-slide-toggle #slidetoggle
                      [checked]="element.checked"
                      [disabled]="element.disabled"
                      [formControlName]="element.formControlName">
      {{element.label | translate}}
    </mat-slide-toggle>
    <mat-hint *ngIf="element.hint">
      {{element.hint | translate}}
    </mat-hint>
  </div>
</ng-container>

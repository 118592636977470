export class EmailDto {
  public receiver: string = undefined;
  public subject: string = undefined;
  public text: string = undefined;

  public constructor(emailDto?: Partial<EmailDto>) {
    Object.assign(this, emailDto);
  }

}

import {JsonExportable} from '../../shared/classes/json-exportable';

import {ExtendedInformation} from './extended-information';
import {MeasurementValueType} from './measurement-value-type';

export class MeasurementType implements JsonExportable {

  public description: string = undefined;
  public extendedInformations: ExtendedInformation[] = undefined;
  public id: string = undefined;
  public key: string = undefined;
  public name: string = undefined;
  public tags: string[] = undefined;
  public valueTypes: MeasurementValueType[] = undefined;
  public version: string = undefined;

  public constructor(measurementType?: Partial<MeasurementType>) {
    Object.assign(this, measurementType);
  }

  public toJSON(): Object {
    return {
      id: this.id,
      key: this.key,
      name: this.name,
      description: this.description,
      valueTypes: this.valueTypes,
      tags: this.tags,
      extendedInformations: this.extendedInformations,
      version: this.version,
    };
  }
}

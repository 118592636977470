import { BaseDto } from '../../shared/classes/base-dto';

export class ThresholdDto extends BaseDto {
  public valueMin: string = undefined;
  public valueMax: string = undefined;
  public alarmMin: string = undefined;
  public alarmMax: string = undefined;
  public assetsCount: string = "0";
  public alarmConfig: string = 'FIRST'

  public constructor(thresholdDto?: Partial<ThresholdDto>) {
    super(thresholdDto);
    Object.assign(this, thresholdDto);
  }
}

import { SearchCriteriaPageableDto } from '../../shared/classes/search-criteria-pageable-dto';

export class MeasurementAggregateDto extends SearchCriteriaPageableDto {
  public dateFrom: Date = undefined;
  public dateTo: Date = undefined;
  public assetId: string = undefined;

  public constructor(measurementAggregateDto?: Partial<MeasurementAggregateDto>) {
    super(measurementAggregateDto);
    Object.assign(this, measurementAggregateDto);
  }
}
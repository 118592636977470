import {ToolbarMenuItem} from './toolbar-menu-item';

// TODO: Diese Klasse, das Interface und ToolbarImageMenuItem unterscheiden sich nur im vordefinierten _type. Sind dann 2 Klassen und 1 Interface notwendig???
/**
 * Implementation of ToolbarMenuItem Interface
 */
export class ToolbarIconMenuItem implements ToolbarMenuItem {

  private _id: string;
  private _title: string;
  private _action: Function;
  private _picture: string;
  private _type: string = 'icon';
  private _context: Object;

  public constructor(id: string, title: string, action: Function, icon: string, context: Object) {
    this.id = id;
    this.title = title;
    this.action = action;
    this.picture = icon;
    this.context = context;
  }

  public get id(): string {
    return this._id;
  }

  public set id(value: string) {
    this._id = value;
  }

  public get title(): string {
    return this._title;
  }

  public set title(value: string) {
    this._title = value;
  }

  public get action(): Function {
    return this._action;
  }

  public set action(value: Function) {
    this._action = value;
  }

  public get picture(): string {
    return this._picture;
  }

  public set picture(value: string) {
    this._picture = value;
  }

  public get type(): string {
    return this._type;
  }

  public set type(value: string) {
    this._type = value;
  }

  public get context(): Object {
    return this._context;
  }

  public set context(value: Object) {
    this._context = value;
  }
}

import {JsonExportable} from '../../shared/classes/json-exportable';

export class Role implements JsonExportable {
  public name: string = undefined;

  public constructor(role?: Partial<Role>) {
    Object.assign(this, role);
  }

  public toJSON(): Object {
    return {
      name: this.name,
    };
  }
}

import {FileItemTypeDto} from './file-item-type-dto.enum';

export class FileItemDto {
  public childItems: string[] = undefined;
  public contentEncoding: string = undefined;
  public contentLength: number = undefined;
  public contentMd5: string = undefined;
  public contentType: string = undefined;
  public data: string = undefined;
  public lastModified: Date = undefined;
  public name: string = undefined;
  public owner: string = undefined;
  public parent: string = undefined;
  public type: FileItemTypeDto = undefined;

  public constructor(fileItemDto?: Partial<FileItemDto>) {
    Object.assign(this, fileItemDto);
  }
}

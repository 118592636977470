import {Asset} from '../../asset/api/asset';
import {JsonExportable} from '../../shared/classes/json-exportable';

import {SensorConfigValue} from './sensor-config-value';

export class SensorRegistration implements JsonExportable {
  public registrationParams: SensorConfigValue[] = undefined;
  public sensorAsset: Asset = undefined;

  public constructor(sensorRegistration?: Partial<SensorRegistration>) {
    Object.assign(this, sensorRegistration);
  }

  public toJSON(): Object {
    return {
      registrationParams: this.registrationParams,
      sensorAsset: this.sensorAsset,
    };
  }
}

export class PropertyDto {
  public id: number = undefined;
  public name: string = undefined;
  public description: string = undefined;
  public value: string = undefined;
  public type: string = undefined;

  public constructor(propertyDto?: Partial<PropertyDto>) {
    Object.assign(this, propertyDto);
  }
}

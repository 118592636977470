import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from 'ngx-flexible-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatOptionModule } from '@angular/material/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { TranslateModule } from '@ngx-translate/core';

import { PhoenixDialogNewModule } from '../../../components/phoenix-dialog-new/phoenix-dialog-new.module';
import { PhoenixFormModule } from '../../../components/phoenix-form/phoenix-form.module';
import { SensorApiModule } from '../../../gapicon/sensor/sensor-api.module';

import { PhoenixSensormanagementDeleteDialogService } from './phoenix-sensormanagement-delete-dialog.service';
import { PhoenixSensormanagementDialogComponent } from './phoenix-sensormanagement-dialog.component';
import { PhoenixSensormanagementDialogService } from './phoenix-sensormanagement-dialog.service';
import { PhoenixSensormanagementInformationDialogComponent } from './phoenix-sensormanagement-information-dialog/phoenix-sensormanagement-information-dialog.component';

@NgModule({
    imports: [
        CommonModule,
        TranslateModule,
        FlexLayoutModule,
        ReactiveFormsModule,
        FormsModule,
        MatSlideToggleModule,
        MatFormFieldModule,
        MatInputModule,
        MatSelectModule,
        MatOptionModule,
        MatListModule,
        PhoenixDialogNewModule,
        PhoenixFormModule,
        SensorApiModule,
    ],
    declarations: [
        PhoenixSensormanagementDialogComponent,
        PhoenixSensormanagementInformationDialogComponent,
    ],
    providers: [
        PhoenixSensormanagementDialogService,
        PhoenixSensormanagementDeleteDialogService,
    ]
})
export class PhoenixSensormanagementDialogModule {
}

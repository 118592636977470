import {Component, Input} from '@angular/core';
import {UntypedFormGroup} from '@angular/forms';

import {PhoenixDynamicFormControl} from '../../classes/phoenix-dynamic-form-control';

@Component({
  selector: 'phoenix-dynamic-chips',
  templateUrl: './phoenix-dynamic-chips.component.html',
  styleUrls: ['./phoenix-dynamic-chips.component.scss'],
})
export class PhoenixDynamicChipsComponent {
  @Input()
  public phoenixFormControl: PhoenixDynamicFormControl<string[]>;

  @Input()
  public form: UntypedFormGroup;


}

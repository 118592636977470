import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from 'ngx-flexible-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatBadgeModule } from '@angular/material/badge';
import { MatOptionModule } from '@angular/material/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { TranslateModule } from '@ngx-translate/core';

import { PhoenixDialogNewModule } from '../../../components/phoenix-dialog-new/phoenix-dialog-new.module';
import { PhoenixFormModule } from '../../../components/phoenix-form/phoenix-form.module';
import { PhoenixNavigationServiceModule } from '../../../services/phoenix-navigation-service/phoenix-navigation-service.module';

import { PhoenixMeasurementTypeDialogComponent } from './phoenix-measurement-type-dialog.component';
import { PhoenixMeasurementTypeDialogService } from './phoenix-measurement-type-dialog.service';
import { PhoenixMeasurementTypeExtendedInformationsDialogComponent } from './phoenix-measurement-type-extended-informations-dialog/phoenix-measurement-type-extended-informations-dialog.component';
import { PhoenixMeasurementTypeInformationDialogComponent } from './phoenix-measurement-type-information-dialog/phoenix-measurement-type-information-dialog.component';
import { PhoenixMeasurementTypeInformationDialogService } from './phoenix-measurement-type-information-dialog/phoenix-measurement-type-information-dialog.service';


@NgModule({
    imports: [
        CommonModule,
        TranslateModule,
        FlexLayoutModule,
        ReactiveFormsModule,
        FormsModule,
        MatSlideToggleModule,
        MatFormFieldModule,
        MatInputModule,
        MatSelectModule,
        MatOptionModule,
        MatListModule,
        MatIconModule,
        MatBadgeModule,
        PhoenixDialogNewModule,
        PhoenixFormModule,
        PhoenixNavigationServiceModule,
    ],
    declarations: [
        PhoenixMeasurementTypeDialogComponent,
        PhoenixMeasurementTypeInformationDialogComponent,
        PhoenixMeasurementTypeExtendedInformationsDialogComponent,
    ],
    providers: [
        PhoenixMeasurementTypeDialogService,
        PhoenixMeasurementTypeInformationDialogService,
    ]
})
export class PhoenixMeasurementTypeDialogModule {
}

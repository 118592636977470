import { Injectable } from '@angular/core';
import { plainToClass } from 'class-transformer';

import { Asset } from '../../asset/api/asset';
import { AssetDto, PropertyDto } from '../../dto.module';
import { Group } from '../../group/api/group';
import { UserGroupUpdateDto } from '../../group/dto/user-group-update-dto';
import { UserGroupMapperService } from '../../group/mapper/usergroup-mapper.service';
import { TenantRequest } from '../api/tenant-request';
import { TenantSearchCriteria } from '../api/tenant-search-criteria';
import { TenantUpdateRequest } from '../api/tenant-update-request';
import { TenantRequestDto } from '../dto/tenant-request-dto';
import { TenantSearchCriteriaDto } from '../dto/tenant-search-criteria-dto';
import { TenantUpdateRequestDto } from '../dto/tenant-update-request-dto';
import { TenantJob } from '../api/tenant-job';
import { TenantJobDto } from '../dto/tenant-job-dto';
import * as _ from 'lodash';

@Injectable({
  providedIn: 'root',
})
export class TenantRequestMapperService {

  public constructor(
    private userGroupMapperService: UserGroupMapperService,
  ) {
  }

  public tenantRequestDtoToTenantRequest(tenantRequestDto: TenantRequestDto): TenantRequest {
    return plainToClass(TenantRequest, tenantRequestDto);
  }

  public tenantUpdateRequestDtoToTenantUpdateRequest(tenantRequestDto: TenantUpdateRequestDto): TenantUpdateRequest {
    return plainToClass(TenantUpdateRequest, tenantRequestDto);
  }

  public tenantSearchCriteriaDtoToTenantSearchCriteria(tenantSearchCriteriaDto: TenantSearchCriteriaDto): TenantSearchCriteria {
    return plainToClass(TenantSearchCriteria, tenantSearchCriteriaDto);
  }

  public tenantToTenantUpdateRequest(tenant: Asset): TenantUpdateRequest {
    const tenantUpdateRequest: TenantUpdateRequest = new TenantUpdateRequest();
    tenantUpdateRequest.name = tenant.name;
    tenantUpdateRequest.roles = tenant.tenant.roles;
    tenantUpdateRequest.description = tenant.description;
    tenantUpdateRequest.providers = tenant.providers;
    if (tenant.properties.find(property => property.name === 'sensorTypeThreshold')) {
      tenantUpdateRequest.sensorTypeThreshold = tenant.properties.find(property => property.name === 'sensorTypeThreshold').value;
    }
    if (tenant.properties.find(property => property.name === 'mobileSensorCheckInterval')) {
      tenantUpdateRequest.mobileSensorCheckInterval = tenant.properties.find(property => property.name === 'mobileSensorCheckInterval').value;
    }
    if (tenant.properties.find(property => property.name === 'stationarySensorCheckInterval')) {
      tenantUpdateRequest.stationarySensorCheckInterval = tenant.properties.find(property => property.name === 'stationarySensorCheckInterval').value;
    }
    //tenantUpdateRequest.sensorTypeThreshold = tenant.sensorTypeThreshold;
    return tenantUpdateRequest;
  }

  public assetDtoToTenantUpdateRequestDto(tenant: AssetDto): TenantUpdateRequestDto {
    const tenantUpdateRequestDto: TenantUpdateRequestDto = new TenantUpdateRequestDto();
    tenantUpdateRequestDto.name = tenant.name;
    tenantUpdateRequestDto.roles = tenant.tenant.roles;
    tenantUpdateRequestDto.description = tenant.description;
    tenantUpdateRequestDto.providers = tenant.providers;
    tenantUpdateRequestDto.sensorTypeThreshold = tenant.properties.find(property => property.name === 'sensorTypeThreshold') ? tenant.properties.find(property => property.name === 'sensorTypeThreshold').value : undefined;
    tenantUpdateRequestDto.mobileSensorCheckInterval = tenant.properties.find(property => property.name === 'mobileSensorCheckInterval') ? tenant.properties.find(property => property.name === 'mobileSensorCheckInterval').value : undefined;
    tenantUpdateRequestDto.stationarySensorCheckInterval = tenant.properties.find(property => property.name === 'stationarySensorCheckInterval') ? tenant.properties.find(property => property.name === 'stationarySensorCheckInterval').value : undefined;
    return tenantUpdateRequestDto;
  }

  public assetDtoToUserGroupUpdateDto(tenant: AssetDto): UserGroupUpdateDto {
    const userGroupUpdateDto: UserGroupUpdateDto = new UserGroupUpdateDto();
    userGroupUpdateDto.assignedUserGroups = this.userGroupMapperService.groupsDtoToGroups(tenant.assignedUserGroups);
    return userGroupUpdateDto;
  }

  public tenantJobDtoToTenantJob(tenantJobDto: TenantJobDto): TenantJob {
    /*
    const tenantJob: TenantJob = new TenantJob();
    tenantJob.tenantName = tenantJobDto.tenantName;
    tenantJob.jobType = tenantJobDto.jobType;
    tenantJob.interval = tenantJobDto.interval;
    tenantJob.nextExecuteTime = tenantJobDto.nextExecuteTime;
    return tenantJob;
    */
    return plainToClass(TenantJob, tenantJobDto);
  }

  public tenantJobDtosToTenantJobs(tenantJobDtos: TenantJobDto[]): TenantJob[] {
    return _.map(tenantJobDtos, (tenantJobDto: TenantJobDto) => this.tenantJobDtoToTenantJob(tenantJobDto));
  }
}

import { TimeWindowConfigDayDto } from "./time-window-config-day-dto";

export class TimeWindowConfigDto {
  public monday: TimeWindowConfigDayDto = undefined;
  public tuesday: TimeWindowConfigDayDto = undefined;
  public wednesday: TimeWindowConfigDayDto = undefined;
  public thursday: TimeWindowConfigDayDto = undefined;
  public friday: TimeWindowConfigDayDto = undefined;
  public saturday: TimeWindowConfigDayDto = undefined;
  public sunday: TimeWindowConfigDayDto = undefined;
  public dstOffset: number = 0;

  public constructor(timeWindowConfigDto?: Partial<TimeWindowConfigDto>) {
    Object.assign(this, timeWindowConfigDto);
  }
}
import {SearchCriteriaPageableDto} from '../../shared/classes/search-criteria-pageable-dto';

export class NotificationConfigSearchCriteriaDto extends SearchCriteriaPageableDto {
  public assetIds: string[] = undefined;
  public measurementTypeKey: string = undefined;

  public constructor(notificationConfigSearchCriteriaDto?: Partial<NotificationConfigSearchCriteriaDto>) {
    super(notificationConfigSearchCriteriaDto);
    Object.assign(this, notificationConfigSearchCriteriaDto);
  }
}

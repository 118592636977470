import {Component, Input} from '@angular/core';

import {PhoenixFormIconButton} from './classes/phoenix-form-icon-button';

@Component({
  selector: 'phoenix-form-icon-button',
  templateUrl: './phoenix-form-icon-button.component.html',
  styleUrls: ['./phoenix-form-icon-button.component.scss'],
})
export class PhoenixFormIconButtonComponent {
  @Input() public element: PhoenixFormIconButton;
}

import { Measurement } from '../../measurement/api/measurement';
import { Sensor } from '../../sensor/api/sensor';
import { BaseEntity } from '../../shared/classes/base-entity';
import { JsonExportable } from '../../shared/classes/json-exportable';
import { MeasurementPoint } from '../asset-types/measurement-point/api/measurement-point';
import { Tenant } from '../asset-types/tenant/api/tenant';

import { AssetStatistics } from './asset-statistics';
import { Attachments } from './attachments';
import { TaskConfig } from './taskConfig';
import { UserGroupDto } from "../../group/dto/user-group-dto";
import { Group } from "../../group/api/group";
import { TimeWindowConfig } from './time-window-config';

export class Asset extends BaseEntity implements JsonExportable {

  public attachments: Attachments;
  public childStatistics: AssetStatistics;
  public code: string;
  public lastMeasurement: Measurement;
  public measurementPoint: MeasurementPoint;
  public parentId: string;
  public sensor: Sensor;
  public statistics: AssetStatistics;
  public taskConfigs: TaskConfig[] = [];
  public assignedUserGroups: Group[] = [];
  public tenant: Tenant;
  public timeWindowConfig: TimeWindowConfig;
  public costCenterCode: string;
  public costCenterType: string;
  public language: string;
  public provider: string;
  public providers: string[];
  //public sensorTypeThreshold: string;

  public constructor(asset?: Partial<Asset>) {
    super(asset);
    Object.assign(this, asset);
  }

  public toJSON(): Object {
    return {
      id: this.id,
      name: this.name,
      description: this.description,
      tags: this.tags,
      tenantId: this.tenantId,
      properties: this.properties,
      code: this.code,
      measurementPoint: this.measurementPoint,
      parentId: this.parentId,
      statistics: this.statistics,
      childStatistics: this.childStatistics,
      lastMeasurement: this.lastMeasurement,
      tenant: this.tenant,
      sensor: this.sensor,
      attachments: this.attachments,
      taskConfigs: this.taskConfigs,
      assignedUserGroups: this.assignedUserGroups,
      timeWindowConfig: this.timeWindowConfig,
      costCenterCode: this.costCenterCode,
      costCenterType: this.costCenterType,
      provider: this.provider,
      providers: this.providers,
      //sensorTypeThreshold: this.sensorTypeThreshold
    };
  }
}

import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';

import {SharedModule} from '../shared/shared.module';

import {EmailMapperService} from './mapper/email-mapper.service';
import {EmailApiService} from './services/email-api.service';


@NgModule({
  imports: [
    CommonModule,
    SharedModule,
  ],

  providers: [
    EmailApiService,
    EmailMapperService,
  ],
})
export class EmailApiModule {
}

import {TaskConfigRepeatType} from './task-config-repeat-type.enum';

export class TaskConfigRepeat {
  public config: { [key: string]: number[] };
  public type: TaskConfigRepeatType;
  public value: number;

  public constructor(partial?: Partial<TaskConfigRepeat>) {
    Object.assign(this, partial);
  }
}

import { JsonExportable } from '../../shared/classes/json-exportable';

export class TenantRequest implements JsonExportable {
  public name: string = undefined;
  public email: string = undefined;
  public description?: string = undefined;
  public roles: string[] = undefined;
  public providers: string[] = undefined;

  public constructor(tenantRequest?: Partial<TenantRequest>) {
    Object.assign(this, tenantRequest);
  }

  public toJSON(): Object {
    return {
      name: this.name,
      email: this.email,
      description: this.description,
      roles: this.roles,
      providers: this.providers,
    };
  }
}

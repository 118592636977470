import { MediaChange, MediaObserver } from 'ngx-flexible-layout';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class FuseMatchMediaService {
  activeMediaQuery: string;
  onMediaChange: BehaviorSubject<string> = new BehaviorSubject<string>('');

  /**
   * Constructor
   *
   * @param {MediaObserver} _observableMedia
   */
  constructor(
    private _observableMedia: MediaObserver
  ) {
    // Set the defaults
    this.activeMediaQuery = '';

    // Initialize
    this._init();

  }

  // -----------------------------------------------------------------------------------------------------
  // @ Private methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Initialize
   *
   * @private
   */
  private _init(): void {
    this._observableMedia.asObservable()
      .pipe(
        debounceTime(500),
        distinctUntilChanged()
      )
      .subscribe((changes: MediaChange[]) => {
        const change = changes[0] as MediaChange;
        if (change && this.activeMediaQuery !== change.mqAlias) {
          this.activeMediaQuery = change.mqAlias;
          this.onMediaChange.next(change.mqAlias);
        }
      });
  }


}

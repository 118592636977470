import {isValid} from 'date-fns';

import {SearchCriteria} from '../../shared/classes/search-criteria';

export class StatisticsSearchCriteria extends SearchCriteria {
  public measurementPointIds: number[] = undefined;
  public assetIds: string[] = undefined;
  public thresholdIds: number[] = undefined;
  public dateFrom: Date = undefined;
  public dateTo: Date = undefined;

  public constructor(statisticsSearchCriteria?: Partial<StatisticsSearchCriteria>) {
    super(statisticsSearchCriteria);
    Object.assign(this, statisticsSearchCriteria);
  }

  public toJSON(): Object {
    return {
      measurementPointIds: this.measurementPointIds,
      assetIds: this.assetIds,
      thresholdIds: this.thresholdIds,
      dateFrom: (this.dateFrom && isValid(this.dateFrom)) ? this.dateFrom.toISOString() : undefined,
      dateTo: (this.dateTo && isValid(this.dateTo)) ? this.dateTo.toISOString() : undefined,
    };
  }
}

import {NavigationCollector} from '../interfaces/navigation-collector';
import {NavigationElement} from '../interfaces/navigation-element';

/**
 * Base Collector class
 */
export abstract class NavigationBaseCollector implements NavigationCollector {
  public children: Array<NavigationElement> = [];
  public hidden: boolean;
  public id: string;
  public priority: number;
  public title: string;
  public translate: string;
  public type: string;
}

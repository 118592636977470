import {JsonExportable} from '../../shared/classes/json-exportable';

export class MeasurementValue implements JsonExportable {
  public key: string = undefined;
  public unit: string = undefined;
  public value: string = undefined;

  public constructor(measurementValue?: Partial<MeasurementValue>) {
    Object.assign(this, measurementValue);
  }

  public toJSON(): Object {
    return {
      key: this.key,
      unit: this.unit,
      value: this.value,
    };
  }
}

<phoenix-dialog-new [buttons]="data.buttons" [subtitle]="data.subtitle" [title]="data.title">
  <div fxLayout="column">
    <div fxLayout="row">
      <div fxFlex="0 1 auto">
        <span class="text-semibold m-20 text-truncate">{{'TASKCONFIG.DESCRIPTION' | translate}}</span>
      </div>
      <div fxFlex="grow">
      </div>
      <div fxFlex="0 1 auto">
        <button [matMenuTriggerFor]="menu" mat-icon-button>
          <mat-icon class="notranslate">add</mat-icon>
        </button>
        <mat-menu #menu="matMenu">
          <button (click)="openDialog(repeatType.once)" mat-menu-item>
            <mat-icon class="notranslate">plus_one</mat-icon>
            <span>{{'TASKCONFIG.MENU.ONCE' | translate}}</span>
          </button>
          <button (click)="openDialog(repeatType.daily)" mat-menu-item>
            <mat-icon class="notranslate">today</mat-icon>
            <span>{{'TASKCONFIG.MENU.DAILY' | translate}}</span>
          </button>
          <button (click)="openDialog(repeatType.weekly)" mat-menu-item>
            <mat-icon class="notranslate">view_day</mat-icon>
            <span>{{'TASKCONFIG.MENU.WEEKLY' | translate}}</span>
          </button>
          <button (click)="openDialog(repeatType.monthly)" mat-menu-item>
            <mat-icon class="notranslate">view_week</mat-icon>
            <span>{{'TASKCONFIG.MENU.MONTHLY' | translate}}</span>
          </button>
          <button (click)="openDialog(repeatType.annually)" mat-menu-item>
            <mat-icon class="notranslate">view_module</mat-icon>
            <span>{{'TASKCONFIG.MENU.ANNUALLY' | translate}}</span>
          </button>
        </mat-menu>
      </div>
    </div>
    <div *ngIf="data.taskConfigs.length === 0" fxLayout="row">
      <div fxFlex="grow"></div>
      <div fxFlex="0 1 auto">
        <span class="text-boxed">{{'TASKCONFIG.NOCONFIGURATIONS' | translate}}</span>
      </div>
      <div fxFlex="grow"></div>
    </div>
    <div *ngIf="data.taskConfigs.length > 0" fxLayout="column">
      <mat-expansion-panel *ngFor="let taskConfig of data.taskConfigs" fxFlex="1 1 auto">
        <mat-expansion-panel-header>
          <mat-panel-title>
            {{getPanelTitle(taskConfig)}}
          </mat-panel-title>
          <mat-panel-description>
            {{getPanelDescription(taskConfig)}}
          </mat-panel-description>
          <button (click)="editConfiguration(taskConfig)" mat-icon-button>
            <mat-icon class="notranslate">edit</mat-icon>
          </button>
          <button (click)="deleteConfiguration(taskConfig)" mat-icon-button>
            <mat-icon class="notranslate">delete</mat-icon>
          </button>
        </mat-expansion-panel-header>
        <ng-container *ngFor="let taskConfigText of getPanelContent(taskConfig)">
          <p>{{taskConfigText}}</p>
        </ng-container>
      </mat-expansion-panel>
    </div>
  </div>
</phoenix-dialog-new>
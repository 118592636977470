import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';

import { SharedModule } from '../shared/shared.module';

import { TokenRequestResponse } from './api/token-request-response';
import { TokenType } from './api/token-type.enum';
import { AuthInfoDto } from './dto/auth-info-dto';
import { AuthApiService } from './services/auth-api.service';

export {
  AuthInfoDto,
  TokenType,
  TokenRequestResponse,
};

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
  ],
  providers: [
    AuthApiService,
  ],
})
export class AuthApiModule {
}

import { Injectable } from '@angular/core';
import { plainToClass } from 'class-transformer';
import * as _ from 'lodash-es';

import { NotificationConfig } from '../api/notification-config';
import { NotificationConfigSearchCriteria } from '../api/notification-config-search-criteria';
import { NotificationType } from '../api/notification-type.enum';
import { NotificationConfigDto } from '../dto/notification-config-dto';
import { NotificationConfigSearchCriteriaDto } from '../dto/notification-config-search-criteria-dto';
import { NotificationTypeDto } from '../dto/notification-type-dto.enum';

@Injectable({
  providedIn: 'root',
})
export class NotificationConfigMapperService {

  public constructor() {
  }

  public notificationConfigToNotificationConfigDto(notificationConfig: NotificationConfig): NotificationConfigDto {
    const notificationConfigDto: NotificationConfigDto = plainToClass(NotificationConfigDto, notificationConfig);
    notificationConfigDto.notificationType = this.notificationTypeToNotificationTypeDto(notificationConfig.notificationType);
    return notificationConfigDto;
  }

  public notificationConfigDtoToNotificationConfig(notificationConfigDto: NotificationConfigDto): NotificationConfig {
    const notificationConfig: NotificationConfig = plainToClass(NotificationConfig, notificationConfigDto);
    notificationConfig.notificationType = this.notificationTypeDtoToNotificationType(notificationConfigDto.notificationType);
    return notificationConfig;
  }

  public notificationConfigArrayToNotificationConfigDtoArray(notificationConfigs: NotificationConfig[]): NotificationConfigDto[] {
    return _.map(notificationConfigs, (notificationConfig: NotificationConfig) => this.notificationConfigToNotificationConfigDto(notificationConfig));
  }

  public notificationTypeToNotificationTypeDto(notificationType: NotificationType): NotificationTypeDto {
    return notificationType ? NotificationType[notificationType.toUpperCase()] : undefined;
  }

  public notificationTypeDtoToNotificationType(notificationTypeDto: NotificationTypeDto): NotificationType {
    return notificationTypeDto ? NotificationTypeDto[notificationTypeDto.toUpperCase()] : undefined;
  }

  public notificationConfigSearchCriteriaDtoToNotificationConfigSearchCriteria(
    notificationConfigSearchCriteriaDto: NotificationConfigSearchCriteriaDto,
  ): NotificationConfigSearchCriteria {
    return plainToClass(NotificationConfigSearchCriteria, notificationConfigSearchCriteriaDto);
  }
}

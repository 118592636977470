import {AssetDto} from '../../../../gapicon/asset/dto/asset-dto';

export class PhoenixCloneConfirmationDialogData {
  public title: string;
  public description: string;
  public question: string;
  public rootClonedElement: AssetDto;

  public constructor(phoenixCloneConfirmationDialogData?: Partial<PhoenixCloneConfirmationDialogData>) {
    Object.assign(this, phoenixCloneConfirmationDialogData);
  }
}

import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';

import {SharedModule} from '../shared/shared.module';

import {RoleMapperService} from './mapper/role-mapper.service';
import {RoleApiService} from './services/role-api.service';

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
  ],
  providers: [
    RoleApiService,
    RoleMapperService,
  ],
})
export class RoleApiModule {
}

import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {map, reduce} from 'rxjs/operators';

import {UserDto} from '../../dto.module';
import {Group} from '../../group/api/group';
import {SharedService} from '../../shared/services/shared.service';
import {User} from '../../user/api/user';
import {UserMapperService} from '../../user/mapper/user-mapper.service';
import {Role} from '../api/role';
import {UpdateUsersRolesRequest} from '../api/update-users-roles-request';
import {RoleDto} from '../dto/role-dto';
import {RoleSearchCriteriaDto} from '../dto/role-search-criteria-dto';
import {RoleMapperService} from '../mapper/role-mapper.service';


@Injectable()
export class RoleApiService {

  private _role: string = 'role';
  private _roles: string = 'roles';
  private _user: string = 'user';
  private _users: string = 'users';
  private _tenant: string = 'tenant';

  /**
   * @param http
   * @param sharedService
   * @param roleMapperService
   * @param userMapperService
   */
  public constructor(private http: HttpClient,
                     private sharedService: SharedService,
                     private roleMapperService: RoleMapperService,
                     private userMapperService: UserMapperService,
  ) {
  }

  public addRoleToUser(userDto: UserDto, roleDto: RoleDto): Observable<UserDto> {
    return this.addRoleToUserById(userDto.id, roleDto.name);
  }

  public addRoleToUserById(userId: string, roleId: string): Observable<UserDto> {
    return this.http.post<User>(
      this.sharedService.buildApiUrl(this._users, this._user, userId, this._role, roleId),
      undefined,
    ).pipe(
      map((user: User) => this.userMapperService.userToUserDto(user)),
    );
  }

  /**
   * Gets a roleDto by given roleDto.
   * @param roleDto
   * @returns
   */
  public getRole(roleDto: RoleDto): Observable<RoleDto> {
    return this.getRoleById(roleDto.name);
  }

  /**
   * Gets a role by given id.
   * @param id
   * @returns
   */
  public getRoleById(id: string): Observable<RoleDto> {
    return this.http.get<Role>(
      this.sharedService.buildApiUrl(this._roles, this._role, id),
    ).pipe(
      map((role: Role) => this.roleMapperService.roleToRoleDto(role)),
    );
  }

  /**
   * Gets all roles matching the search criteria.
   * @param filter
   * @param withFurtherPages
   * @returns
   */
  public getRoles(filter: RoleSearchCriteriaDto = new RoleSearchCriteriaDto(), withFurtherPages: boolean = true): Observable<RoleDto[]> {
    return this.sharedService.httpGetWithPagination<Role>(
      this.sharedService.buildApiUrl(this._roles),
      this.roleMapperService.roleSearchCriteriaDtoToRoleSearchCriteria(filter),
      withFurtherPages,
    ).pipe(
      map((roles: Role[]) => this.roleMapperService.roleArrayToRoleDtoArray(roles)),
      reduce((all: RoleDto[], current: RoleDto[]) => all.concat(current)),
    );
  }

  public removeRoleFromUser(userDto: UserDto, roleDto: RoleDto): Observable<UserDto> {
    return this.removeRoleFromUserById(userDto.id, roleDto.name);
  }

  public removeRoleFromUserById(userId: string, roleId: string): Observable<UserDto> {
    return this.http.delete<User>(
      this.sharedService.buildApiUrl(this._users, this._user, userId, this._role, roleId),
    ).pipe(
      map((user: User) => this.userMapperService.userToUserDto(user)),
    );
  }

  public removeRolesFromUserById(userId: string): Observable<UserDto> {
    return this.http.delete<User>(
      this.sharedService.buildApiUrl(this._users, this._user, userId, this._tenant),
    ).pipe(
      map((user: User) => this.userMapperService.userToUserDto(user)),
    );
  }

  public updateUsersRoles(updateUsersRolesRequest: UpdateUsersRolesRequest, groupId: string): Observable<any> {
    const url: string = this.sharedService.buildApiUrl(this._users, this._roles, groupId.toString());
    return this.http.put<void>(url, updateUsersRolesRequest);
  }
}

import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subscriber } from 'rxjs';

import { CustomEncoderForHttpParameter } from '../../../classes/custom-encoder-for-http-parameter';
import { SharedService } from '../../shared/services/shared.service';
import { UserMapperService } from '../../user/mapper/user-mapper.service';
import { TokenRequestResponse } from '../api/token-request-response';
import { AuthInfoDto } from '../dto/auth-info-dto';

@Injectable()
export class AuthApiService {
  private static auth: AuthInfoDto = undefined;

  /**
   * Constructor
   * @param http
   * @param sharedService
   * @param userMapperService
   */
  public constructor(
    private http: HttpClient,
    private sharedService: SharedService,
    private userMapperService: UserMapperService,
  ) {
  }

  /**
   * Returns current auth info
   * @returns
   */
  public getAuth(): AuthInfoDto {
    return AuthApiService.auth;
  }

  /**
   * Logout current user
   */
  public logout(): void {
    // delete data from local storage when user logout
    localStorage.removeItem('selectedTenants');
    localStorage.removeItem('selectedAssetBranches');
    localStorage.removeItem('userSelection');
    localStorage.removeItem('selectedLanguage');
    localStorage.removeItem('availableTenants');
    AuthApiService.auth = null;

  }

  /**
   * Sets tenant for all requests
   * @param tenants
   */
  public setCurrentTenants(tenants: string[]): void {
    // tenants added in header
    AuthApiService.auth = new AuthInfoDto(this.userMapperService, AuthApiService.auth);
    AuthApiService.auth.currentTenants = tenants;
  }

  public setGroupAssetPairs(groupApiPairs: string[]): void {
    AuthApiService.auth.groupAssetPairs = groupApiPairs;
  }

  /*
  public getTenantsIdsFromGroupRole(roleArray: string[]): string[] {
    console.log("++++getTenantsIdsFromGroupRole roleArray:", roleArray);
    if (roleArray) {
      return roleArray.map((item: string) => {
        const items: string[] = item.split('/');
        return items[2];
      });
    } else {
      return [];
    }
  }
  */

  public getTenantsIdsFromGroupRole(roleArray: string[]): string[] {
    if (roleArray) {
      return roleArray.map((item: string) => {
        const items: string[] = item.split('.');
        return items.length === 2 ? items[1] : item;
      });
    } else {
      return [];
    }
  }

}



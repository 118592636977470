<div [ngSwitch]="phoenixFormControl.type" class="margin">

  <phoenix-dynamic-textbox [form]="form" [phoenixFormControl]="phoenixFormControl"
    *ngSwitchCase="phoenixDynamicFormControlType.TextBox"
    [translationParams]="translationParams"></phoenix-dynamic-textbox>

  <phoenix-dynamic-textarea [form]="form" [phoenixFormControl]="phoenixFormControl"
    *ngSwitchCase="phoenixDynamicFormControlType.TextArea"></phoenix-dynamic-textarea>

  <phoenix-dynamic-text [form]="form" [phoenixFormControl]="phoenixFormControl"
    *ngSwitchCase="phoenixDynamicFormControlType.Text" [translationParams]="translationParams"></phoenix-dynamic-text>

  <phoenix-dynamic-switch [form]="form" [phoenixFormControl]="phoenixFormControl"
    *ngSwitchCase="phoenixDynamicFormControlType.Switch"></phoenix-dynamic-switch>

  <phoenix-dynamic-password [form]="form" [phoenixFormControl]="phoenixFormControl"
    *ngSwitchCase="phoenixDynamicFormControlType.Password"></phoenix-dynamic-password>

  <phoenix-dynamic-chips [form]="form" [phoenixFormControl]="phoenixFormControl"
    *ngSwitchCase="phoenixDynamicFormControlType.Chips"></phoenix-dynamic-chips>

  <phoenix-dynamic-icon [form]="form" [phoenixFormControl]="phoenixFormControl"
    *ngSwitchCase="phoenixDynamicFormControlType.Icon"></phoenix-dynamic-icon>

  <phoenix-dynamic-dropdown [form]="form" [phoenixFormControl]="phoenixFormControl"
    *ngSwitchCase="phoenixDynamicFormControlType.Dropdown"></phoenix-dynamic-dropdown>

  <phoenix-dynamic-tabswitcher [form]="form" [phoenixFormControl]="phoenixFormControl"
    *ngSwitchCase="phoenixDynamicFormControlType.TabSwitcher"></phoenix-dynamic-tabswitcher>

  <phoenix-dynamic-button [form]="form" [phoenixFormControl]="phoenixFormControl"
    *ngSwitchCase="phoenixDynamicFormControlType.Button"></phoenix-dynamic-button>

  <phoenix-dynamic-threshold-limit [form]="form" [phoenixFormControl]="phoenixFormControl"
    *ngSwitchCase="phoenixDynamicFormControlType.ThresholdLimit"></phoenix-dynamic-threshold-limit>

  <phoenix-dynamic-form-control-array [form]="form" [phoenixFormControl]="phoenixFormControl"
    *ngSwitchCase="phoenixDynamicFormControlType.FormControlArray"
    [translationParams]="translationParams"></phoenix-dynamic-form-control-array>

  <phoenix-dynamic-checkbox [form]="form" [phoenixFormControl]="phoenixFormControl"
    *ngSwitchCase="phoenixDynamicFormControlType.CheckBox"></phoenix-dynamic-checkbox>

  <phoenix-dynamic-icon-button [form]="form" [phoenixFormControl]="phoenixFormControl"
    *ngSwitchCase="phoenixDynamicFormControlType.IconButton"></phoenix-dynamic-icon-button>

  <phoenix-dynamic-time-window-interval [form]="form" [phoenixFormControl]="phoenixFormControl"
    *ngSwitchCase="phoenixDynamicFormControlType.TimeWindowInterval"></phoenix-dynamic-time-window-interval>

</div>
import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';

import {PhoenixCommunicationService} from './phoenix-communication.service';

@NgModule({
  imports: [
    CommonModule,
  ],
  providers: [
    PhoenixCommunicationService,
  ],
})
export class PhoenixCommunicationServiceModule {
}

import {FileAttachment} from './file-attachment';
import {UrlAttachment} from './url-attachment';

export class Attachments {
  public files: FileAttachment[];
  public urls: UrlAttachment[];

  public constructor(attachments?: Partial<Attachments>) {
    Object.assign(this, attachments);
  }
}

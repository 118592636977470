import { SearchCriteriaPageableDto } from '../../shared/classes/search-criteria-pageable-dto';

export class MeasurementTypeSearchCriteriaDto extends SearchCriteriaPageableDto {
  public key: string = undefined;
  public name: string = undefined;
  public partialName: string = undefined;
  public tags: string[] = undefined;

  public constructor(measurementTypeSearchCriteriaDto?: Partial<MeasurementTypeSearchCriteriaDto>) {
    super(measurementTypeSearchCriteriaDto);
    Object.assign(this, measurementTypeSearchCriteriaDto);
  }
}

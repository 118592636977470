import {JsonExportable} from '../../shared/classes/json-exportable';

import {NotificationType} from './notification-type.enum';

export class NotificationConfig implements JsonExportable {
  public id: string = undefined;
  public name: string = undefined;
  public notificationType: NotificationType = undefined;
  public measurementType: string = undefined;
  public expression: string = '';

  public constructor(notificationConfig?: Partial<NotificationConfig>) {
    Object.assign(this, notificationConfig);
  }

  public toJSON(): Object {
    return {
      id: this.id,
      name: this.name,
      notificationType: this.notificationType,
      measurementType: this.measurementType,
      expression: this.expression,
    };
  }
}

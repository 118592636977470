import { Component, Input } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import _ from 'lodash-es';

import { PhoenixDynamicFormControl } from '../../classes/phoenix-dynamic-form-control';

@Component({
  selector: 'phoenix-dynamic-text',
  templateUrl: './phoenix-dynamic-text.component.html',
  styleUrls: ['./phoenix-dynamic-text.component.scss'],
})
export class PhoenixDynamicTextComponent {
  @Input()
  public phoenixFormControl: PhoenixDynamicFormControl<string>;

  @Input()
  public form: UntypedFormGroup;

  @Input()
  public translationParams: Object = {};

  public readOnlyTextStyle(): Object {
    if (_.has(this.phoenixFormControl.options, 'fontSize') && _.isNumber(this.phoenixFormControl.options['fontSize'])) {
      let style = { 'font-size.px': this.phoenixFormControl.options['fontSize'] };
      return style;
    }
    return {}
  }
}

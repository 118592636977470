import {JsonExportable} from '../../shared/classes/json-exportable';

import {SensorConfigValue} from './sensor-config-value';

export class SensorConfig implements JsonExportable {
  public configurations: SensorConfigValue[] = undefined;

  public constructor(sensor?: Partial<SensorConfig>) {
    Object.assign(this, sensor);
  }

  public toJSON(): Object {
    return {
      configurations: this.configurations,
    };
  }
}

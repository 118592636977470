import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';

import {SharedModule} from '../shared/shared.module';

import {TenantRequestMapperService} from './mapper/tenant-request-mapper.service';
import {TenantApiService} from './services/tenant-api.service';

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
  ],
  providers: [
    TenantApiService,
    TenantRequestMapperService,
  ],
})
export class TenantApiModule {
}

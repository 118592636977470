import {Component, Input} from '@angular/core';

import {ToolbarMenuItem} from '../toolbar-menu-item/toolbar-menu-item';

import {ToolbarMenu} from './toolbar-menu';

/**
 * ToolbarMenuComponent
 */
@Component({
  selector: 'phoenix-toolbar-menu',
  templateUrl: './toolbar-menu.component.html',
  styleUrls: ['./toolbar-menu.component.scss'],
})
export class ToolbarMenuComponent {
  @Input()
  public menu: ToolbarMenu;

  @Input()
  public entries: ToolbarMenuItem[];
}

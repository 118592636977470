import {UserGroupType} from '../enums/usergroup-status.enum';

export class UserGroupDto {
  public name: string = undefined;
  public id: string = undefined;
  public role: string = undefined;
  public type: UserGroupType = undefined;
  public assignedUsersIds: string[];
  public assignedUsers: string[];
  public notification: boolean = true;
  public constructor(userGroupDto?: Partial<UserGroupDto>) {
    Object.assign(this, userGroupDto);
  }
}

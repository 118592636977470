import { Injectable, OnDestroy } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { merge, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { PhoenixDialogResult } from '../../components/phoenix-dialog/classes/phoenix-dialog-result';
import { PhoenixDynamicFormControl } from '../../components/phoenix-dialog/classes/phoenix-dynamic-form-control';
import { PhoenixDynamicFormControlType } from '../../components/phoenix-dialog/enums/phoenix-dynamic-form-control-type.enum';
import { PhoenixDialogData } from '../../components/phoenix-dialog/interfaces/phoenix-dialog-data';
import { PhoenixDialogComponent } from '../../components/phoenix-dialog/phoenix-dialog/phoenix-dialog.component';
import { PhoenixSnackbarService } from '../../components/phoenix-snackbar/phoenix-snackbar.service';
import { AccountApiService } from '../../gapicon/account/services/account-api.service';
import { PasswordChangeDto } from '../../gapicon/dto.module';

@Injectable({
  providedIn: 'root',
})
export class PhoenixAccountInfoDialogService implements OnDestroy {
  private unsubscribe: Subject<void> = new Subject();

  public constructor(
    private dialog: MatDialog,
    private accountApi: AccountApiService,
    private snackbarService: PhoenixSnackbarService,
    private translateService: TranslateService,
  ) {
  }

  public async openChangePasswordDialog(): Promise<void> {
    const dialogRef: MatDialogRef<PhoenixDialogComponent> = await this.dialog.open(PhoenixDialogComponent, {
      width: '400px',
      data: <PhoenixDialogData>{
        headline: 'DIALOGS.PASSWORDCHANGE.TITLE',
        subHeadline: 'DIALOGS.PASSWORDCHANGE.SUBTITLE',
        buttons: {
          save: true,
          cancel: true,
        },
        onClose: (dialogResult: PhoenixDialogResult): Promise<void> => this.passwordChangeDialogCallback(dialogResult),
        formControls: [[
          this.createFormControlForPasswordDialog('passwordold', 'DIALOGS.PASSWORDCHANGE.OLD'),
          this.createFormControlForPasswordDialog('passwordnew', 'DIALOGS.PASSWORDCHANGE.NEW', { passwordRules: true }),
          this.createFormControlForPasswordDialog('passwordnewconfirm', 'DIALOGS.PASSWORDCHANGE.NEWCONFIRM', { passwordRules: true }),
        ]],
      },
    });

    await dialogRef.afterOpened().toPromise();

    merge(
      dialogRef.componentInstance.form.get('passwordnew').valueChanges,
      dialogRef.componentInstance.form.get('passwordnewconfirm').valueChanges,
    )
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(() => this.setErrorsForPasswordConfirmControl(dialogRef));
  }

  public ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  /**
   * sends result of dialog via gapicon to backend
   * @param dialogResult
   */
  private async passwordChangeDialogCallback(dialogResult: PhoenixDialogResult): Promise<void> {
    if (dialogResult.result === 'save') {
      const passwordChange: PasswordChangeDto = new PasswordChangeDto();
      passwordChange.newPassword = dialogResult.formData['passwordnew'];
      passwordChange.oldPassword = dialogResult.formData['passwordold'];

      try {
        await this.accountApi.changePassword(passwordChange).toPromise();
        this.snackbarService.openPhoenixDefaultSnackbar(this.translateService.instant('DIALOGS.PASSWORDCHANGE.SUCCESS'));
      } catch (e) {
        console.error(e);
      }
    }
    dialogResult.dialogRef.close();
  }

  /**
   * Checks if both password and password confirmation provided in the dialog are equal and sets an form control error if not
   * @param dialogRef
   */
  private setErrorsForPasswordConfirmControl(dialogRef: MatDialogRef<PhoenixDialogComponent>): void {
    if (dialogRef.componentInstance.form.get('passwordnew').value !== dialogRef.componentInstance.form.get('passwordnewconfirm').value) {
      dialogRef.componentInstance.form.get('passwordnewconfirm').setErrors({ equal: true });
    } else {
      dialogRef.componentInstance.form.get('passwordnewconfirm').setErrors(undefined);
    }
  }

  private createFormControlForPasswordDialog(key: string, label: string, options: Object = {}): PhoenixDynamicFormControl<string> {
    return new PhoenixDynamicFormControl<string>(
      key,
      PhoenixDynamicFormControlType.Password,
      label,
      true,
      options);
  }
}

import {Injectable} from '@angular/core';
import {plainToClass} from 'class-transformer';

import {UserMapperService} from '../../user/mapper/user-mapper.service';
import {PasswordChange} from '../api/password-change';
import {PasswordReset} from '../api/password-reset';
import {PasswordResetRequest} from '../api/password-reset-request';
import {RegistrationConfirmation} from '../api/registration-confirmation';
import {RegistrationRequest} from '../api/registration-request';
import {RegistrationResponse} from '../api/registration-response';
import {PasswordChangeDto} from '../dto/password-change-dto';
import {PasswordResetDto} from '../dto/password-reset-dto';
import {PasswordResetRequestDto} from '../dto/password-reset-request-dto';
import {RegistrationConfirmationDto} from '../dto/registration-confirmation-dto';
import {RegistrationRequestDto} from '../dto/registration-request-dto';
import {RegistrationResponseDto} from '../dto/registration-response-dto';

@Injectable({
  providedIn: 'root',
})
export class AccountMapperService {

  public constructor(private userMapper: UserMapperService) {
  }

  public passwordChangeDtoToPasswordChange(passwordChangeDto: PasswordChangeDto): PasswordChange {
    return plainToClass(PasswordChange, passwordChangeDto);
  }

  public passwordResetDtoToPasswordReset(passwordResetDto: PasswordResetDto): PasswordReset {
    return plainToClass(PasswordReset, passwordResetDto);
  }

  public registrationRequestDtoToRegistrationRequest(registrationRequestDto: RegistrationRequestDto): RegistrationRequest {
    return plainToClass(RegistrationRequest, registrationRequestDto);
  }

  public registratrionResponseToRegistratrionResponseDto(registrationResponse: RegistrationResponse): RegistrationResponseDto {
    return plainToClass(RegistrationResponseDto, registrationResponse);
  }

  public registrationConfirmationDtoToRegistrationConfirmation(registrationConfirmationDto: RegistrationConfirmationDto): RegistrationConfirmation {
    const registrationConfirmation: RegistrationConfirmation = plainToClass(RegistrationConfirmation, registrationConfirmationDto);
    registrationConfirmation.user = this.userMapper.userDtoToUser(registrationConfirmationDto.user);
    return registrationConfirmation;
  }

  public passwordResetRequestDtoToPasswordResetRequest(passwortResetRequestDto: PasswordResetRequestDto): PasswordResetRequest {
    return plainToClass(PasswordResetRequest, passwortResetRequestDto);
  }
}

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { SharedModule } from '../shared/shared.module';

import { AlarmMapperService } from './mapper/alarm-mapper.service';
import { AlarmApiService } from './services/alarm-api.service';
//import { HTTP_INTERCEPTORS } from '@angular/common/http';
//import { DEFAULT_TIMEOUT, TimeoutInterceptor, defaultTimeout } from './services/timeout-interceptor.service';


@NgModule({
  imports: [
    CommonModule,
    SharedModule,
  ],
  providers: [
    AlarmApiService,
    AlarmMapperService,
    //{ provide: HTTP_INTERCEPTORS, useClass: TimeoutInterceptor, multi: true },
    //{ provide: DEFAULT_TIMEOUT, useValue: defaultTimeout }
  ],
})
export class AlarmApiModule {
}

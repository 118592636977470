export class FileAttachment {
  public label: string;
  public name: string;
  public contentEncoding: string;
  public contentType: string;

  public constructor(fileAttachment?: Partial<FileAttachment>) {
    Object.assign(this, fileAttachment);
  }
}

<ng-container [formGroup]="element.formGroup">
  <mat-label>{{element.placeholder | translate}}</mat-label>
  <mat-form-field [hintLabel]="element.hint | translate">
  <textarea [disabled]="element.disabled"
            [formControlName]="element.formControlName"
            [maxlength]="element.maxlength"
            [minlength]="element.minlength"
            [placeholder]="element.placeholder | translate"
            [readonly]="element.readonly"
            matInput></textarea>
    <mat-hint *ngIf="element.maxlength" align="end">
      {{element.formGroup.get(element.formControlName).value?.length || 0}}/{{ element.maxlength}}
    </mat-hint>
    <mat-error *ngIf="!element.formGroup.get(element.formControlName).valid">
      <span data-translate="DIALOG.FORM.INVALID" translate></span>
    </mat-error>
  </mat-form-field>
</ng-container>

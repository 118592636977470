import { Component, Inject, OnInit, ChangeDetectorRef } from '@angular/core';
import { AbstractControl, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TimeWindowConfigIntervallDto } from '@phoenix/gapicon/asset/dto/time-window-config-intervall-dto';
import * as _ from 'lodash-es';

import { PhoenixDialogResult } from '../classes/phoenix-dialog-result';
import { PhoenixDynamicFormControl } from '../classes/phoenix-dynamic-form-control';
import { PhoenixDynamicFormControlType } from '../enums/phoenix-dynamic-form-control-type.enum';
import { PhoenixDialogData } from '../interfaces/phoenix-dialog-data';

@Component({
  selector: 'phoenix-dialog',
  templateUrl: './phoenix-dialog.component.html',
  styleUrls: ['./phoenix-dialog.component.scss'],
})
export class PhoenixDialogComponent implements OnInit {

  public color: string = 'accent';
  public defaultButtonCaptionMap: Map<string, string> = new Map<string, string>();
  public form: UntypedFormGroup;
  public showSpinner: boolean = false;
  public translationParams: Object = {};

  public constructor(
    @Inject(MAT_DIALOG_DATA) public data: PhoenixDialogData,
    private dialogRef: MatDialogRef<PhoenixDialogComponent>,
    private cdref: ChangeDetectorRef
  ) {
    this.defaultButtonCaptionMap.set('save', 'DIALOG.BUTTONS.SAVE');
    this.defaultButtonCaptionMap.set('cancel', 'DIALOG.BUTTONS.CANCEL');
    this.defaultButtonCaptionMap.set('ok', 'DIALOG.BUTTONS.OK');
    this.defaultButtonCaptionMap.set('previous', 'DIALOG.BUTTONS.PREVIOUS');
    this.defaultButtonCaptionMap.set('delete', 'DIALOG.BUTTONS.DELETE');
    this.defaultButtonCaptionMap.set('exit', 'DIALOG.BUTTONS.EXIT');
  }

  ngAfterContentChecked() {
    this.cdref.detectChanges();
  }

  public async closeDialog(result: string): Promise<void> {
    this.showSpinner = true;
    if (this.data.onClose) {
      _.forEach(this.data.formControls, (formControls: PhoenixDynamicFormControl<string | boolean | number | TimeWindowConfigIntervallDto>[]) => {
        _.forEach(formControls, (formControl: PhoenixDynamicFormControl<string | boolean | number | TimeWindowConfigIntervallDto>) => {
          if (formControl.type === PhoenixDynamicFormControlType.Button) {
            formControl.disabled = true;
          }
        });
      });

      const dialogResult: PhoenixDialogResult = new PhoenixDialogResult();
      dialogResult.result = result;
      dialogResult.dialogRef = this.dialogRef;
      dialogResult.formData = this.getFormData();
      dialogResult.spinner = this.showSpinner;
      await this.data.onClose(dialogResult);
      this.showSpinner = dialogResult.spinner;
    } else {
      this.dialogRef.close({
        result: result,
        formData: this.getFormData(),
      });
    }
  }

  public formSubmit(): void {
    if (this.form.valid) {
      this.closeDialog('submit');
    }
  }

  public ngOnInit(): void {
    if (this.data.translationParams) {
      this.translationParams = this.data.translationParams;
    }

    if (this.data.formControls) {
      this.form = this.convertToFormGroup(this.data.formControls);
    }

    _.forEach(this.data.buttons, (button: boolean | string, key: string) => {
      if (_.isBoolean(button) && button) {
        this.data.buttons[key] = this.defaultButtonCaptionMap.get(key);
      } else if (typeof button === 'object') {
        this.data.buttons[key] = button;
      }
    });

    if (this.data.color) {
      this.color = this.data.color;
    }

    if (this.data.onInit) {
      this.data.onInit(this.form);
    }
  }

  private convertFormControlToFormGroup(formControls: PhoenixDynamicFormControl<string | boolean | number | TimeWindowConfigIntervallDto>[],
    formGroup: { [key: string]: AbstractControl }): { [key: string]: AbstractControl } {
    _.forEach(formControls, (formControl: PhoenixDynamicFormControl<string | boolean | number | TimeWindowConfigIntervallDto>) => {
      if (formControl.required && formControl.type === PhoenixDynamicFormControlType.Switch) {
        formGroup[formControl.key] = new UntypedFormControl(formControl.value, Validators.requiredTrue);
      } else if (formControl.required) {
        formGroup[formControl.key] = new UntypedFormControl(formControl.value, Validators.required);
      } else {
        formGroup[formControl.key] = new UntypedFormControl(formControl.value);
      }
      if (_.hasIn(formControl, 'options.tabs')) {
        _.forEach(formControl.options['tabs'], (tab: PhoenixDynamicFormControl<string | boolean | number | TimeWindowConfigIntervallDto>[][]) => {

          formGroup = _.merge(formGroup, this.convertFormControlToFormGroup(tab['formControls'], formGroup));
        });
      }

      if (formControl.disabled) {
        formGroup[formControl.key].disable();
      }
    });

    formGroup['buttonClicked'] = new UntypedFormControl();

    return formGroup;
  }

  private convertToFormGroup(formControls: PhoenixDynamicFormControl<string | boolean | string[] | number | TimeWindowConfigIntervallDto>[][]): UntypedFormGroup {
    let formGroup: { [key: string]: AbstractControl } = {};

    _.forEach(formControls, (column: PhoenixDynamicFormControl<string | boolean | number | TimeWindowConfigIntervallDto>[]) => {
      formGroup = this.convertFormControlToFormGroup(column, formGroup);
    });
    return new UntypedFormGroup(formGroup);
  }

  private getFormData(): Object {
    let data: Object = {};
    if (this.data.formControls && this.form) {
      _.forEach(this.data.formControls, (column: PhoenixDynamicFormControl<string | boolean | number | TimeWindowConfigIntervallDto>[]) => {
        data = this.getFormDateFromFromControl(data, column);
      });
      data['buttonClicked'] = this.form.get('buttonClicked');
    }

    return data;
  }

  private getFormDateFromFromControl(data: Object, formControlData: PhoenixDynamicFormControl<string | boolean | number | TimeWindowConfigIntervallDto>[]): Object {
    _.forEach(formControlData, (formControl: PhoenixDynamicFormControl<string | boolean>) => {
      if (_.hasIn(formControl, 'options.tabs')) {
        _.forEach(formControl.options['tabs'], (tab: PhoenixDynamicFormControl<string | boolean>[][]) => {

          data = _.merge(data, this.getFormDateFromFromControl(data, tab['formControls']));
        });
      }
      data[formControl.key] = this.form.get(formControl.key) ? this.form.get(formControl.key).value : undefined;
    });
    return data;
  }
}

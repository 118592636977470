import {SearchCriteriaPageable} from '../../shared/classes/search-criteria-pageable';

export class SensorSearchCriteria extends SearchCriteriaPageable {
  public isAssigned: boolean = undefined;
  public types: string[] = undefined;
  public sensorIds: string[] = undefined;
  public partialName: string = undefined;

  public constructor(sensorSearchCriteria?: Partial<SensorSearchCriteria>) {
    super(sensorSearchCriteria);
    Object.assign(this, sensorSearchCriteria);
  }

  public toJSON(): Object {
    return {
      page: this.page,
      size: this.size,
      isAssigned: this.isAssigned,
      sensorIds: this.sensorIds,
      partialName: this.partialName,
      types: this.types,
    };
  }
}

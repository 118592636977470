import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {map, reduce} from 'rxjs/operators';

import {SharedService} from '../../shared/services/shared.service';
import {MeasurementType} from '../api/measurement-type';
import {MeasurementTypeDto} from '../dto/measurement-type-dto';
import {MeasurementTypeSearchCriteriaDto} from '../dto/measurement-type-search-criteria-dto';
import {MeasurementTypeMapperService} from '../mapper/measurement-type-mapper.service';

@Injectable({
  providedIn: 'root',
})
export class MeasurementTypeApiService {

  private _measurementTypes: string = 'measurementTypes';
  private _versions: string = 'versions';
  private _newVersion: string = 'newVersion';
  private _measurementType: string = 'measurementType';
  private _cloneElementPostfix: string = 'clone';

  public constructor(
    private http: HttpClient,
    private sharedService: SharedService,
    private measurementTypeMapper: MeasurementTypeMapperService,
  ) {
  }

  public getMeasurementTypes(
    measurementTypeSearchCriteriaDto: MeasurementTypeSearchCriteriaDto = new MeasurementTypeSearchCriteriaDto(),
    withFurtherPages: boolean = true,
  ): Observable<MeasurementTypeDto[]> {
    return this.sharedService.httpGetWithPagination<MeasurementType>(
      this.sharedService.buildApiUrl(this._measurementTypes),
      this.measurementTypeMapper.measurementTypeSearchCriteriaDtoToMeasurementTypeSearchCriteria(measurementTypeSearchCriteriaDto),
      withFurtherPages,
    ).pipe(
      map((measurementTypes: MeasurementType[]) => this.measurementTypeMapper.measurementTypeArrayToMeasurementTypeDtoArray(measurementTypes)),
      reduce((all: MeasurementTypeDto[], current: MeasurementTypeDto[]) => all.concat(current)),
    );
  }

  public getMeasurementTypesVersions(
      measurementTypeSearchCriteriaDto: MeasurementTypeSearchCriteriaDto = new MeasurementTypeSearchCriteriaDto(),
      withFurtherPages: boolean = true,
  ): Observable<MeasurementTypeDto[]> {
    return this.sharedService.httpGetWithPagination<MeasurementType>(
        this.sharedService.buildApiUrl(this._measurementTypes,this._versions),
        this.measurementTypeMapper.measurementTypeSearchCriteriaDtoToMeasurementTypeSearchCriteria(measurementTypeSearchCriteriaDto),
        withFurtherPages,
    ).pipe(
        map((measurementTypes: MeasurementType[]) => this.measurementTypeMapper.measurementTypeArrayToMeasurementTypeDtoArray(measurementTypes)),
        reduce((all: MeasurementTypeDto[], current: MeasurementTypeDto[]) => all.concat(current)),
    );
  }

  public createMeasurementType(measurementTypeDto: MeasurementTypeDto): Observable<MeasurementTypeDto> {
    return this.http.post<MeasurementType>(
      this.sharedService.buildApiUrl(this._measurementTypes, this._measurementType),
      this.measurementTypeMapper.measurementTypeDtoToMeasurementType(measurementTypeDto),
    ).pipe(
      map((measurementType: MeasurementType) => this.measurementTypeMapper.measurementTypeToMeasurementTypeDto(measurementType)),
    );
  }

  public cloneMeasurementType(measurementTypeDto: MeasurementTypeDto): Observable<MeasurementTypeDto> {
    return this.http.post<MeasurementType>(
      this.sharedService.buildApiUrl(this._measurementTypes, this._measurementType, this._cloneElementPostfix),
      this.measurementTypeMapper.measurementTypeDtoToMeasurementType(measurementTypeDto),
    ).pipe(
      map((measurementType: MeasurementType) => this.measurementTypeMapper.measurementTypeToMeasurementTypeDto(measurementType)),
    );
  }

  public updateMeasurementType(measurementTypeDto: MeasurementTypeDto): Observable<MeasurementTypeDto> {
    return this.http.put<MeasurementType>(
      this.sharedService.buildApiUrl(this._measurementTypes, this._measurementType, measurementTypeDto.id),
      this.measurementTypeMapper.measurementTypeDtoToMeasurementType(measurementTypeDto),
    ).pipe(
      map((measurementType: MeasurementType) => this.measurementTypeMapper.measurementTypeToMeasurementTypeDto(measurementType)),
    );
  }

  public updateMeasurementTypeKeepVersionChanges(measurementTypeDto: MeasurementTypeDto): Observable<MeasurementTypeDto> {
    return this.http.put<MeasurementType>(
        this.sharedService.buildApiUrl(this._measurementTypes, this._newVersion, this._measurementType, measurementTypeDto.id),
        this.measurementTypeMapper.measurementTypeDtoToMeasurementType(measurementTypeDto),
    ).pipe(
        map((measurementType: MeasurementType) => this.measurementTypeMapper.measurementTypeToMeasurementTypeDto(measurementType)),
    );
  }



  public deleteMeasurementType(measurementTypeDto: MeasurementTypeDto): Observable<void> {
    return this.http.delete<void>(
      this.sharedService.buildApiUrl(this._measurementTypes, this._measurementType, measurementTypeDto.id),
    );
  }
}

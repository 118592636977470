export class SensorConfigValueDto {
  public defaultValue: string = undefined;
  public key: string = undefined;
  public name: string = undefined;
  public type: string = undefined;
  public value: string = undefined;
  public unit: string = undefined;

  public constructor(sensor?: Partial<SensorConfigValueDto>) {
    Object.assign(this, sensor);
  }
}

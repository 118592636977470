import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';

import {SharedModule} from '../shared/shared.module';

import {ThresholdMapperService} from './mapper/threshold-mapper.service';
import {ThresholdApiService} from './services/threshold-api.service';

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
  ],
  providers: [
    ThresholdApiService,
    ThresholdMapperService,
  ],
})
export class ThresholdApiModule {
}

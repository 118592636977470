<div class="mt-12 mb-4 mat-caption">{{ phoenixFormControl.label | translate }}</div>
<mat-tab-group [formGroup]="form" [selectedIndex]="selectedIndex"
               (selectedIndexChange)="selectedIndex = $event">
  <input type="hidden" [formControlName]="phoenixFormControl.key">
  <mat-tab *ngFor="let tab of tabs" [label]="tab.label | translate" [disabled]="phoenixFormControl.disabled">
    <div class="tab-content">
      {{ tab.content | translate }}
      <ng-container *ngIf="tab.formControls">
        <phoenix-dialog-form-control *ngFor="let formControl of tab.formControls" [phoenixFormControl]="formControl"
                                     [form]="form"></phoenix-dialog-form-control>
      </ng-container>
    </div>
  </mat-tab>
</mat-tab-group>

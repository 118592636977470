import { Injectable } from '@angular/core';
import { plainToClass } from 'class-transformer';
import * as _ from 'lodash-es';

import { FileAccessToken } from '../api/file-access-token';
import { FileItem } from '../api/file-item';
import { FileItemType } from '../api/file-item-type.enum';
import { FileStorageInformation } from '../api/file-storage-information';
import { FileAccessTokenDto } from '../dto/file-access-token-dto';
import { FileItemDto } from '../dto/file-item-dto';
import { FileItemTypeDto } from '../dto/file-item-type-dto.enum';
import { FileStorageInformationDto } from '../dto/file-storage-information-dto';

@Injectable({
  providedIn: 'root',
})
export class FileMapperService {

  public constructor() {
  }

  public fileAccessTokenDtoToFileAccessToken(fileAccessTokenDto: FileAccessTokenDto): FileAccessToken {
    return new FileAccessToken(fileAccessTokenDto);
  }

  public fileAccessTokenToFileAccessTokenDto(fileAccessToken: FileAccessToken): FileAccessTokenDto {
    return new FileAccessTokenDto(fileAccessToken);
  }

  public fileItemArrayToFileItemDtoArray(fileItems: FileItem[]): FileItemDto[] {
    return _.map(fileItems, (fileItem: FileItem) => this.fileItemToFileItemDto(fileItem));
  }

  public fileItemDtoToFileItem(fileItemDto: FileItemDto): FileItem {
    const fileItem: FileItem = plainToClass(FileItem, fileItemDto);
    fileItem.type = FileItemType[fileItemDto.type];

    return fileItem;
  }

  public fileItemToFileItemDto(fileItem: FileItem): FileItemDto {
    const fileItemDto: FileItemDto = plainToClass(FileItemDto, fileItem);
    fileItemDto.lastModified = new Date(fileItem.lastModified);
    fileItemDto.type = FileItemTypeDto[fileItem.type];

    return fileItemDto;
  }

  public fileStorageInformationToFileStorageInformationDto(fileStorageInformation: FileStorageInformation): FileStorageInformationDto {
    return plainToClass(FileStorageInformationDto, fileStorageInformation);
  }
}

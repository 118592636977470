import {TaskConfigRepeatTypeDto} from './task-config-repeat-type-dto.enum';

export class TaskConfigRepeatDto {
  public config: { [key: string]: number[] };
  public type: TaskConfigRepeatTypeDto;
  public value: number;

  public constructor(partial?: Partial<TaskConfigRepeatDto>) {
    Object.assign(this, partial);
  }
}

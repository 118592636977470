<div [formGroup]="form" *ngIf="phoenixFormControl.value">
  <div class="mt-12 mb-4" fxLayout="row" fxLayoutAlign="start center">

    <div fxFlex="10" fxLayoutAlign="center">
      <mat-icon class="notranslate w-24 h-24 font-size-24 material-icons"
        [ngStyle]="getStatusIconStyle()">{{statusIcon}}</mat-icon>
    </div>

    <div fxFlex="37" fxLayoutAlign="center">
      <div class="time-select">
        <mat-form-field>
          <mat-select placeholder="{{'DATETIME.SELECT.HOUR' | translate}}" [value]="getStartTimeHours()"
            (selectionChange)="startTimeHoursOnChange($event.value);">
            <mat-option *ngFor="let hour of hours" [value]="hour">{{hour}}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="time-select">
        <mat-form-field>
          <mat-select placeholder="{{'DATETIME.SELECT.MINUTE' | translate}}" [value]="getStartTimeMinutes()"
            (selectionChange)="startTimeMinutesOnChange($event.value);">
            <mat-option *ngFor="let minute of minutes" [value]="minute">{{minute}}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>

    <div fxFlex="6">
      <hr>
    </div>

    <div fxFlex="37" fxLayoutAlign="center">
      <div class="time-select">
        <mat-form-field>
          <mat-select placeholder="{{'DATETIME.SELECT.HOUR' | translate}}" [value]="getEndTimeHours()"
            (selectionChange)="endTimeHoursOnChange($event.value);">
            <mat-option *ngFor="let hour of hours" [value]="hour">{{hour}}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="time-select">
        <mat-form-field>
          <mat-select placeholder="{{'DATETIME.SELECT.MINUTE' | translate}}" [value]="getEndTimeMinutes()"
            (selectionChange)="endTimeMinutesOnChange($event.value);">
            <mat-option *ngFor="let minute of minutes" [value]="minute">{{minute}}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>

    <div fxFlex="10" fxLayoutAlign="center">
      <button *ngIf="this.phoenixFormControl.options['deleteCallback']" class="delete-button"
        (click)="deleteCallbackFromOptions()" [color]="'warn'"
        [disabled]="phoenixFormControl.disabled || phoenixFormControl.options['deleteDisabled']" mat-mini-fab
        type="button">
        <mat-icon class="notranslate delete-button-icon">{{ 'delete' }}
        </mat-icon>
      </button>
    </div>

  </div>
</div>
import moment, { Moment } from 'moment';
import { Injectable } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { PhoenixDialogResult } from '@phoenix/components/phoenix-dialog/classes/phoenix-dialog-result';
import { PhoenixDynamicFormControl } from '@phoenix/components/phoenix-dialog/classes/phoenix-dynamic-form-control';
import { PhoenixDynamicFormControlType } from '@phoenix/components/phoenix-dialog/enums/phoenix-dynamic-form-control-type.enum';
import { PhoenixDialogData } from '@phoenix/components/phoenix-dialog/interfaces/phoenix-dialog-data';
import { PhoenixDialogComponent } from '@phoenix/components/phoenix-dialog/phoenix-dialog/phoenix-dialog.component';
import { PhoenixSnackbarService } from '@phoenix/components/phoenix-snackbar/phoenix-snackbar.service';
import { PhoenixIcon } from '@phoenix/enums/phoenix-icon.enum';
import { PhoenixStatusColor } from '@phoenix/enums/phoenix-status-color.enum';
import { TimeWindowConfigDayDto } from '@phoenix/gapicon/asset/dto/time-window-config-day-dto';
import { TimeWindowConfigDto } from '@phoenix/gapicon/asset/dto/time-window-config-dto';
import { TimeWindowConfigIntervallDto } from '@phoenix/gapicon/asset/dto/time-window-config-intervall-dto';
import _ from 'lodash-es';
import { PhoenixCommunicationService } from '../phoenix-communication-service/phoenix-communication.service';
import { PhoenixTimeWindowConfigDayWizardService } from './phoenix-time-window-config-day-wizard.service';
import { PhoenixWizardHelperService } from './phoenix-wizard-helper.service';

@Injectable({
  providedIn: 'root'
})
export class PhoenixTimeWindowConfigWizardService {

  private dialogTimeWindowConfig: TimeWindowConfigDto = undefined;
  private mpTimeWindowConfig: TimeWindowConfigDto = undefined;
  private openElementWizard: () => void = undefined;
  private rowFormControls: PhoenixDynamicFormControl<string | boolean | number>[][] = [];
  private statusIconButtonFormControls: PhoenixDynamicFormControl<string>[] = [];
  private weekdayLabelFormControls: PhoenixDynamicFormControl<string>[] = [];
  private editIntervalButtonFormControls: PhoenixDynamicFormControl<string>[] = [];
  private intervalLabelFormControls: PhoenixDynamicFormControl<string>[] = [];

  public constructor(
    private dialog: MatDialog,
    private communicationService: PhoenixCommunicationService,
    private translateService: TranslateService,
    private phoenixWizardHelper: PhoenixWizardHelperService,
    private phoenixSnackbarService: PhoenixSnackbarService,
    private timeWindowConfigDayWizardService: PhoenixTimeWindowConfigDayWizardService
  ) { }

  public openEditTimeWindowConfigWizard(
    timeWindowConfig: TimeWindowConfigDto,
    openElementWizard: () => void,
    afterTimeWindowConfigEditedCallBack?: (timeWindowConfig: TimeWindowConfigDto) => void
  ): void {
    this.openElementWizard = openElementWizard;
    this.mpTimeWindowConfig = timeWindowConfig;
    this.dialogTimeWindowConfig = _.cloneDeep(timeWindowConfig);
    this.dialogTimeWindowConfig = !this.dialogTimeWindowConfig ? new TimeWindowConfigDto() : this.dialogTimeWindowConfig;
    this.dialogTimeWindowConfig.monday = !this.dialogTimeWindowConfig.monday ? new TimeWindowConfigDayDto() : this.dialogTimeWindowConfig.monday;
    this.createIntervalIfEmpty(this.dialogTimeWindowConfig.monday);
    this.dialogTimeWindowConfig.tuesday = !this.dialogTimeWindowConfig.tuesday ? new TimeWindowConfigDayDto() : this.dialogTimeWindowConfig.tuesday;
    this.createIntervalIfEmpty(this.dialogTimeWindowConfig.tuesday);
    this.dialogTimeWindowConfig.wednesday = !this.dialogTimeWindowConfig.wednesday ? new TimeWindowConfigDayDto() : this.dialogTimeWindowConfig.wednesday;
    this.createIntervalIfEmpty(this.dialogTimeWindowConfig.wednesday);
    this.dialogTimeWindowConfig.thursday = !this.dialogTimeWindowConfig.thursday ? new TimeWindowConfigDayDto() : this.dialogTimeWindowConfig.thursday;
    this.createIntervalIfEmpty(this.dialogTimeWindowConfig.thursday);
    this.dialogTimeWindowConfig.friday = !this.dialogTimeWindowConfig.friday ? new TimeWindowConfigDayDto() : this.dialogTimeWindowConfig.friday;
    this.createIntervalIfEmpty(this.dialogTimeWindowConfig.friday);
    this.dialogTimeWindowConfig.saturday = !this.dialogTimeWindowConfig.saturday ? new TimeWindowConfigDayDto() : this.dialogTimeWindowConfig.saturday;
    this.createIntervalIfEmpty(this.dialogTimeWindowConfig.saturday);
    this.dialogTimeWindowConfig.sunday = !this.dialogTimeWindowConfig.sunday ? new TimeWindowConfigDayDto() : this.dialogTimeWindowConfig.sunday;
    this.createIntervalIfEmpty(this.dialogTimeWindowConfig.sunday);
    this.openTimeWindowConfigWizard(
      (dialogResult: PhoenixDialogResult): Promise<void> => this.onEditTimeWindowConfigCloseCallback(dialogResult, afterTimeWindowConfigEditedCallBack),
      'WIZARDS.SELECTOR.TITLE.TIMEWINDOW',
    );
  }

  public createIntervalIfEmpty(configDay: TimeWindowConfigDayDto) {
    if (configDay && configDay.enable && (!configDay.intervalls || configDay.intervalls.length == 0)) {
      configDay.intervalls = [];
      configDay.intervalls[0] = new TimeWindowConfigIntervallDto({ startTime: '0:00', endTime: '0:00' });
    }
  }

  public openTimeWindowConfigWizard(
    onClose: Function,
    title: string
  ): void {
    this.dialog.open(PhoenixDialogComponent, {
      width: this.phoenixWizardHelper.DIALOGWIDTHBIG,
      autoFocus: false,
      data: <PhoenixDialogData>{
        headline: title,
        subHeadline: 'WIZARDS.SELECTOR.SUBTITLE.TIMEWINDOW',
        onClose: onClose,
        buttons: {
          cancel: true,
          ok: true
        },
        formControls: [
          this.createTimeWindowConfigFormControl(),
        ],
      },
    });
  }

  public deleteIntervalIfNotNeeded(configDay: TimeWindowConfigDayDto) {
    if (configDay && configDay.enable && configDay.intervalls && configDay.intervalls.length == 1
      && configDay.intervalls[0].startTime == '0:00' && configDay.intervalls[0].endTime == '0:00'
      || configDay && configDay.intervalls && configDay.intervalls.length == 0) {
      configDay.intervalls = null;
    }
  }

  private async onEditTimeWindowConfigCloseCallback(dialogResult: PhoenixDialogResult, afterTimeWindowConfigEditedCallBack?: (timeWindowConfig: TimeWindowConfigDto) => void): Promise<void> {
    if (dialogResult.result === 'ok') {
      this.mpTimeWindowConfig = this.dialogTimeWindowConfig;
      this.deleteIntervalIfNotNeeded(this.mpTimeWindowConfig.monday);
      this.deleteIntervalIfNotNeeded(this.mpTimeWindowConfig.tuesday);
      this.deleteIntervalIfNotNeeded(this.mpTimeWindowConfig.wednesday);
      this.deleteIntervalIfNotNeeded(this.mpTimeWindowConfig.thursday);
      this.deleteIntervalIfNotNeeded(this.mpTimeWindowConfig.friday);
      this.deleteIntervalIfNotNeeded(this.mpTimeWindowConfig.saturday);
      this.deleteIntervalIfNotNeeded(this.mpTimeWindowConfig.sunday);
      this.mpTimeWindowConfig.dstOffset = moment().isDST() == true ? 1 : 0;
    }
    if (afterTimeWindowConfigEditedCallBack) {
      afterTimeWindowConfigEditedCallBack(this.mpTimeWindowConfig);
    }
    dialogResult.dialogRef.close();
  }

  private createTimeWindowConfigFormControl(): PhoenixDynamicFormControl<string | boolean | number>[] {
    let formControlRows: PhoenixDynamicFormControl<string | boolean | number>[] = [];
    for (let i = 0; i <= 6; i++) {
      formControlRows.push(this.createTimeWindowConfigFormControlRow(i))
    }
    return formControlRows;
  }

  private createTimeWindowConfigFormControlRow(dayIndex: number): PhoenixDynamicFormControl<string | boolean | number> {
    const configDay: TimeWindowConfigDayDto = this.dialogTimeWindowConfig[this.getWeekdayName(dayIndex)];
    this.rowFormControls[dayIndex] = [];
    this.statusIconButtonFormControls[dayIndex] = this.createStatusIconButtonFormControl(dayIndex);
    this.weekdayLabelFormControls[dayIndex] = this.createWeekdayLabelFormControl(dayIndex);
    this.editIntervalButtonFormControls[dayIndex] = this.createEditIntervalButtonFormControl(dayIndex);
    this.intervalLabelFormControls[dayIndex] = this.createIntervalLabelFormControl(dayIndex);
    this.rowFormControls[dayIndex][0] = this.statusIconButtonFormControls[dayIndex];
    this.rowFormControls[dayIndex][1] = this.weekdayLabelFormControls[dayIndex];
    this.rowFormControls[dayIndex][2] = this.editIntervalButtonFormControls[dayIndex];
    this.rowFormControls[dayIndex][3] = this.intervalLabelFormControls[dayIndex];
    if (!configDay.enable) {
      this.rowFormControls[dayIndex][2].options['hidden'] = true;
    }
    return new PhoenixDynamicFormControl<string>(
      'timeWindowConfigArray' + dayIndex,
      PhoenixDynamicFormControlType.FormControlArray,
      undefined,
      false,
      {
        push: 2,
        topMargin: false,
        formControls: this.rowFormControls[dayIndex]
      }
    );
  }

  private getWeekdayName(dayIndex: number): string {
    switch (dayIndex) {
      case 0:
        return 'monday';
      case 1:
        return 'tuesday';
      case 2:
        return 'wednesday';
      case 3:
        return 'thursday';
      case 4:
        return 'friday';
      case 5:
        return 'saturday';
      case 6:
        return 'sunday';
      default:
        return 'monday';
    }
  }

  private createIntervalLabelFormControl(dayIndex: number): PhoenixDynamicFormControl<string> {
    const configDay: TimeWindowConfigDayDto = this.dialogTimeWindowConfig[this.getWeekdayName(dayIndex)];
    let intervalText = '';
    if (configDay && configDay.intervalls && configDay.intervalls.length > 0) {
      intervalText = configDay.intervalls[0].startTime + ' - ' + configDay.intervalls[0].endTime;
      if (configDay.intervalls.length > 1) {
        intervalText += ' …';
      }
    }
    return new PhoenixDynamicFormControl<string>(
      'intervalLabel' + this.getWeekdayName(dayIndex),
      PhoenixDynamicFormControlType.Text,
      '',
      false,
      { fontSize: 24, classes: { 'time-window-interval': true } },
      intervalText,
      true
    );
  }

  private createEditIntervalButtonFormControl(dayIndex: number): PhoenixDynamicFormControl<string> {
    return new PhoenixDynamicFormControl<string>(
      'editIntervalButton' + this.getWeekdayName(dayIndex),
      PhoenixDynamicFormControlType.IconButton,
      '',
      false,
      {
        color: 'basic',
        iconColor: PhoenixStatusColor.DEFAULT,
        callback: async (form: UntypedFormGroup): Promise<void> => this.showTimeWindowConfigDayDialog(form, dayIndex),
      },
      PhoenixIcon.SCHEDULE,
    );
  }

  private createWeekdayLabelFormControl(dayIndex: number): PhoenixDynamicFormControl<string> {
    return new PhoenixDynamicFormControl<string>(
      'weekdayLabel' + this.getWeekdayName(dayIndex),
      PhoenixDynamicFormControlType.Text,
      '',
      false,
      { fontSize: 24 },
      this.translateService.instant('WIZARDS.TIMEWINDOW.WEEKDAYS.' + this.getWeekdayName(dayIndex).toUpperCase()),
      true
    );
  }

  private createStatusIconButtonFormControl(dayIndex: number): PhoenixDynamicFormControl<string> {
    const configDay: TimeWindowConfigDayDto = this.dialogTimeWindowConfig[this.getWeekdayName(dayIndex)];
    return new PhoenixDynamicFormControl<string>(
      'statusIconButton' + this.getWeekdayName(dayIndex),
      PhoenixDynamicFormControlType.IconButton,
      '',
      false,
      {
        color: 'basic',
        iconColor: this.getStatusIconButtonIconColor(configDay),
        callback: async (form: UntypedFormGroup): Promise<void> => this.switchTimeWindowStatus(form, dayIndex),
      },
      this.getStatusIconButtonIcon(configDay),
    );
  }

  private getStatusIconButtonIcon(configDay: TimeWindowConfigDayDto): string {
    if (configDay) {
      if (!configDay.enable) {
        return PhoenixIcon.LENS;
      } else if (configDay.intervalls && configDay.intervalls.length >= 1 &&
        !(configDay.intervalls[0].startTime == '0:00' && configDay.intervalls[0].endTime == '0:00')) {
        return PhoenixIcon.INCOMPLETE_CIRCLE;
      } else {
        return PhoenixIcon.INCOMPLETE_CIRCLE;
      }
    } else {
      return PhoenixIcon.LENS;
    }
  }

  private getStatusIconButtonIconColor(configDay: TimeWindowConfigDayDto): string {
    if (configDay) {
      if (!configDay.enable) {
        return PhoenixStatusColor.POSITIVE;
      } else if (configDay.intervalls && configDay.intervalls.length >= 1 &&
        !(configDay.intervalls[0].startTime == '0:00' && configDay.intervalls[0].endTime == '0:00')) {
        return PhoenixStatusColor.POSITIVE;
      } else {
        return PhoenixStatusColor.NEGATIVE;
      }
    } else {
      return PhoenixStatusColor.POSITIVE;
    }
  }

  private async switchTimeWindowStatus(form: UntypedFormGroup, dayIndex: number): Promise<void> {
    let configDay: TimeWindowConfigDayDto = this.dialogTimeWindowConfig[this.getWeekdayName(dayIndex)];
    if (configDay) {
      if (configDay.enable == false) {
        configDay.enable = true;
        configDay.intervalls = [];
        configDay.intervalls[0] = new TimeWindowConfigIntervallDto({ startTime: '0:00', endTime: '0:00' });
        this.intervalLabelFormControls[dayIndex].value = configDay.intervalls[0].startTime + ' - ' + configDay.intervalls[0].endTime;
        this.statusIconButtonFormControls[dayIndex].options['iconColor'] = this.getStatusIconButtonIconColor(configDay);
        this.statusIconButtonFormControls[dayIndex].value = this.getStatusIconButtonIcon(configDay);
        this.rowFormControls[dayIndex][2].options['hidden'] = false;
      } else {
        await this.openDeleteDayConfigWizard(dayIndex);
      }
    }
  }


  public async openDeleteDayConfigWizard(dayIndex: number): Promise<void> {
    const hintTextOptions: { classes: { [key: string]: boolean } } = {
      classes: {
        'font-size-12': true,
        'hint-text': true,
        'primary-300-fg': true,
        'mt-4': true,
        'mb-4': true,
      },
    };

    await this.dialog.open(PhoenixDialogComponent, {
      width: this.phoenixWizardHelper.DIALOGWIDTH,
      autoFocus: false,
      data: <PhoenixDialogData>{
        headline: 'WIZARDS.TIMEWINDOW.DELETE.TITLE',
        subHeadline: this.translateService.instant('WIZARDS.TIMEWINDOW.WEEKDAYS.' + this.getWeekdayName(dayIndex).toUpperCase()),
        buttons: {
          cancel: true,
          delete: 'WIZARDS.TIMEWINDOW.DELETE.SUBMIT',
        },
        onClose: (dialogResult: PhoenixDialogResult): Promise<void> => this.onDeleteDayConfigCloseCallback(dialogResult, dayIndex),
        color: 'warn',
        formControls: [[
          new PhoenixDynamicFormControl<string>(
            'assetName', PhoenixDynamicFormControlType.Text, undefined,
            false, hintTextOptions, 'WIZARDS.TIMEWINDOW.DELETE.CONFIRMATION',
          ),
        ]],
      },
    });
  }

  public async onDeleteDayConfigCloseCallback(dialogResult: PhoenixDialogResult, dayIndex: number): Promise<void> {
    if (dialogResult.result === 'delete') {
      let configDay: TimeWindowConfigDayDto = this.dialogTimeWindowConfig[this.getWeekdayName(dayIndex)];
      configDay.enable = false;
      configDay.intervalls = [];
      this.intervalLabelFormControls[dayIndex].value = '';
      this.statusIconButtonFormControls[dayIndex].options['iconColor'] = this.getStatusIconButtonIconColor(configDay);
      this.statusIconButtonFormControls[dayIndex].value = this.getStatusIconButtonIcon(configDay);
      this.rowFormControls[dayIndex][2].options['hidden'] = true;
    }
    dialogResult.dialogRef.close();
  }

  private async showTimeWindowConfigDayDialog(form: UntypedFormGroup, dayIndex: number): Promise<void> {
    let configDay: TimeWindowConfigDayDto = this.dialogTimeWindowConfig[this.getWeekdayName(dayIndex)];
    await this.timeWindowConfigDayWizardService.openEditTimeWindowConfigDayWizard(configDay, dayIndex, (timeWindowConfigDay: TimeWindowConfigDayDto) => {
      this.dialogTimeWindowConfig[this.getWeekdayName(dayIndex)] = timeWindowConfigDay;
      this.statusIconButtonFormControls[dayIndex].options['iconColor'] = this.getStatusIconButtonIconColor(timeWindowConfigDay);
      this.statusIconButtonFormControls[dayIndex].value = this.getStatusIconButtonIcon(timeWindowConfigDay);
      if (timeWindowConfigDay && timeWindowConfigDay.intervalls && timeWindowConfigDay.intervalls.length > 0) {
        let intervalLabel = timeWindowConfigDay.intervalls[0].startTime + ' - ' + timeWindowConfigDay.intervalls[0].endTime;
        if (timeWindowConfigDay.intervalls.length > 1) {
          intervalLabel += ' …';
        }
        this.intervalLabelFormControls[dayIndex].value = intervalLabel;
      }
    });
  }

}

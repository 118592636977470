import {ThresholdDto} from '../../../../threshold/dto/threshold-dto';

export class MeasurementPointDto {
  public sensorId: String = undefined;
  public type: string = undefined;
  public threshold: ThresholdDto = undefined;

  public constructor(measurementPointDto?: Partial<MeasurementPointDto>) {
    Object.assign(this, measurementPointDto);
  }
}

import { isValid } from 'date-fns';

import { SearchCriteriaPageable } from '../../shared/classes/search-criteria-pageable';
import moment from 'moment';

export class MeasurementSearchCriteria extends SearchCriteriaPageable {
  public measurementPointType: string = undefined;
  public sensorId: string = undefined;
  public assetId: string = undefined;
  public dateFrom: Date = undefined;
  public dateTo: Date = undefined;
  public type: string = undefined;
  public userIds: number[] = undefined;
  public assetIds: string[] = undefined;
  public thresholdIds: number[] = undefined;
  public ascendingSort: boolean = undefined;
  public ids: string[] = undefined;

  public constructor(measurementSearchCriteria?: Partial<MeasurementSearchCriteria>) {
    super(measurementSearchCriteria);
    Object.assign(this, measurementSearchCriteria);
  }

  public toJSON(): Object {

    return {
      measurementPointType: this.measurementPointType,
      sensorId: this.sensorId,
      assetId: this.assetId,
      dateFrom: (this.dateFrom && isValid(this.dateFrom)) ? this.dateFrom.toISOString() : undefined,
      dateTo: (this.dateTo && isValid(this.dateTo)) ? this.dateTo.toISOString() : undefined,
      type: this.type,
      userIds: this.userIds,
      assetIds: this.assetIds,
      thresholdIds: this.thresholdIds,
      page: this.page,
      size: this.size,
      ascendingSort: this.ascendingSort,
      ids: this.ids,
    };
  }
}

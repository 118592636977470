import { Component, Input, OnInit } from '@angular/core';

import { PhoenixDialogButton } from './classes/phoenix-dialog-button';


@Component({
  selector: 'phoenix-dialog-new',
  templateUrl: './phoenix-dialog-new.component.html',
  styleUrls: ['./phoenix-dialog-new.component.scss'],
})
export class PhoenixDialogNewComponent implements OnInit {

  @Input() public title: string;
  @Input() public subtitle: string;
  @Input() public buttons: PhoenixDialogButton[];
  @Input() public color: string = 'accent';

  public constructor() {
  }

  public ngOnInit(): void {
    // this.color = 
  }

}

import {SearchCriteriaPageableDto} from '../../shared/classes/search-criteria-pageable-dto';

export class SensorSearchCriteriaDto extends SearchCriteriaPageableDto {
  public isAssigned: boolean = undefined;
  public types: string[] = undefined;
  public sensorIds: string[] = undefined;
  public partialName: string = undefined;

  public constructor(sensorSearchCriteriaDto ?: Partial<SensorSearchCriteriaDto>) {
    super(sensorSearchCriteriaDto);
    Object.assign(this, sensorSearchCriteriaDto);
  }
}

import {Injectable} from '@angular/core';
import {Group} from '../api/group';
import {UserGroupDto} from '../dto/user-group-dto';
import {UserGroupType} from '../enums/usergroup-status.enum';

@Injectable({
  providedIn: 'root',
})
export class UserGroupMapperService {

  public constructor() {
  }

  public groupsToGroupsDto(groups: Group[], allGroupsVisibility: boolean = true): UserGroupDto[] {
    let groupsDto: UserGroupDto[] = groups.map((group: Group) => {
      return this.groupToGroupDto(group);
    });
    if (!allGroupsVisibility) {
      groupsDto = groupsDto.filter((groupDto: UserGroupDto) => groupDto.type !== UserGroupType.RESTRICTED);
    }
    return groupsDto;
  }


  public groupsDtoToGroups(groups: UserGroupDto[]): Group[] {
    return groups.map((group: UserGroupDto) => {
      return this.groupDtoToGroup(group);
    });
  }

  private checkGroupType(type: string): UserGroupType {
    switch (type) {
      case 'NORMAL':
        return UserGroupType.NORMAL;
      case 'ALL_USERS':
        return UserGroupType.ALL_USERS;
      default:
        return UserGroupType.RESTRICTED;

    }
  }

  public groupDtoToGroup(group: UserGroupDto): Group {

    return new Group({
      id: group.id,
      name: group.name,
      userIds: group.assignedUsersIds,
      role: group.role,
      type: group.type = this.checkGroupType(group.type),
      notification: group.notification,
    });
  }

  public groupToGroupDto(group: Group): UserGroupDto {
    return new UserGroupDto({
      id: group.id,
      name: group.name,
      assignedUsersIds: group.userIds,
      assignedUsers: group.userIds,
      role: group.role,
      type: group.type = this.checkGroupType(group.type),
      notification: group.notification,
    });
  }


}

export class TenantUpdateRequestDto {
  public name: string = undefined;
  public description?: string = undefined;
  public roles: string[] = undefined;
  public providers: string[] = undefined;
  public sensorTypeThreshold: string = undefined;
  public mobileSensorCheckInterval: string = undefined;
  public stationarySensorCheckInterval: string = undefined;

  public constructor(tenantRequest?: Partial<TenantUpdateRequestDto>) {
    Object.assign(this, tenantRequest);
  }
}

import {NotificationTypeDto} from './notification-type-dto.enum';

export class NotificationConfigDto {
  public id: string = undefined;
  public name: string = undefined;
  public notificationType: NotificationTypeDto = undefined;
  public measurementType: string = undefined;
  public expression: string = '';

  public constructor(notificationConfigDto?: Partial<NotificationConfigDto>) {
    Object.assign(this, notificationConfigDto);
  }
}

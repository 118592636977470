import {NavigationGroup} from '../../../../navigation/classes/navigation-group';

export const navbarGroups: {
  monitoring: NavigationGroup,
  tenantAdministration: NavigationGroup,
  environmentAdministration: NavigationGroup,
  information: NavigationGroup,
} = {
  // key of object and id must be the same!
  monitoring: new NavigationGroup('monitoring', 0, 'Monitoring', undefined),
  tenantAdministration: new NavigationGroup('tenantAdministration', 1, 'Tenant-Administration', undefined),
  environmentAdministration: new NavigationGroup('environmentAdministration', 2, 'Environment-Administration', undefined),
  information: new NavigationGroup('information', 3, 'Information', undefined),
};

import { Injectable } from '@angular/core';
import { plainToClass } from 'class-transformer';
import * as _ from 'lodash-es';

import { MeasurementPointMapperService } from '../../asset/asset-types/measurement-point/mapper/measurement-point-mapper.service';
import { PropertyMapperService } from '../../property/mapper/property-mapper.service';
import { UserMapperService } from '../../user/mapper/user-mapper.service';
import { Measurement } from '../api/measurement';
import { MeasurementValue } from '../api/measurement-value';
import { MeasurementDto } from '../dto/measurement-dto';
import { MeasurementValueDto } from '../dto/measurement-value-dto';

@Injectable({
  providedIn: 'root',
})
export class LatestMeasurementMapperService {

  public constructor(
    private measurementPointMapper: MeasurementPointMapperService,
    private propertyMapper: PropertyMapperService,
    private userMapper: UserMapperService,
  ) {
  }

  /*
  MeasurementDto <=> Measurement
   */
  public measurementDtoToMeasurement(measurementDto: MeasurementDto): Measurement {
    const measurement: Measurement = plainToClass(Measurement, measurementDto);

    measurement.asset = undefined;
    measurement.measurementValues = this.measurementValueDtoArrayToMeasurementValueArray(measurementDto.measurementValues);
    measurement.timestamp = new Date(measurementDto.timestamp);
    measurement.properties = this.propertyMapper.propertyDtoArrayToPropertyArray(measurementDto.properties);
    measurement.user = this.userMapper.userInfoDtoToUserInfo(measurementDto.user);

    return measurement;
  }

  public measurementToMeasurementDto(measurement: Measurement): MeasurementDto {
    const measurementDto: MeasurementDto = plainToClass(MeasurementDto, measurement);

    measurementDto.asset = undefined;
    measurementDto.measurementValues = this.measurementValueArrayToMeasurementValueDtoArray(measurement.measurementValues);
    measurementDto.timestamp = new Date(measurement.timestamp);
    measurementDto.properties = this.propertyMapper.propertyArrayToPropertyDtoArray(measurement.properties);
    measurementDto.user = this.userMapper.userInfoToUserInfoDto(measurement.user);

    return measurementDto;
  }

  public measurementArrayToMeasurementDtoArray(measurements: Measurement[]): MeasurementDto[] {
    return _.map(measurements, (measurement: Measurement) => this.measurementToMeasurementDto(measurement));
  }

  public measurementDtoArrayToMeasurementArray(measurementDtos: MeasurementDto[]): Measurement[] {
    return _.map(measurementDtos, (measurementDto: MeasurementDto) => this.measurementDtoToMeasurement(measurementDto));
  }

  /*
 MeasurementValueDto <=> MeasurementValue
  */

  public measurementValueDtoToMeasurementValue(measurementValueDto: MeasurementValueDto): MeasurementValue {
    return plainToClass(MeasurementValue, measurementValueDto);
  }

  public measurementValueToMeasurementValueDto(measurementValue: MeasurementValue): MeasurementValueDto {
    return plainToClass(MeasurementValueDto, measurementValue);
  }

  public measurementValueArrayToMeasurementValueDtoArray(measurementValues: MeasurementValue[]): MeasurementValueDto[] {
    return _.map(measurementValues, (measurementValue: MeasurementValue) => this.measurementValueToMeasurementValueDto(measurementValue));
  }

  public measurementValueDtoArrayToMeasurementValueArray(measurementValueDtos: MeasurementValueDto[]): MeasurementValue[] {
    return _.map(measurementValueDtos, (measurementValueDto: MeasurementValueDto) => this.measurementValueDtoToMeasurementValue(measurementValueDto));
  }
}

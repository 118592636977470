import { MeasurementDto, MeasurementPointDto } from '../../dto.module';
import { SensorDto } from '../../sensor/dto/sensor-dto';
import { BaseDto } from '../../shared/classes/base-dto';
import { TenantDto } from '../asset-types/tenant/dto/tenant-dto';

import { AssetStatisticsDto } from './asset-statistics-dto';
import { AttachmentsDto } from './attachments-dto';
import { TaskConfigDto } from './task-config-dto';
import { UserGroupDto } from "../../group/dto/user-group-dto";
import { TimeWindowConfigDto } from './time-window-config-dto';

export class AssetDto extends BaseDto {

  public attachments: AttachmentsDto = undefined;
  public childStatistics: AssetStatisticsDto = undefined;
  public code: string = undefined;
  public lastMeasurement: MeasurementDto = undefined;
  public measurementPoint: MeasurementPointDto = undefined;
  public parentId: string = undefined;
  public sensor: SensorDto = undefined;
  public statistics: AssetStatisticsDto = undefined;
  public taskConfigs: TaskConfigDto[] = [];
  public assignedUserGroups: UserGroupDto[] = [];
  public tenant: TenantDto = undefined;
  public timeWindowConfig: TimeWindowConfigDto = undefined;
  public costCenterCode: string = undefined;
  public costCenterType: string = undefined;
  public language: string = undefined;
  public timestamp: string = undefined;
  public provider: string = undefined;
  public providers: string[] = [];
  //public sensorTypeThreshold: string = undefined;

  public constructor(assetDto?: Partial<AssetDto>) {
    super(assetDto);
    Object.assign(this, assetDto);
  }
}

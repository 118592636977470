import { SearchCriteria } from './search-criteria';

export abstract class SearchCriteriaPageable extends SearchCriteria {
  public size: number = 5000;
  public page: number = 0;

  protected constructor(searchCriteriaPageable?: Partial<SearchCriteriaPageable>) {
    super(searchCriteriaPageable);
    Object.assign(this, searchCriteriaPageable);
  }
}

import { BaseDto } from '../../shared/classes/base-dto';
import {UserInfoDto} from '../../user/dto/user-info-dto';
import { TaskStatus } from '../enums/task-status.enum';

export class TaskDto extends BaseDto {

  public begin: Date = undefined;
  public createdDate: Date = undefined;
  public deadline: Date = undefined;
  public status: TaskStatus = undefined;
  public targetAssetId: string = undefined;
  public taskConfigId: string = undefined;
  public comment: string = undefined;
  public commentTimestamp: Date = undefined;
  public commentUser: UserInfoDto = undefined;

  public constructor(taskDto?: Partial<TaskDto>) {
    super(taskDto);
    Object.assign(this, taskDto);
  }
}

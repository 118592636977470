import { Injectable } from '@angular/core';
import { plainToClass } from 'class-transformer';
import * as _ from 'lodash-es';

import { SensorType } from '../api/sensor-type';
import { SensorTypeSearchCriteria } from '../api/sensor-type-search-criteria';
import { SensorTypeDto } from '../dto/sensor-type-dto';
import { SensorTypeSearchCriteriaDto } from '../dto/sensor-type-search-criteria-dto';

import { SensorMapperService } from './sensor-mapper.service';

@Injectable({
  providedIn: 'root',
})
export class SensorTypeMapperService {

  public constructor(
    private sensorMapper: SensorMapperService,
  ) {
  }

  /*
 SensorType <=> SensorTypeDto
*/
  public sensorTypeDtoToSensorType(sensorTypeDto: SensorTypeDto): SensorType {
    const sensorType: SensorType = plainToClass(SensorType, sensorTypeDto);
    sensorType.configurations = this.sensorMapper.sensorConfigValueDtoArrayToSensorConfigValueArray(sensorTypeDto.configurations);

    return sensorType;
  }

  public sensorTypeToSensorTypeDto(sensorType: SensorType): SensorTypeDto {
    const sensorTypeDto: SensorTypeDto = plainToClass(SensorTypeDto, sensorType);
    sensorTypeDto.configurations = this.sensorMapper.sensorConfigValueArrayToSensorConfigValueDtoArray(sensorType.configurations);

    return sensorTypeDto;
  }

  public sensorTypeDtoArrayToSensorTypeArray(sensorTypeDtos: SensorTypeDto[]): SensorType[] {
    return _.map(sensorTypeDtos, (sensorTypeDto: SensorTypeDto) => this.sensorTypeDtoToSensorType(sensorTypeDto));
  }

  public sensorTypeArrayToSensorTypeDtoArray(sensorTypes: SensorType[]): SensorTypeDto[] {
    return _.forEach(sensorTypes, (sensorType: SensorType) => this.sensorTypeToSensorTypeDto(sensorType));
  }

  /*
  SensorTypeSearchCriteria <=> SensorTypeSearchCriteriaDto
 */
  public sensorTypeSearchCriteriaDtoToSensorTypeSearchCriteria(sensorTypeSearchCriteriaDto: SensorTypeSearchCriteriaDto): SensorTypeSearchCriteria {
    return plainToClass(SensorTypeSearchCriteria, sensorTypeSearchCriteriaDto);
  }

  public sensorTypeSearchCriteriaToSensorTypeSearchCriteriaDto(sensorTypeSearchCriteria: SensorTypeSearchCriteria): SensorTypeSearchCriteriaDto {
    return plainToClass(SensorTypeSearchCriteriaDto, sensorTypeSearchCriteria);
  }
}


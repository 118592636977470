import {JsonExportable} from '../../shared/classes/json-exportable';

export class PasswordChange implements JsonExportable {
  public newPassword: string = undefined;
  public oldPassword: string = undefined;

  public constructor(passwordChange?: Partial<PasswordChange>) {
    Object.assign(this, passwordChange);
  }

  public toJSON(): Object {
    return {
      newPassword: this.newPassword,
      oldPassword: this.oldPassword,
    };
  }
}

import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import * as _ from 'lodash-es';

import { PhoenixDynamicFormControl } from '../../classes/phoenix-dynamic-form-control';

@Component({
  selector: 'phoenix-dynamic-dropdown',
  templateUrl: './phoenix-dynamic-dropdown.component.html',
  styleUrls: ['./phoenix-dynamic-dropdown.component.scss'],
})
export class PhoenixDynamicDropdownComponent implements OnInit {
  @Input()
  public phoenixFormControl: PhoenixDynamicFormControl<string>;
  @Input()
  public form: UntypedFormGroup;

  public ngOnInit(): void {
    if (this.phoenixFormControl.disabled) {
      this.form.get(this.phoenixFormControl.key).disable();
    }
  }

  public getElementLabel(key: string): string {
    const element: { key: string, label: string } = _.find(this.phoenixFormControl.options['options'], (option: { key: string, label: string }) => {
      return option.key === key;
    });
    return element ? element.label : '';
  }

  public selectAllForTheGivenDropdownObject(form: UntypedFormGroup, formControlKey: string, dropdownValues: { key: string, value: string }[]): void {
    form.get(formControlKey).setValue(_.map(dropdownValues, 'key'));
  }

}

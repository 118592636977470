import { JsonExportable } from '../../shared/classes/json-exportable';
import { Asset } from './asset';

export class DynamicAsset extends Asset implements JsonExportable {

  public costCenterCode: string = undefined;
  public costCenterType: string = undefined;
  public language: string = undefined;
  public duration: number = undefined
  public provider: string = undefined;



  public constructor(asset?: Partial<DynamicAsset>) {
    super(asset);
    Object.assign(this, asset);
  }

  public toJSON(): Object {
    return {
      id: this.id,
      name: this.name,
      description: this.description,
      tags: this.tags,
      tenantId: this.tenantId,
      properties: this.properties,
      code: this.code,
      measurementPoint: this.measurementPoint,
      parentId: this.parentId,
      statistics: this.statistics,
      childStatistics: this.childStatistics,
      lastMeasurement: this.lastMeasurement,
      tenant: this.tenant,
      sensor: this.sensor,
      attachments: this.attachments,
      taskConfigs: this.taskConfigs,
      assignedUserGroups: this.assignedUserGroups,
      timeWindowConfig: this.timeWindowConfig,
      costCenterCode: this.costCenterCode,
      costCenterType: this.costCenterType,
      language: this.language,
      duration: this.duration,
      provider: this.provider
    };
  }
}

import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { AuthApiService } from '../../auth/services/auth-api.service';
import { AuthInfoDto } from '../../dto.module';

@Injectable()
export class TenantInterceptor implements HttpInterceptor {

  public constructor(private authApi: AuthApiService) {
  }

  public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const auth: AuthInfoDto = this.authApi.getAuth();

    if (req.url.startsWith('http') || req.url.endsWith('/userinfo') || req.url.endsWith('/certs')) {
      return next.handle(req);
    }

    if (auth && auth.currentTenants && auth.currentTenants.length > 0) {
      req = this.setHeaderToRequest(req, auth);
      return next.handle(req);
    } else {
      return next.handle(req);
    }
  }

  private setHeaderToRequest(req: HttpRequest<any>, auth: AuthInfoDto): HttpRequest<any> {
    const header: { 'gc-tenants': string, 'gc-groups-assets'?: string } = {
      'gc-tenants': auth.currentTenants.join(','),
    };
    if (auth.groupAssetPairs.length > 0) {
      header['gc-groups-assets'] = auth.groupAssetPairs.join(',');//'6042791ecb4db91e50b70dcf:rieber1';//auth.groupAssetPairs.join(',');
    }
    req = req.clone({
      setHeaders: header,
    });
    return req;
  }
}

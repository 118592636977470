import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from 'ngx-flexible-layout';
import { MatSnackBarModule } from '@angular/material/snack-bar';

import { PhoenixButtonModule } from '../phoenix-button/phoenix-button.module';

import { PhoenixSnackbarComponent } from './phoenix-snackbar.component';
import { PhoenixSnackbarService } from './phoenix-snackbar.service';


@NgModule({
    imports: [
        CommonModule,
        MatSnackBarModule,
        PhoenixButtonModule,
        FlexLayoutModule,
    ],
    providers: [
        PhoenixSnackbarService,
    ],
    declarations: [
        PhoenixSnackbarComponent,
    ],
    exports: [
        PhoenixSnackbarComponent,
    ]
})
export class PhoenixSnackbarModule {
}

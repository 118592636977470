import { Injectable } from '@angular/core';
import { plainToClass } from 'class-transformer';
import * as _ from 'lodash-es';

import { UserMapperService } from '../../user/mapper/user-mapper.service';
import { Alarm } from '../api/alarm';
import { AlarmHistory } from '../api/alarm-history';
import { AlarmSearchCriteria } from '../api/alarm-search-criteria';
import { AlarmStatus } from '../api/alarm-status.enum';
import { AlarmDto } from '../dto/alarm-dto';
import { AlarmHistoryDto } from '../dto/alarm-history-dto';
import { AlarmSearchCriteriaDto } from '../dto/alarm-search-criteria-dto';
import { AlarmStatusDto } from '../dto/alarm-status-dto.enum';
import { AlarmCount } from '../api/alarmCount';
import { AlarmCountDto } from '../dto/alarm-count-dto';


@Injectable({
  providedIn: 'root',
})
export class AlarmMapperService {

  public constructor(
    private userMapperService: UserMapperService,
  ) {
  }

  public alarmArrayToAlarmDtoArray(alarms: Alarm[]): AlarmDto[] {
    return _.map(alarms, (alarm: Alarm) => this.alarmToAlarmDto(alarm));
  }

  public alarmDtoArrayToAlarmArray(alarmDtos: AlarmDto[]): Alarm[] {
    return _.map(alarmDtos, (alarmDto: AlarmDto) => this.alarmDtoToAlarm(alarmDto));
  }


  public alarmDtoToAlarm(alarmDto: AlarmDto): Alarm {
    const alarm: Alarm = plainToClass(Alarm, alarmDto);
    alarm.status = this.alarmStatusDtoToAlarmStatus(alarmDto.status);
    alarm.history = this.alarmHistoryDtoArrayToAlarmHistoryArray(alarmDto.history);
    alarm.updateUser = this.userMapperService.userInfoDtoToUserInfo(alarmDto.updateUser);
    return alarm;
  }

  public alarmHistoryArrayToAlarmHistoryDtoArray(alarmHistoryArray: AlarmHistory[]): AlarmHistoryDto[] {
    return _.map(alarmHistoryArray, (alarmHistory: AlarmHistory) => this.alarmHistoryToAlarmHistoryDto(alarmHistory));
  }

  public alarmHistoryDtoArrayToAlarmHistoryArray(alarmHistoryDtoArray: AlarmHistoryDto[]): AlarmHistory[] {
    return _.map(alarmHistoryDtoArray, (alarmHistoryDto: AlarmHistoryDto) => this.alarmHistoryDtoToAlarmHistory(alarmHistoryDto));
  }

  public alarmHistoryDtoToAlarmHistory(alarmHistoryDto: AlarmHistoryDto): AlarmHistory {
    return plainToClass(AlarmHistory, alarmHistoryDto);
  }

  public alarmHistoryToAlarmHistoryDto(alarmHistory: AlarmHistory): AlarmHistoryDto {
    const alarmHistoryDto: AlarmHistoryDto = plainToClass(AlarmHistoryDto, alarmHistory);
    alarmHistoryDto.timestamp = new Date(alarmHistory.timestamp);
    return alarmHistoryDto;
  }

  public alarmSearchCriteriaDtoToAlarmSearchCriteria(alarmSearchCriteriaDto: AlarmSearchCriteriaDto): AlarmSearchCriteria {
    return plainToClass(AlarmSearchCriteria, alarmSearchCriteriaDto);
  }

  public alarmStatusDtoToAlarmStatus(alarmStatusDto: AlarmStatusDto): AlarmStatus {
    return alarmStatusDto ? AlarmStatus[alarmStatusDto.toUpperCase()] : undefined;
  }

  public alarmStatusToAlarmStatusDto(alarmStatus: AlarmStatus): AlarmStatusDto {
    return alarmStatus ? AlarmStatusDto[alarmStatus.toUpperCase()] : undefined;
  }

  public alarmToAlarmDto(alarm: Alarm): AlarmDto {
    const alarmDto: AlarmDto = plainToClass(AlarmDto, alarm);
    alarmDto.timestamp = new Date(alarmDto.timestamp);
    alarmDto.updateTimestamp = new Date(alarmDto.updateTimestamp);
    alarmDto.status = this.alarmStatusToAlarmStatusDto(alarm.status);
    alarmDto.history = this.alarmHistoryArrayToAlarmHistoryDtoArray(alarm.history);
    alarmDto.updateUser = this.userMapperService.userInfoToUserInfoDto(alarm.updateUser);
    return alarmDto;
  }

  public alarmCountToAlarmCountDto(alarmCount: AlarmCount): AlarmCountDto {
    const alarmCountDto: AlarmCountDto = plainToClass(AlarmCountDto, alarmCount);
    alarmCountDto.monthYear = alarmCount.monthYear;
    alarmCountDto.activeInactiveCount = alarmCount.activeInactiveCount;
    alarmCountDto.closedCount = alarmCount.closedCount;
    return alarmCountDto;
  }

  public alarmCountDtoToAlarm(alarmCountDto: AlarmCountDto): AlarmCount {
    const alarmCount: AlarmCount = plainToClass(AlarmCount, alarmCountDto);
    alarmCount.monthYear = alarmCountDto.monthYear;
    alarmCount.activeInactiveCount = alarmCountDto.activeInactiveCount;
    alarmCount.closedCount = alarmCountDto.closedCount;
    return alarmCount;
  }

  public alarmCountArrayToAlarmCountDtoArray(alarmCounts: AlarmCount[]): AlarmCountDto[] {
    return _.map(alarmCounts, (alarmCount: AlarmCount) => this.alarmCountToAlarmCountDto(alarmCount));
  }
}


import {Component, Input} from '@angular/core';
import {UntypedFormGroup} from '@angular/forms';
import { TimeWindowConfigIntervallDto } from '@phoenix/gapicon/asset/dto/time-window-config-intervall-dto';

import {PhoenixDynamicFormControl} from '../../classes/phoenix-dynamic-form-control';
import {PhoenixDynamicFormControlType} from '../../enums/phoenix-dynamic-form-control-type.enum';

@Component({
  selector: 'phoenix-dialog-form-control',
  templateUrl: './phoenix-dialog-form-control.component.html',
  styleUrls: ['./phoenix-dialog-form-control.component.scss'],
})
export class PhoenixDialogFormControlComponent {
  public phoenixDynamicFormControlType: typeof PhoenixDynamicFormControlType = PhoenixDynamicFormControlType;

  @Input()
  public phoenixFormControl: PhoenixDynamicFormControl<string | boolean | number | TimeWindowConfigIntervallDto>;

  @Input()
  public form: UntypedFormGroup;

  @Input()
  public translationParams: Object = {};

  public constructor() {
  }

  public get isValid(): boolean {
    return this.form.controls[this.phoenixFormControl.key].valid;
  }
}

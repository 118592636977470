import {AsyncValidatorFn, ValidatorFn} from '@angular/forms';

import {PhoenixDynamicFormControlType} from '../enums/phoenix-dynamic-form-control-type.enum';

/**
 * Definition of a dynamic form control for PhoenixDialog
 */
export class PhoenixDynamicFormControl<T> {
  public value: T;
  public key: string;
  public type: PhoenixDynamicFormControlType;
  public label: string;
  public required: boolean;
  public options: Object;
  public disabled: boolean;
  public validators: ValidatorFn | ValidatorFn[] | null;
  public asyncValidators: AsyncValidatorFn | AsyncValidatorFn[] | null;

  /**
   * Create a new instance of PhoenixDynamicFormControl
   * @param {string} key - set a unique key for your FormControl. It will be used for returning form values.
   * @param {PhoenixDynamicFormControlType} type - type of the FormControl that should be created.
   * @param {string} label - mostly used for the placeholder attribute
   * @param {boolean} required - is a value required
   * @param {Object} options - define some extra settings (e.g. { maxlength: 42 } for TextAreas)
   * @param {T} defaultValue - form control will be set to this value on initialization
   * @param disabled
   * @param validators
   * @param asyncValidators
   */
  public constructor(
    key: string,
    type: PhoenixDynamicFormControlType,
    label: string,
    required ?: boolean,
    options?: Object,
    defaultValue?: T,
    disabled?: boolean,
    validators?: ValidatorFn | ValidatorFn[] | null,
    asyncValidators?: AsyncValidatorFn | AsyncValidatorFn[] | null,
  ) {
    this.key = key;
    this.type = type;
    this.label = label;
    this.required = required ? required : false;
    this.options = options ? options : {};
    this.value = defaultValue;
    this.disabled = disabled;
    this.validators = validators;
    this.asyncValidators = asyncValidators;
  }
}

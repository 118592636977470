import {NavigationType} from '../enums/navigation-type.enum';
import {NavigationElement} from '../interfaces/navigation-element';

import {NavigationBaseCollector} from './navigation-base-collector';

/**
 * Class for Group in Navigation. The element can have children.
 */
export class NavigationGroup extends NavigationBaseCollector {
  public hidden: boolean = true;
  public type: NavigationType = NavigationType.GROUP;

  public constructor(
    id: string,
    priority: number,
    title: string,
    translate: string,
    children: Array<NavigationElement> = [],
  ) {
    super();
    this.id = id;
    this.priority = priority;
    this.title = title;
    this.translate = translate;
    this.children = children;
  }
}

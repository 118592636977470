import {SearchCriteriaPageable} from '../../shared/classes/search-criteria-pageable';

export class NotificationConfigSearchCriteria extends SearchCriteriaPageable {
  public assetIds: string[] = undefined;
  public measurementTypeKey: string = undefined;

  public constructor(notificationConfigSearchCriteria?: Partial<NotificationConfigSearchCriteria>) {
    super(notificationConfigSearchCriteria);
    Object.assign(this, notificationConfigSearchCriteria);
  }

  public toJSON(): Object {
    return {
      assetIds: this.assetIds,
      measurementTypeKey: this.measurementTypeKey,
      page: this.page,
      size: this.size,
    };
  }
}

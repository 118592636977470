import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';

import {SharedModule} from '../shared/shared.module';

import {MeasurementTypeMapperService} from './mapper/measurement-type-mapper.service';
import {MeasurementTypeApiService} from './services/measurement-type-api.service';

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
  ],
  providers: [
    MeasurementTypeApiService,
    MeasurementTypeMapperService,
  ],
})
export class MeasurementTypeApiModule {
}

<div [ngClass]="getStyleClasses()"></div>
<phoenix-page [isLoggedIn]="isLoggedIn">
  <phoenix-page-header *ngIf="isLoggedIn">
    <phoenix-breadcrumbs></phoenix-breadcrumbs>
  </phoenix-page-header>
  <phoenix-page-content [isLoggedIn]="isLoggedIn"
    [ngClass]="{'content-card fuse-white-bg phoenix-border-radius': isLoggedIn, 'h-100-p': !isLoggedIn}">
    <!--
    *ngIf="true" hack is required here for router-outlet to work
    correctly. Otherwise, it won't register the changes on the
    layout and won't update the view.
    -->
    <router-outlet *ngIf="true"></router-outlet>
  </phoenix-page-content>
</phoenix-page>
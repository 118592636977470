import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';

import {SharedModule} from '../shared/shared.module';

import {AssetMapperService} from './mapper/asset-mapper.service';
import {AssetApiService} from './services/asset-api.service';


@NgModule({
  imports: [
    CommonModule,
    SharedModule,
  ],

  providers: [
    AssetApiService,
    AssetMapperService,
  ],
})
export class AssetApiModule {
}

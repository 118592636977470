import {Property} from '../../property/api/property';

export abstract class BaseEntity {
  public id: string;
  public name: string;
  public description: string;
  public tags: string[];
  public tenantId: string;
  public properties: Property[];

  protected constructor(baseEntity?: Partial<BaseEntity>) {
    Object.assign(this, baseEntity);
  }
}

import {NavigationType} from '../enums/navigation-type.enum';
import {NavigationElement} from '../interfaces/navigation-element';

import {NavigationBadge} from './navigation-badge';

/**
 * Class for Item in Navigation. The element can not have children.
 */
export class NavigationItem implements NavigationElement {
  public badge: NavigationBadge;
  public exactMatch: boolean;
  public function: Function;
  public hidden: boolean = false;
  public icon: string;
  public id: string;
  public priority: number;
  public title: string;
  public translate: string;
  public type: NavigationType = NavigationType.ITEM;
  public url: string;

  public constructor(
    id: string,
    priority: number,
    hidden: boolean = false,
    title: string,
    urlOrFunction: any,
    icon?: string,
    badge?: NavigationBadge,
    exactMatch?: boolean,
  ) {
    this.id = id;
    this.priority = priority;
    this.hidden = hidden;
    this.title = title;
    this.translate = title;
    this.icon = icon;
    this.badge = badge;

    switch (typeof urlOrFunction) {
      case 'string':
        this.url = urlOrFunction.toString();
        break;
      case 'function':
        this.function = urlOrFunction;
        break;
    }
    this.exactMatch = exactMatch;
  }
}

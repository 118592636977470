import { OAuthModuleConfig } from 'angular-oauth2-oidc';

export const authModuleConfig: OAuthModuleConfig = {
    resourceServer: {
        //allowedUrls: ['http://localhost:4200/api/*', 'http://localhost:4200/api/users/user/62385b2922024d757c9c2424'],
        customUrlValidation: (url: string) => {
            return url.startsWith("/api");
        },
        sendAccessToken: true,
    }
};
import {SearchCriteriaPageableDto} from '../../shared/classes/search-criteria-pageable-dto';

export class ThresholdSearchCriteriaDto extends SearchCriteriaPageableDto {
  public tags: string[] = undefined;
  public name: string = undefined;
  public partialName: string = undefined;

  public constructor(thresholdSearchCriteriaDto?: Partial<ThresholdSearchCriteriaDto>) {
    super(thresholdSearchCriteriaDto);
    Object.assign(this, thresholdSearchCriteriaDto);
  }
}

<mat-form-field [formGroup]="form" floatLabel="auto">

  <mat-label>{{phoenixFormControl.label | translate : translationParams}}
  </mat-label>

  <ng-container *ngIf="!phoenixFormControl.options['autocomplete']">
    <input matInput placeholder="{{phoenixFormControl.label | translate : translationParams}}"
      [formControlName]="phoenixFormControl.key">
  </ng-container>

  <ng-container *ngIf="phoenixFormControl.options['autocomplete']">
    <input matInput placeholder="{{phoenixFormControl.label | translate : translationParams}}"
      [formControlName]="phoenixFormControl.key" [matAutocomplete]="auto">

    <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn">
      <mat-option *ngFor="let option of filteredOptions | async" [value]="option">
        {{ option.label }}
      </mat-option>
    </mat-autocomplete>
  </ng-container>

  <mat-hint *ngIf="phoenixFormControl.options['hint']">{{ phoenixFormControl.options['hint'] | translate :
    translationParams }}
  </mat-hint>
  <mat-error *ngIf="form.get(phoenixFormControl.key).invalid">
    <span>{{ 'DIALOG.FORM.INVALID' | translate}}</span>
  </mat-error>
</mat-form-field>
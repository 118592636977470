import {JsonExportable} from '../../shared/classes/json-exportable';

import {SensorConfigValue} from './sensor-config-value';

export class SensorType implements JsonExportable {
  public id: string = undefined;
  public configurations: SensorConfigValue[] = undefined;
  public registrationParams: SensorConfigValue[] = undefined;
  public measurementTypes: string[] = undefined;
  public name: string = undefined;
  public key: string = undefined;

  public constructor(sensorType?: Partial<SensorType>) {
    Object.assign(this, sensorType);
  }

  public toJSON(): Object {
    return {
      id: this.id,
      configurations: this.configurations,
      registrationParams: this.registrationParams,
      measurementTypes: this.measurementTypes,
      name: this.name,
      key: this.key,
    };
  }
}

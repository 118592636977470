import {Component, Input} from '@angular/core';

import {PhoenixFormTextarea} from './classes/phoenix-form-textarea';

@Component({
  selector: 'phoenix-form-textarea',
  templateUrl: './phoenix-form-textarea.component.html',
  styleUrls: ['./phoenix-form-textarea.component.scss'],
})
export class PhoenixFormTextareaComponent {
  @Input() public element: PhoenixFormTextarea;
}

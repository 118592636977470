import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from 'ngx-flexible-layout';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatListModule } from '@angular/material/list';
import { TranslateModule } from '@ngx-translate/core';

import { PhoenixDialogNewModule } from '../../../components/phoenix-dialog-new/phoenix-dialog-new.module';

import { PhoenixAsseBranchSelectorDialogComponent } from './phoenix-asset-branchselector-dialog.component';
import { PhoenixAssetBranchselectorDialogService } from './phoenix-asset-branchselector-dialog.service';

@NgModule({
    imports: [
        CommonModule,
        TranslateModule,
        FlexLayoutModule,
        MatListModule,
        MatDialogModule,
        MatButtonModule,
        PhoenixDialogNewModule,
    ],
    declarations: [
        PhoenixAsseBranchSelectorDialogComponent,
    ],
    providers: [
        PhoenixAssetBranchselectorDialogService,
    ]
})
export class PhoenixAssetBranchSelectorDialogModule {
}

import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {FuseSharedModule} from '@fuse/shared.module';
import {TranslateModule} from '@ngx-translate/core';
import {PhoenixIcon} from '@phoenix/enums/phoenix-icon.enum';
import {takeUntil} from 'rxjs/operators';

import {PhoenixCommunicationSubject} from '../../../@phoenix/services/phoenix-communication-service/phoenix-communication-subject.enum';
import {PhoenixCommunicationService} from '../../../@phoenix/services/phoenix-communication-service/phoenix-communication.service';
import {CanLoadGuard} from '../../guards/can-load.guard';
import {navbarGroups} from '../../layout/components/navbar/navbar-groups/navbar-groups.module';
import {NavigationItem} from '../../navigation/classes/navigation-item';
import {NavigationService} from '../../navigation/navigation.service';
import {MainModule} from '../classes/main-module';


const routes: Routes = [
  {
    path: 'help',
    loadChildren: () => import('./help-content/help-content.module').then(m => m.HelpContentModule),
    canLoad: [CanLoadGuard],
  },
];

@NgModule({
  imports: [
    RouterModule.forChild(routes),
    TranslateModule,
    FuseSharedModule,
  ],
  declarations: [],
})
export class HelpModule extends MainModule {
  private translationKey: string = 'NAV.HELP';
  private navItem: NavigationItem = new NavigationItem(
    'help',
    0,
    false,
    this.translationKey,
    '/help',
    PhoenixIcon.HELP,
  );

  public constructor(
    private navigationService: NavigationService,
    private communicationService: PhoenixCommunicationService,
  ) {
    super();
    this.communicationService.getObservable(PhoenixCommunicationSubject.AssetBranchChanged)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(() => this.navigationService.refreshNavigation(this.navItem, navbarGroups.information.id));
  }
}

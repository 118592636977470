<phoenix-dialog-new [buttons]="data.buttons" [subtitle]="data.subtitle" [title]="data.title">
  <phoenix-form [formGroup]="data.formGroup">
    <div fxLayout="column" fxLayoutGap="10px">
      <div fxLayout="row">
        <div fxFlex="1 1 auto"></div>
        <div fxFlex="0 1 auto">
          <button (click)="removeElement(-1)" [disabled]="data.extendedInformationForms.controls.length === 0"
            mat-mini-fab>
            <mat-icon class="notranslate">remove</mat-icon>
          </button>
        </div>
        <div fxFlex="1 1 auto"></div>
        <div fxFlex="0 1 auto">
          <button (click)="addElement()" mat-mini-fab>
            <mat-icon class="notranslate">add</mat-icon>
          </button>
        </div>
        <div fxFlex="1 1 auto"></div>
      </div>
      <div formArrayName="extendedInformations">
        <div *ngFor="let element of data.extendedInformationForms.controls; let i=index" [formGroupName]="i"
          class="phoenixDialogFormControlWrapper" fxLayout="row" fxLayoutGap="10px">
          <mat-form-field>
            <mat-select [formControlName]="'type'"
              [placeholder]="'MEASUREMENTTYPE.EXTENDEDINFORMATIONS.TYPE' | translate">
              <mat-option *ngFor="let type of types" [value]="type.value">{{type.label}}</mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field>
            <input [formControlName]="'name'" [maxlength]="64"
              [placeholder]="'MEASUREMENTTYPE.EXTENDEDINFORMATIONS.LABEL' | translate" matInput type="text">
            <mat-hint align="end">
              {{element.get('name').value?.length || 0}}/64
            </mat-hint>
            <mat-error *ngIf="element.invalid">
              <span data-translate="DIALOG.FORM.INVALID" translate></span>
            </mat-error>
          </mat-form-field>
          <mat-form-field>
            <input [formControlName]="'value'"
              [placeholder]="'MEASUREMENTTYPE.EXTENDEDINFORMATIONS.' + element.get('type').value | translate" matInput
              type="text">
            <mat-error *ngIf="element.invalid">
              <span data-translate="DIALOG.FORM.INVALID" translate></span>
            </mat-error>
          </mat-form-field>
          <div *ngIf="element" class="mt-12 mb-12">
            <button (click)="removeElement(i)" mat-icon-button>
              <mat-icon class="notranslate">delete</mat-icon>
            </button>
          </div>
        </div>
      </div>
    </div>
  </phoenix-form>
</phoenix-dialog-new>
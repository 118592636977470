import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from 'ngx-flexible-layout';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { GalleryModule } from '@ks89/angular-modal-gallery';
import { TranslateModule } from '@ngx-translate/core';

import { PhoenixGalleryComponent } from './phoenix-gallery.component';

@NgModule({
    imports: [
        CommonModule,
        TranslateModule,
        MatButtonModule,
        MatDialogModule,
        MatGridListModule,
        MatDividerModule,
        MatProgressSpinnerModule,
        FlexLayoutModule,
        //ModalGalleryModule.forRoot(),
        GalleryModule,
    ],
    declarations: [
        PhoenixGalleryComponent,
    ]
})
export class PhoenixGalleryModule {
}

import {SearchCriteriaPageableDto} from '../../shared/classes/search-criteria-pageable-dto';

import {AlarmStatusDto} from './alarm-status-dto.enum';

export class AlarmSearchCriteriaDto extends SearchCriteriaPageableDto {
  public alarmId?: string = undefined;
  public assetIds?: string[] = undefined;
  public dateFrom?: Date = undefined;
  public dateTo?: Date = undefined;
  public statuses?: AlarmStatusDto[] = undefined;
  public userIds?: number[] = undefined;

  public constructor(alarmSearchCriteriaDto?: Partial<AlarmSearchCriteriaDto>) {
    super(alarmSearchCriteriaDto);
    Object.assign(this, alarmSearchCriteriaDto);
  }
}

<div [formGroup]="form" class="mat-caption mt-12 mb-4 text-center"
  [ngClass]="{'hidden': phoenixFormControl.options['hidden']}">
  <ng-container *ngIf="!phoenixFormControl.options['callback']">
    <button (click)="buttonCallback('submit')" [color]="'primary'"
      [disabled]="phoenixFormControl.disabled || phoenixFormControl.options['disabled']" mat-mini-fab type="submit">
      <mat-icon class="notranslate" [ngStyle]="{'color': defaultIconColor}">{{ phoenixFormControl.value }}
      </mat-icon>
    </button>
  </ng-container>
  <ng-container *ngIf="phoenixFormControl.options['callback']">
    <button (click)="callbackFromOptions()"
      [color]="this.phoenixFormControl.options['color'] ? this.phoenixFormControl.options['color'] : 'primary'"
      [disabled]="phoenixFormControl.disabled || phoenixFormControl.options['disabled']" mat-mini-fab type="button">
      <mat-icon class="notranslate" [inline]="true" [ngStyle]="getIconStyle()">{{ phoenixFormControl.value }}
      </mat-icon>
    </button>
  </ng-container>
</div>
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SharedModule } from '../shared/shared.module';
import { ReportApiService } from './services/report-api.service';

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
  ],

  providers: [
    ReportApiService,
  ],
})
export class ReportApiModule {
}

import { UserGroupDto } from '@phoenix/gapicon/group/dto/user-group-dto';
import { TaskConfigRepeatDto } from './task-config-repeat-dto';

export class TaskConfigDto {
  public createdDate: Date;
  public duration: number;  // in seconds
  public id: string; // is set in Backend
  public repeat?: TaskConfigRepeatDto;
  public startDate: Date;
  public timezone: string; // - format: e.g. 'Europe/Berlin'
  public assignedUserGroupIds: string[] = [];

  public constructor(partial?: Partial<TaskConfigDto>) {
    Object.assign(this, partial);
  }
}

export class MeasurementValueDto {
  public key: string = undefined;
  public unit: string = undefined;
  public value: string = undefined;
  public descriptions: string[] = [];

  public constructor(measurementValueDto?: Partial<MeasurementValueDto>) {
    Object.assign(this, measurementValueDto);
  }
}

import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import * as _ from 'lodash-es';

import { AssetDto } from '../../gapicon/asset/dto/asset-dto';
import { AssetApiService } from '../../gapicon/asset/services/asset-api.service';
import { PhoenixCommunicationSubject } from '../phoenix-communication-service/phoenix-communication-subject.enum';
import { PhoenixCommunicationService } from '../phoenix-communication-service/phoenix-communication.service';

@Injectable({
  providedIn: 'root',
})
export class PhoenixNavigationService {

  public constructor(
    private router: Router,
    private assetApi: AssetApiService,
    private communicationService: PhoenixCommunicationService,
  ) {
  }

  public async navigateToAsset(assetDto: AssetDto): Promise<void> {
    await this.navigate(assetDto);
    this.communicationService.emit(PhoenixCommunicationSubject.MeasurementPointSelected, assetDto.id);
  }

  public async navigateToAssetFromTask(assetDto: AssetDto, startTask: Date, endTask: Date): Promise<void> {
    await this.navigate(assetDto);
    this.communicationService.emit(PhoenixCommunicationSubject.MeasurementPointSelectedWithTaskDuration, { mpId: assetDto.id, start: startTask, end: endTask });
  }

  private async navigate(assetDto: AssetDto): Promise<void> {
    const hierarchyAssets: AssetDto[] = await this.assetApi.getHierarchyForAsset(assetDto).toPromise();
    hierarchyAssets.pop();
    await this.router.navigate([_.join(_.concat([`/checkpoints`], _.map(hierarchyAssets, (asset: AssetDto) => asset.id)), '/')]);
  }
}

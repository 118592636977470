import { Injectable } from '@angular/core';
import { plainToClass } from 'class-transformer';
import * as _ from 'lodash-es';

import { Tenant } from '../api/tenant';
import { TenantDto } from '../dto/tenant-dto';

@Injectable({
  providedIn: 'root',
})
export class TenantMapperService {

  public constructor() {
  }

  public tenantDtoToTenant(tenantDto: TenantDto): Tenant {
    return plainToClass(Tenant, tenantDto);
  }

  public tenantToTenantDto(tenant: Tenant): TenantDto {
    return plainToClass(TenantDto, tenant);
  }

  public tenantArrayToTenantDtoArray(tenants: Tenant[]): TenantDto[] {
    return _.map(tenants, (tenant: Tenant) => this.tenantToTenantDto(tenant));
  }

  public tenantDtoArrayToTenantArray(tenantDtos: TenantDto[]): Tenant[] {
    return _.map(tenantDtos, (tenantDto: TenantDto) => this.tenantDtoToTenant(tenantDto));
  }
}

import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {map, reduce} from 'rxjs/operators';

import {SharedService} from '../../shared/services/shared.service';
import {NotificationConfig} from '../api/notification-config';
import {NotificationConfigDto} from '../dto/notification-config-dto';
import {NotificationConfigSearchCriteriaDto} from '../dto/notification-config-search-criteria-dto';
import {NotificationConfigMapperService} from '../mapper/notification-config-mapper.service';

@Injectable({
  providedIn: 'root',
})
export class NotificationConfigApiService {
  private _notificationConfigs: string = 'notificationConfigs';
  private _newVersion: string = 'newVersion';
  private _notificationConfig: string = 'notificationConfig';

  /**
   *
   * @param http
   * @param sharedService
   * @param notificationConfigMapperService
   */
  public constructor(
    private http: HttpClient,
    private sharedService: SharedService,
    private notificationConfigMapperService: NotificationConfigMapperService,
  ) {
  }

  /**
   * Gets all notificationConfigs matching the search criteria
   * @param notificationConfigSearchCriteriaDto
   * @param withFurtherPages
   * @returns
   */
  public getNotificationConfigs(
    notificationConfigSearchCriteriaDto: NotificationConfigSearchCriteriaDto = new NotificationConfigSearchCriteriaDto(),
    withFurtherPages: boolean = true,
  ): Observable<NotificationConfigDto[]> {
    return this.sharedService.httpGetWithPagination<NotificationConfig>(
      this.sharedService.buildApiUrl(this._notificationConfigs),
      this.notificationConfigMapperService.notificationConfigSearchCriteriaDtoToNotificationConfigSearchCriteria(notificationConfigSearchCriteriaDto),
      withFurtherPages,
    ).pipe(
      map((notificationConfigs: NotificationConfig[]) => this.notificationConfigMapperService.notificationConfigArrayToNotificationConfigDtoArray(notificationConfigs)),
      reduce((all: NotificationConfigDto[], current: NotificationConfigDto[]) => all.concat(current)),
    );
  }

  /**
   * Create an notification config by given NotificationConfigDto.
   * @param notificationConfigDto
   * @returns
   */
  public createNotificationConfig(notificationConfigDto: NotificationConfigDto): Observable<NotificationConfigDto> {
    return this.http.post<NotificationConfig>(
      this.sharedService.buildApiUrl(this._notificationConfigs, this._notificationConfig),
      this.notificationConfigMapperService.notificationConfigDtoToNotificationConfig(notificationConfigDto),
    ).pipe(
      map((notificationConfig: NotificationConfig) => this.notificationConfigMapperService.notificationConfigToNotificationConfigDto(notificationConfig)),
    );
  }

  /**
   * Update an notification config by given NotificationConfigDto.
   * @param notificationConfigDto
   * @returns
   */
  public updateNotificationConfig(notificationConfigDto: NotificationConfigDto): Observable<NotificationConfigDto> {
    return this.http.put<NotificationConfig>(
      this.sharedService.buildApiUrl(this._notificationConfigs, this._notificationConfig, notificationConfigDto.id),
      this.notificationConfigMapperService.notificationConfigDtoToNotificationConfig(notificationConfigDto),
    ).pipe(
      map((notificationConfig: NotificationConfig) => this.notificationConfigMapperService.notificationConfigToNotificationConfigDto(notificationConfig)),
    );
  }


  /**
   * Update an notification config by given NotificationConfigDto.
   * @param notificationConfigDto
   * @returns
   */
  public updateNotificationConfigKeepKeepVersionChanges(notificationConfigDto: NotificationConfigDto): Observable<NotificationConfigDto> {
    return this.http.put<NotificationConfig>(
        this.sharedService.buildApiUrl(this._notificationConfigs, this._newVersion, this._notificationConfig, notificationConfigDto.id),
        this.notificationConfigMapperService.notificationConfigDtoToNotificationConfig(notificationConfigDto),
    ).pipe(
        map((notificationConfig: NotificationConfig) => this.notificationConfigMapperService.notificationConfigToNotificationConfigDto(notificationConfig)),
    );
  }


  //TODO not used functionality probably can be deleted
  /**
   * Delete an notification config by given NotificationConfigDto.
   * @returns
   * @param notificationConfigDto
   */
  public deleteNotificationConfig(notificationConfigDto: NotificationConfigDto): Observable<void> {
    return this.deleteNotificationConfigById(this.notificationConfigMapperService.notificationConfigDtoToNotificationConfig(notificationConfigDto).id.toString());
  }

  /**
   * Delete an notification config by given NotificationConfig ID.
   * @param id
   * @returns
   */
  public deleteNotificationConfigById(id: string): Observable<void> {
    return this.http.delete<void>(
      this.sharedService.buildApiUrl(this._notificationConfigs, this._notificationConfig, id.toString()),
    );
  }

}

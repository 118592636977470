<button mat-button [matMenuTriggerFor]="menuItem"
        class="toolbar-menu-button">
  <div fxLayout="row" fxLayoutAlign="center center">
    <img [ngClass]="menu.imageClass" src="{{menu.imageSrc}}">
    <span [ngClass]="menu.spanClass" fxHide fxShow.gt-sm>{{menu.title}}</span>
    <mat-icon [ngClass]="menu.iconClass" fxHide.xs>{{menu.iconName}}</mat-icon>
  </div>
</button>
<mat-menu #menuItem="matMenu" [overlapTrigger]="false">
  <phoenix-toolbar-menu-item *ngFor="let entry of entries" [entry]="entry"
                             (clicked)="entry.action(entry)"></phoenix-toolbar-menu-item>
</mat-menu>

export class RegistrationRequestDto {
  public email: string = undefined;
  /**
   * Key -> Role,
   * Value -> Tenants
   */
   public roles: { [key: string]: string[] } = undefined;

  public constructor(registrationRequestDto?: Partial<RegistrationRequestDto>) {
    Object.assign(this, registrationRequestDto);
  }
}

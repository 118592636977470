import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';

import {SharedService} from '../../shared/services/shared.service';

import {EmailDto} from '../dto/email-dto';
import {EmailMapperService} from '../mapper/email-mapper.service';

@Injectable()
export class EmailApiService {
  /**
   * @param http
   * @param shared
   * @param emailMapperService
   */
  public constructor(
    private http: HttpClient,
    private shared: SharedService,
    private emailMapperService: EmailMapperService,
  ) {
  }

  /**
   * Sends an email.
   * @param emailDto
   * @returns
   */
  public sendEmail(emailDto: EmailDto): Observable<void> {
    return this.http.post<void>(
      this.shared.buildApiUrl('emails'),
      this.emailMapperService.emailDtoToEmail(emailDto),
    );
  }
}

export class UserInfoDto {
  public id: string = undefined;
  public email: string = undefined;
  public tenant: string = undefined;
  public firstName: string = undefined;
  public lastName: string = undefined;

  public constructor(userInfoDto?: Partial<UserInfoDto>) {
    Object.assign(this, userInfoDto);
  }
}

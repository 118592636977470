import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';

import {SharedModule} from '../shared/shared.module';

import {FileMapperService} from './mapper/file-mapper.service';
import {FileApiService} from './services/file-api.service';


@NgModule({
  imports: [
    CommonModule,
    SharedModule,
  ],

  providers: [
    FileApiService,
    FileMapperService,
  ],
})
export class FileApiModule {
}

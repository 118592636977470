import {SearchCriteriaPageable} from '../../shared/classes/search-criteria-pageable';

export class MeasurementTypeSearchCriteria extends SearchCriteriaPageable {

  private id: string = undefined;
  private key: string = undefined;
  private name: string = undefined;
  private partialName: string = undefined;
  private tags: string[] = undefined;

  public constructor(measurementTypeSearchCriteria?: Partial<MeasurementTypeSearchCriteria>) {
    super(measurementTypeSearchCriteria);
    Object.assign(this, measurementTypeSearchCriteria);
  }

  public toJSON(): Object {
    return {
      id: this.id,
      key: this.key,
      name: this.name,
      partialName: this.partialName,
      tags: this.tags,
      page: this.page,
      size: this.size,
    };
  }

}

import {SearchCriteriaPageableDto} from '../../shared/classes/search-criteria-pageable-dto';

export class TenantSearchCriteriaDto extends SearchCriteriaPageableDto {
  public name: string = undefined;
  public description: string = undefined;

  public constructor(tenantSearchCriteriaDto?: Partial<TenantSearchCriteriaDto>) {
    super(tenantSearchCriteriaDto);
    Object.assign(this, tenantSearchCriteriaDto);
  }
}

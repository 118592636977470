import { SearchCriteriaPageableDto } from '../../shared/classes/search-criteria-pageable-dto';

export class AssetSearchCriteriaDto extends SearchCriteriaPageableDto {
  public name: string = undefined;
  public partialName: string = undefined;
  public tags: string[] = undefined;
  public sensorId: string = undefined;
  public measurementType: string = undefined;
  public parentId: string = undefined;
  public code: string = undefined;
  public thresholdId: string = undefined;
  public dateFrom?: Date = undefined;
  public dateTo?: Date = undefined;



  public constructor(assetSearchCriteria?: Partial<AssetSearchCriteriaDto>) {
    super(assetSearchCriteria);
    Object.assign(this, assetSearchCriteria);
  }
}

import { Platform } from '@angular/cdk/platform';
import { DOCUMENT } from '@angular/common';
import { Component, HostListener, Inject, OnDestroy, OnInit } from '@angular/core';
import { FuseNavigationService } from '@fuse/components/navigation/navigation.service';
import { FuseSidebarService } from '@fuse/components/sidebar/sidebar.service';
import { FuseConfigService } from '@fuse/services/config.service';
import { FuseSplashScreenService } from '@fuse/services/splash-screen.service';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { TranslateService } from '@ngx-translate/core';
import * as _ from 'lodash-es';
import moment from 'moment';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import german from '../../i18n/de.json';
import english from '../../i18n/en.json';
import french from '../../i18n/fr.json';
import italian from '../../i18n/it.json';
import { PhoenixUserConfigType } from '../@phoenix/enums/phoenix-user-config-type.enum';
import { AuthApiService } from '../@phoenix/gapicon/auth/services/auth-api.service';
import { UserConfigDto } from '../@phoenix/gapicon/user/dto/user-config-dto';
import { PhoenixCommunicationSubject } from '../@phoenix/services/phoenix-communication-service/phoenix-communication-subject.enum';
import { PhoenixCommunicationService } from '../@phoenix/services/phoenix-communication-service/phoenix-communication.service';
import { PermissionService } from './guards/permission.service';
import { navigation } from './navigation/navigation';
import { OAuthService } from 'angular-oauth2-oidc';
import { authCodeFlowConfig } from './auth.config';




@Component({
  selector: 'app',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
  public fuseConfig: any;
  public navigation: any;

  // Private
  private _unsubscribeAll: Subject<void>;

  /**
   * Constructor
   *
   * @param {DOCUMENT} document
   * @param {FuseConfigService} _fuseConfigService
   * @param {FuseNavigationService} _fuseNavigationService
   * @param {FuseSidebarService} _fuseSidebarService
   * @param {FuseSplashScreenService} _fuseSplashScreenService
   * @param {FuseTranslationLoaderService} _fuseTranslationLoaderService
   * @param {Platform} _platform
   * @param {TranslateService} _translateService
   * @param _permissionService
   * @param _communicationService
   */
  public constructor(
    @Inject(DOCUMENT) private document: any,
    private _fuseConfigService: FuseConfigService,
    private _fuseNavigationService: FuseNavigationService,
    private _fuseSidebarService: FuseSidebarService,
    private _fuseSplashScreenService: FuseSplashScreenService,
    private _fuseTranslationLoaderService: FuseTranslationLoaderService,
    private _translateService: TranslateService,
    private _platform: Platform,
    private _permissionService: PermissionService,
    private _communicationService: PhoenixCommunicationService,
    private _authApiService: AuthApiService,
    private oauthService: OAuthService
  ) {

    this.oauthService.configure(authCodeFlowConfig);
    //this.oauthService.loadDiscoveryDocumentAndLogin();

    this.oauthService.setupAutomaticSilentRefresh();

    // Automatically load user profile
    this.oauthService.events
      .pipe(filter((e) => {
        //console.log("e: ", e);
        //return e.type === 'token_received';
        return e.type === 'token_received';
      }))
      .subscribe(async (_) => {
        if (!this._permissionService.user) {
          console.log("###_:", _);
          console.log('user profile:' + JSON.stringify(await this.oauthService.loadUserProfile(), null, 2));
          this.oauthService.loadUserProfile()
          console.log("### getIdentityClaims():", this.oauthService.getIdentityClaims());
          this._permissionService.postlogin();
        }
      }
      );



    this._fuseConfigService.setConfig({
      colorTheme: 'theme-gc',
    });

    // Get default navigation
    this.navigation = navigation;

    // Register the navigation to the service
    this._fuseNavigationService.register('main', this.navigation);

    // Set the main navigation as our current navigation
    this._fuseNavigationService.setCurrentNavigation('main');


    // Add languages
    this._translateService.addLangs([
      'en',
      'de',
      'it',
      'fr',
    ]);

    // Set the default language
    this._translateService.setDefaultLang('en');

    // Set the navigation translations
    this._fuseTranslationLoaderService.loadTranslations(
      english,
      german,
      italian,
      french,
    );

    // Use a language
    this._translateService.use(localStorage.getItem('selectedLanguage') || this._translateService.getBrowserLang());

    this._communicationService.getObservable(PhoenixCommunicationSubject.UserLogin).subscribe(() => {
      const language: string = this.getUserLanguage();
      if (language) {
        this._translateService.use(language);
        localStorage.setItem('selectedLanguage', language);
      }
    });


    moment.locale('de');

    // Workaround because of error in moment type definition
    (moment as { defaultFormat: string }).defaultFormat = 'L LT';

    /**
     * ------------------------------------------------------------------
     * ngxTranslate Fix Start
     * ------------------------------------------------------------------
     * If you are using a language other than the default one, i.e. Turkish in this case,
     * you may encounter an issue where some of the components are not actually being
     * translated when your app first initialized.
     *
     * This is related to ngxTranslate module and below there is a temporary fix while we
     * are moving the multi language implementation over to the Angular's core language
     * service.
     **/

    // Set the default language to 'en' and then back to 'tr'.
    // '.use' cannot be used here as ngxTranslate won't switch to a language that's already
    // been selected and there is no way to force it, so we overcome the issue by switching
    // the default language back and forth.
    /**
     setTimeout(() => {
            this._translateService.setDefaultLang('en');
            this._translateService.setDefaultLang('tr');
         });
     */

    /**
     * ------------------------------------------------------------------
     * ngxTranslate Fix End
     * ------------------------------------------------------------------
     */

    // Add is-mobile class to the body if the platform is mobile
    if (this._platform.ANDROID || this._platform.IOS) {
      this.document.body.classList.add('is-mobile');
    }

    // Set the private defaults
    this._unsubscribeAll = new Subject();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Lifecycle hooks
  // -----------------------------------------------------------------------------------------------------

  /**
   * On destroy
   */
  public ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  /**
   * On init
   */
  public async ngOnInit(): Promise<void> {

    // persist changes
    // Subscribe to config changes
    this._fuseConfigService.config
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((config) => {

        this.fuseConfig = config;

        // Boxed
        if (this.fuseConfig.layout.width === 'boxed') {
          this.document.body.classList.add('boxed');
        } else {
          this.document.body.classList.remove('boxed');
        }

        // Color theme - Use normal for loop for IE11 compatibility
        for (let i = 0; i < this.document.body.classList.length; i++) {
          const className = this.document.body.classList[i];

          if (className.startsWith('theme-')) {
            this.document.body.classList.remove(className);
          }
        }

        this.document.body.classList.add(this.fuseConfig.colorTheme);
        // persisting user after refreshing the page

      });


    await this.oauthService.loadDiscoveryDocumentAndLogin({ customHashFragment: window.location.search });
    this._permissionService.autoLogin();

  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Toggle sidebar open
   *
   * @param key
   */
  public toggleSidebarOpen(key): void {
    this._fuseSidebarService.getSidebar(key).toggleOpen();
  }

  @HostListener('document:click', ['$event.target'])
  @HostListener('document:keydown', ['$event.target'])
  @HostListener('document:mousemove', ['$event.target'])
  /*
  public refreshLoginState(target): void {
    this._permissionService.startNewAutoLoginCountDown();
  }
  */

  private getUserLanguage(): string {
    let language: string;
    if (this._permissionService.user) {
      _.forEach(this._permissionService.user.configurations, (conf: UserConfigDto) => {
        if (conf.type === PhoenixUserConfigType.Locale) {
          language = conf.value;
        }
      });
    }
    return language;
  }


  get userName(): string {
    const claims = this.oauthService.getIdentityClaims();
    if (!claims) return null;
    return claims['given_name'];
  }

  get idToken(): string {
    return this.oauthService.getIdToken();
  }

  get accessToken(): string {
    return this.oauthService.getAccessToken();
  }

  refresh() {
    this.oauthService.refreshToken();
  }


}

import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { FuseSharedModule } from '@fuse/shared.module';
import { TranslateModule } from '@ngx-translate/core';
import { PhoenixIcon } from '@phoenix/enums/phoenix-icon.enum';
import { PhoenixCommunicationSubject } from '@phoenix/services/phoenix-communication-service/phoenix-communication-subject.enum';
import { PhoenixCommunicationService } from '@phoenix/services/phoenix-communication-service/phoenix-communication.service';
import { takeUntil } from 'rxjs/operators';

import { CanLoadGuard } from '../../guards/can-load.guard';
import { Permission, permissionIds } from '../../guards/permission';
import { navbarGroups } from '../../layout/components/navbar/navbar-groups/navbar-groups.module';
import { NavigationItem } from '../../navigation/classes/navigation-item';
import { NavigationService } from '../../navigation/navigation.service';
import { MainModule } from '../classes/main-module';


const permission: Permission = new Permission(permissionIds.CHECKPOINTS.read);
const routes: Routes = [
  {
    path: 'checkpoints',
    loadChildren: () => import('./checkpoints-content/checkpoints-content.module').then(m => m.CheckpointsContentModule),
    canLoad: [CanLoadGuard],
    data: { permission: permission },
  },
];

@NgModule({
  imports: [
    RouterModule.forChild(routes),
    TranslateModule,
    FuseSharedModule,
  ],
  declarations: [],
})
export class CheckpointsModule extends MainModule {
  private translationKey: string = 'NAV.CHECKPOINTS';
  private navItem: NavigationItem = new NavigationItem(
    'checkpoints',
    1,
    false,
    this.translationKey,
    '/checkpoints',
    PhoenixIcon.DEVICE_HUB,
  );

  public constructor(
    private navigationService: NavigationService,
    private communicationService: PhoenixCommunicationService,
  ) {
    super();
    this.communicationService.getObservable(PhoenixCommunicationSubject.AssetBranchChanged)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(() => {
        this.navigationService.refreshNavigation(this.navItem, navbarGroups.monitoring.id, permission);
      });
  }
}

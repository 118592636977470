<mat-toolbar class="p-0 mat-elevation-z1">
  <div fxFill fxFlex fxLayout="row" fxLayoutAlign="start center">
    <div fxFlex="1 0 auto" fxLayout="row" fxLayoutAlign="start center">
      <button (click)="toggleSidebarOpened('navbar')" class="navbar-toggle-button" fxHide.gt-md mat-icon-button>
        <mat-icon class="notranslate secondary-text">menu</mat-icon>
      </button>
    </div>
    <div class="h-100-p" fxFlex="0 1 auto" fxLayout="row" fxLayoutAlign="start center">

      <div *ngIf="currentTenant" class="mat-body-1">{{ 'TOOLBAR.CURRENTTENANT' | translate }}<b> {{ currentTenant }}</b>
      </div>&nbsp;
      <div *ngIf="currentAssetBranch" class="mat-body-1"> {{ 'TOOLBAR.ASSETBRANCH' | translate }}<b> {{
          currentAssetBranch }}</b>
      </div>
      <div *ngIf="canChangeAssetBranch" class="ml-24"> 
        <button (click)="changeAssetBranch()" color="accent" mat-raised-button>{{ 'TOOLBAR.CHANGEASSETBRANCH' |
          translate }}</button>
      </div>
      <div *ngIf="canChangeTenant" class="change-tenant-button-wrapper">
        <button (click)="changeTenant()" color="accent" mat-raised-button>{{ 'TOOLBAR.CHANGETENANT' | translate
          }}</button>
      </div>
      <div class="toolbar-separator"></div>
      <phoenix-toolbar-menu [entries]="userMenuEntries" [menu]="userMenu"></phoenix-toolbar-menu>
      <div class="toolbar-separator"></div>
      <phoenix-toolbar-menu [entries]="languageMenuEntries" [menu]="languageMenu"></phoenix-toolbar-menu>
    </div>
  </div>
</mat-toolbar>
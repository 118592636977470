import {Component, Input} from '@angular/core';

import {PhoenixFormText} from './classes/phoenix-form-text';

@Component({
  selector: 'phoenix-form-text',
  templateUrl: './phoenix-form-text.component.html',
  styleUrls: ['./phoenix-form-text.component.scss'],
})
export class PhoenixFormTextComponent {
  @Input() public element: PhoenixFormText;
}

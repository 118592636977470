import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from 'ngx-flexible-layout';
import { ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { TranslateModule } from '@ngx-translate/core';

import { PhoenixDynamicFormControlsModule } from './form-controls/phoenix-dynamic-form-controls.module';
import { PhoenixDialogComponent } from './phoenix-dialog/phoenix-dialog.component';

@NgModule({
    imports: [
        CommonModule,
        MatButtonModule,
        TranslateModule,
        FlexLayoutModule,
        MatDialogModule,
        MatGridListModule,
        MatDividerModule,
        MatCheckboxModule,
        PhoenixDynamicFormControlsModule,
        ReactiveFormsModule,
        MatProgressSpinnerModule,
    ],
    declarations: [
        PhoenixDialogComponent,
    ]
})
export class PhoenixDialogModule {
}

import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';

import {SharedModule} from '../shared/shared.module';

import {AccountMapperService} from './mapper/account-mapper.service';
import {AccountApiService} from './services/account-api.service';

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
  ],
  providers: [
    AccountApiService,
    AccountMapperService,
  ],
})
export class AccountApiModule {
}

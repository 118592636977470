import {JsonExportable} from '../../shared/classes/json-exportable';

export class SensorConfigValue implements JsonExportable {
  public defaultValue: string = undefined;
  public key: string = undefined;
  public name: string = undefined;
  public type: string = undefined;
  public value: string = undefined;
  public unit: string = undefined;

  public constructor(sensor?: Partial<SensorConfigValue>) {
    Object.assign(this, sensor);
  }

  public toJSON(): Object {
    return {
      defaultValue: this.defaultValue,
      key: this.key,
      name: this.name,
      type: this.type,
      value: this.value,
      unit: this.unit,
    };
  }
}

import { Injectable } from "@angular/core";
import { UntypedFormGroup } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { PhoenixDialogResult } from '@phoenix/components/phoenix-dialog/classes/phoenix-dialog-result';
import { PhoenixDynamicFormControl } from '@phoenix/components/phoenix-dialog/classes/phoenix-dynamic-form-control';
import { PhoenixDynamicFormControlType } from '@phoenix/components/phoenix-dialog/enums/phoenix-dynamic-form-control-type.enum';
import { PhoenixDialogData } from '@phoenix/components/phoenix-dialog/interfaces/phoenix-dialog-data';
import { PhoenixDialogComponent } from '@phoenix/components/phoenix-dialog/phoenix-dialog/phoenix-dialog.component';
import { PhoenixSnackbarService } from '@phoenix/components/phoenix-snackbar/phoenix-snackbar.service';
import { PhoenixIcon } from '@phoenix/enums/phoenix-icon.enum';
import { PhoenixStatusColor } from '@phoenix/enums/phoenix-status-color.enum';
import { TimeWindowConfigDayDto } from '@phoenix/gapicon/asset/dto/time-window-config-day-dto';
import { TimeWindowConfigIntervallDto } from "@phoenix/gapicon/asset/dto/time-window-config-intervall-dto";
import _ from 'lodash-es';
import { PhoenixCommunicationService } from '../phoenix-communication-service/phoenix-communication.service';
import { PhoenixWizardHelperService } from './phoenix-wizard-helper.service';
import moment, { Moment } from 'moment';

@Injectable({
  providedIn: 'root'
})
export class PhoenixTimeWindowConfigDayWizardService {
  private dialogTimeWindowConfigDay: TimeWindowConfigDayDto = undefined;
  private mpTimeWindowConfigDay: TimeWindowConfigDayDto = undefined;
  private dayStatusIconFormControl: PhoenixDynamicFormControl<string> = undefined;
  private dialogRef: MatDialogRef<PhoenixDialogComponent, PhoenixDialogData>;
  private dialogFormControls: PhoenixDynamicFormControl<string | boolean | number | TimeWindowConfigIntervallDto>[] = [];

  public constructor(
    private dialog: MatDialog,
    private communicationService: PhoenixCommunicationService,
    private translateService: TranslateService,
    private phoenixWizardHelper: PhoenixWizardHelperService,
    private phoenixSnackbarService: PhoenixSnackbarService,
  ) { }

  public openEditTimeWindowConfigDayWizard(
    timeWindowConfigDay: TimeWindowConfigDayDto,
    dayIndex: number,
    afterTimeWindowConfigDayEditedCallBack?: (timeWindowConfigDay: TimeWindowConfigDayDto) => void
  ): void {
    this.mpTimeWindowConfigDay = timeWindowConfigDay;
    this.dialogTimeWindowConfigDay = _.cloneDeep(timeWindowConfigDay);
    this.dialogTimeWindowConfigDay = !this.dialogTimeWindowConfigDay ? new TimeWindowConfigDayDto() : this.dialogTimeWindowConfigDay;
    this.openTimeWindowConfigDayWizard(
      (dialogResult: PhoenixDialogResult): Promise<void> => this.onEditTimeWindowConfigDayCloseCallback(dialogResult, afterTimeWindowConfigDayEditedCallBack),
      dayIndex,
      'WIZARDS.SELECTOR.TITLE.TIMEWINDOWDAY',
    );
  }

  public openTimeWindowConfigDayWizard(
    onClose: Function,
    dayIndex: number,
    title: string
  ): void {
    this.dialog.open(PhoenixDialogComponent, {
      width: this.phoenixWizardHelper.DIALOGWIDTHBIG,
      autoFocus: false,
      data: <PhoenixDialogData>{
        headline: title,
        subHeadline: 'WIZARDS.SELECTOR.SUBTITLE.TIMEWINDOWDAY',
        onClose: onClose,
        buttons: {
          cancel: true,
          ok: true
        },
        formControls: [
          this.createTimeWindowConfigDayFormControl(dayIndex),
        ],
      },
    });
  }

  private async onEditTimeWindowConfigDayCloseCallback(dialogResult: PhoenixDialogResult, afterTimeWindowConfigDayEditedCallBack?: (timeWindowConfigDay: TimeWindowConfigDayDto) => void): Promise<void> {
    if (dialogResult.result === 'ok') {
      this.mpTimeWindowConfigDay = this.dialogTimeWindowConfigDay;
    }
    if (afterTimeWindowConfigDayEditedCallBack) {
      afterTimeWindowConfigDayEditedCallBack(this.mpTimeWindowConfigDay);
    }
    dialogResult.dialogRef.close();
  }

  private createTimeWindowConfigDayFormControl(dayIndex: number): PhoenixDynamicFormControl<string | boolean | number | TimeWindowConfigIntervallDto>[] {
    this.dialogFormControls = [];
    this.dialogFormControls.push(this.createTimeWindowConfigDayFirstRowFormControl(dayIndex))
    if (this.dialogTimeWindowConfigDay.intervalls) {
      for (let i = 0; i < this.dialogTimeWindowConfigDay.intervalls.length; i++) {
        this.dialogFormControls[i + 1] = this.createIntervalFormControl(i);
      }
    }
    this.dialogFormControls.push(this.createAddButonFormControl());
    this.dialogFormControls.push(this.createInvalidIntervalsHintFormControl());
    return this.dialogFormControls;
  }

  private createTimeWindowConfigDayFirstRowFormControl(dayIndex: number): PhoenixDynamicFormControl<string | boolean | number> {
    this.dayStatusIconFormControl = this.createDayStatusIconFormControl();
    this.setDayStatusIcon();
    return new PhoenixDynamicFormControl<string>(
      'timeWindowConfigDayFirstRowArray',
      PhoenixDynamicFormControlType.FormControlArray,
      undefined,
      false,
      {
        layoutAlign: 'start center',
        topMargin: false,
        formControls: [
          this.dayStatusIconFormControl,
          this.createDayLabelFormControl(dayIndex)
        ]
      }
    );
  }

  private createDayStatusIconFormControl(): PhoenixDynamicFormControl<string> {
    return new PhoenixDynamicFormControl<string>(
      'dayStatusIcon',
      PhoenixDynamicFormControlType.Icon,
      '',
      false,
      {
      },
      PhoenixIcon.SCHEDULE,
    );
  }

  private setDayStatusIcon(): void {
    if (this.dialogTimeWindowConfigDay) {
      if (!this.dialogTimeWindowConfigDay.enable) {
        this.dayStatusIconFormControl.options['color'] = PhoenixStatusColor.POSITIVE;
        this.dayStatusIconFormControl.value = PhoenixIcon.LENS;
      } else if (this.dialogTimeWindowConfigDay.intervalls && this.dialogTimeWindowConfigDay.intervalls.length >= 1 &&
        !(this.dialogTimeWindowConfigDay.intervalls[0].startTime == '0:00' && this.dialogTimeWindowConfigDay.intervalls[0].endTime == '0:00')) {
        this.dayStatusIconFormControl.options['color'] = PhoenixStatusColor.POSITIVE;
        this.dayStatusIconFormControl.value = PhoenixIcon.INCOMPLETE_CIRCLE;
      } else {
        this.dayStatusIconFormControl.options['color'] = PhoenixStatusColor.NEGATIVE;
        this.dayStatusIconFormControl.value = PhoenixIcon.INCOMPLETE_CIRCLE;
      }
    } else {
      this.dayStatusIconFormControl.options['color'] = PhoenixStatusColor.POSITIVE;
      this.dayStatusIconFormControl.value = PhoenixIcon.LENS;
    }

  }

  private createDayLabelFormControl(dayIndex: number): PhoenixDynamicFormControl<string> {
    return new PhoenixDynamicFormControl<string>(
      'dayLabel',
      PhoenixDynamicFormControlType.Text,
      '',
      false,
      { fontSize: 24 },
      this.translateService.instant('WIZARDS.TIMEWINDOW.WEEKDAYS.' + this.getWeekdayName(dayIndex).toUpperCase()),
      true
    );
  }

  private getWeekdayName(dayIndex: number): string {
    switch (dayIndex) {
      case 0:
        return 'monday';
      case 1:
        return 'tuesday';
      case 2:
        return 'wednesday';
      case 3:
        return 'thursday';
      case 4:
        return 'friday';
      case 5:
        return 'saturday';
      case 6:
        return 'sunday';
      default:
        return 'monday';
    }
  }

  private createIntervalFormControl(intervalIndex: number): PhoenixDynamicFormControl<TimeWindowConfigIntervallDto> {
    if (this.dialogTimeWindowConfigDay && this.dialogTimeWindowConfigDay.intervalls
      && intervalIndex >= 0 && intervalIndex < this.dialogTimeWindowConfigDay.intervalls.length) {

      return new PhoenixDynamicFormControl<TimeWindowConfigIntervallDto>(
        'timeWindowConfigDayInterval' + intervalIndex,
        PhoenixDynamicFormControlType.TimeWindowInterval,
        undefined,
        true,
        {
          deleteCallback: async (form: UntypedFormGroup): Promise<void> => this.deleteInterval(form, 'timeWindowConfigDayInterval' + intervalIndex),
          onChangeCallback: async (form: UntypedFormGroup): Promise<void> => this.intervalChanged(form)
        },
        this.dialogTimeWindowConfigDay.intervalls[intervalIndex],
        intervalIndex == 0,
      );
    }
  }

  private createAddButonFormControl(): PhoenixDynamicFormControl<string> {
    return new PhoenixDynamicFormControl<string>(
      'timeWindowConfigDayAddButton',
      PhoenixDynamicFormControlType.Button,
      undefined,
      true,
      {
        style: "mini-fab-icon",
        callback: async (form: UntypedFormGroup): Promise<void> => this.addInterval(form),
      },
      PhoenixIcon.ADD
    );
  }

  private createInvalidIntervalsHintFormControl(): PhoenixDynamicFormControl<string> {
    return new PhoenixDynamicFormControl<string>(
      'timeWindowConfigDayInvalidIntervalssHint',
      PhoenixDynamicFormControlType.Text,
      '',
      false,
      { fontSize: 15, classes: { hidden: true } },
      this.translateService.instant('WIZARDS.TIMEWINDOW.HINT.INVALIDINTERVALS'),
      true
    );
  }

  private async deleteInterval(form: UntypedFormGroup, key: string): Promise<void> {
    let intervalIndex = undefined;
    for (let i = 1; i < this.dialogFormControls.length; i++) {
      if (this.dialogFormControls[i].key == key) {
        intervalIndex = i - 1;
      }
    }
    this.dialogTimeWindowConfigDay.intervalls.splice(intervalIndex, 1);
    this.dialogFormControls.splice(intervalIndex + 1, 1);
    this.setDayStatusIcon();
    if (!this.isValid()) {
      form.disable();
      if (this.dialogFormControls && this.dialogFormControls.length > 1) {
        this.dialogFormControls[this.dialogFormControls.length - 1].options['classes'] = { hidden: false };
      }
    } else {
      form.enable();
      if (this.dialogFormControls && this.dialogFormControls.length > 1) {
        this.dialogFormControls[this.dialogFormControls.length - 1].options['classes'] = { hidden: true };
      }
    }
    form.updateValueAndValidity();
  }

  private async addInterval(form: UntypedFormGroup): Promise<void> {
    this.dialogTimeWindowConfigDay.intervalls.push(new TimeWindowConfigIntervallDto({ startTime: '0:00', endTime: '0:00' }));
    let intervalFormControl = this.createIntervalFormControl(this.dialogTimeWindowConfigDay.intervalls.length - 1);
    this.dialogFormControls.splice(this.dialogFormControls.length - 2, 0, intervalFormControl);
    this.setDayStatusIcon();
    if (!this.isValid()) {
      form.disable();
      if (this.dialogFormControls && this.dialogFormControls.length > 1) {
        this.dialogFormControls[this.dialogFormControls.length - 1].options['classes'] = { hidden: false };
      }
    } else {
      form.enable();
      if (this.dialogFormControls && this.dialogFormControls.length > 1) {
        this.dialogFormControls[this.dialogFormControls.length - 1].options['classes'] = { hidden: true };
      }
    }
    form.updateValueAndValidity();
  }

  private async intervalChanged(form: UntypedFormGroup): Promise<void> {
    this.setDayStatusIcon();
    if (!this.isValid()) {
      form.disable();
      if (this.dialogFormControls && this.dialogFormControls.length > 1) {
        this.dialogFormControls[this.dialogFormControls.length - 1].options['classes'] = { hidden: false };
      }
    } else {
      form.enable();
      if (this.dialogFormControls && this.dialogFormControls.length > 1) {
        this.dialogFormControls[this.dialogFormControls.length - 1].options['classes'] = { hidden: true };
      }
    }
    form.updateValueAndValidity();
  }

  private isValid(): boolean {
    if (this.dialogTimeWindowConfigDay && this.dialogTimeWindowConfigDay.intervalls) {
      for (let i = 0; i < this.dialogTimeWindowConfigDay.intervalls.length; i++) {
        let starti = this.createDate(this.dialogTimeWindowConfigDay.intervalls[i].startTime);
        let endi = this.createDate(this.dialogTimeWindowConfigDay.intervalls[i].endTime);
        if (starti.format('H:mm') == '0:00' && endi.format('H:mm') == '0:00' && this.dialogTimeWindowConfigDay.intervalls.length > 1) {
          return false;
        } else if (starti.format('H:mm') != '0:00' && endi.format('H:mm') != '0:00' && starti > endi) {
          return false;
        }
        for (let j = i + 1; j < this.dialogTimeWindowConfigDay.intervalls.length; j++) {
          let startj = this.createDate(this.dialogTimeWindowConfigDay.intervalls[j].startTime);
          let endj = this.createDate(this.dialogTimeWindowConfigDay.intervalls[j].endTime);
          if (startj.format('H:mm') != '0:00' && endj.format('H:mm') != '0:00' && startj > endj) {
            return false;
          } else if (starti <= startj && (startj < endi || endi.format('H:mm') == '0:00')) {
            return false;
          } else if (startj <= starti && (starti < endj || endj.format('H:mm') == '0:00')) {
            return false;
          }
        }
      }
    }
    return true;
  }

  private createDate(time: string): Moment {
    // only time is matter
    let hour = +time.split(':')[0];
    let minute = +time.split(':')[1];
    let date = moment().hours(hour).minutes(minute).seconds(0);
    return date;
  }

}

import {SearchCriteriaPageable} from '../../shared/classes/search-criteria-pageable';

export class LatestMeasurementFilter extends SearchCriteriaPageable {
  public objectIds: number[] = undefined;
  public objectType: string = undefined;

  public constructor(latestMeasurementFilter?: Partial<LatestMeasurementFilter>) {
    super(latestMeasurementFilter);
    Object.assign(this, latestMeasurementFilter);
  }

  public toJSON(): Object {
    return {
      objectType: this.objectType,
      objectIds: this.objectIds,
      page: this.page,
      size: this.size,
    };
  }
}

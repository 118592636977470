import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';

import { PermissionService } from './permission.service';

import { OAuthService } from 'angular-oauth2-oidc';

@Injectable()
export class CanActivateGuard {
  public constructor(
    private permissionService: PermissionService,
    private router: Router,
    private oauthService: OAuthService) {
  }

  public canActivate(activatedRoute: ActivatedRouteSnapshot): Observable<boolean> | Promise<boolean> | boolean {

    if (this.permissionService.userIsNotAuthenticated()) {
      this.router.navigate(['/login']);
      return false;
    }

    if (this.permissionService.routeNeedsPermission(activatedRoute)) {
      return this.permissionService.hasPermission(activatedRoute.data.permission);
    }
    // User is authenticated and needs no permission.
    return true;
  }

}

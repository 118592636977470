import {JsonExportable} from '../../shared/classes/json-exportable';

export class Email implements JsonExportable {
  public receiver: string = undefined;
  public subject: string = undefined;
  public text: string = undefined;

  public constructor(email?: Partial<Email>) {
    Object.assign(this, email);
  }

  public toJSON(): Object {
    return {
      receiver: this.receiver,
      subject: this.subject,
      text: this.text,
    };
  }
}

import {Component, Input} from '@angular/core';

@Component({
  selector: 'phoenix-page',
  templateUrl: './phoenix-page.component.html',
  styleUrls: ['./phoenix-page.component.scss'],
})
export class PhoenixPageComponent {
  @Input()
  public isLoggedIn: boolean;

  public constructor() {
  }

}

import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { UserGroupDto } from '@phoenix/gapicon/group/dto/user-group-dto';
import { GroupApiService } from '@phoenix/gapicon/group/services/group-api.service';

import { PhoenixDialogButton } from '../../../components/phoenix-dialog-new/classes/phoenix-dialog-button';
import { TaskConfigDto } from '../../../gapicon/dto.module';

import { PhoenixTaskConfigDialog } from './classes/phoenix-task-config-dialog';
import { PhoenixTaskConfigDialogComponent } from './phoenix-task-config-dialog.component';

@Injectable({
  providedIn: 'root',
})
export class PhoenixTaskConfigDialogService {

  public constructor(
    private dialog: MatDialog,
    private userGroupsApiService: GroupApiService,
  ) {
  }

  public async openTaskConfigDialog(taskConfigs: TaskConfigDto[]): Promise<MatDialogRef<PhoenixTaskConfigDialogComponent>> {
    const userGroups: UserGroupDto[] = await this.userGroupsApiService.getGroups(false).toPromise();
    const dialogRef: MatDialogRef<PhoenixTaskConfigDialogComponent> = this.dialog.open(PhoenixTaskConfigDialogComponent, {
      minWidth: '900px',
      minHeight: '300px',
      autoFocus: false,
      data: <PhoenixTaskConfigDialog>{
        title: 'TASKCONFIG.HEADLINE',
        subtitle: 'TASKCONFIG.SUBHEADLINE',
        buttons: [
          new PhoenixDialogButton({
            label: 'TASKCONFIG.CANCEL',
            click: (): void => dialogRef.close(undefined),
          }),
          new PhoenixDialogButton({
            label: 'TASKCONFIG.APPLY',
            click: async (): Promise<void> => {
              dialogRef.close(taskConfigs);
            },
            color: 'accent',
            raised: true,
          })],

        taskConfigs: taskConfigs,
        usergroups: userGroups
      },
    });
    return dialogRef;
  }
}

import {SearchCriteriaPageable} from '../../shared/classes/search-criteria-pageable';

export class RoleSearchCriteria extends SearchCriteriaPageable {
  public name: string = undefined;

  public constructor(roleSearchCriteria?: Partial<RoleSearchCriteria>) {
    super(roleSearchCriteria);
    Object.assign(this, roleSearchCriteria);
  }

  public toJSON(): Object {
    return {
      name: this.name,
      page: this.page,
      size: this.size,
    };
  }
}

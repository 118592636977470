import { JsonExportable } from "@phoenix/gapicon/shared/classes/json-exportable";

export class TenantJob implements JsonExportable {
    public tenantId: string = undefined;
    public tenantName: string = undefined;
    public jobType: string = undefined;
    public interval: string = undefined;
    public nextExecutionTime: string = undefined;
    public previousExecutionTime: string = undefined;
    public triggerState: string = undefined;

    public constructor(tenantJob?: Partial<TenantJob>) {
        Object.assign(this, tenantJob);
    }

    public toJSON(): Object {
        return {
            tenantId: this.tenantId,
            tenantName: this.tenantName,
            jobType: this.jobType,
            interval: this.interval,
            nextExecutionTime: this.nextExecutionTime,
            previousExecutionTime: this.previousExecutionTime,
            triggerState: this.triggerState
        };
    }
}

import {JsonExportable} from '../../shared/classes/json-exportable';

export class PasswordResetRequest implements JsonExportable {
  public email: string = undefined;

  public constructor(passwordResetRequest?: Partial<PasswordResetRequest>) {
    Object.assign(this, passwordResetRequest);
  }

  public toJSON(): Object {
    return {
      email: this.email,
    };
  }
}

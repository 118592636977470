import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';

import {SharedModule} from '../shared/shared.module';

import {NotificationConfigMapperService} from './mapper/notification-config-mapper.service';
import {NotificationConfigApiService} from './services/notification-config-api.service';

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
  ],
  providers: [
    NotificationConfigApiService,
    NotificationConfigMapperService,
  ],
})
export class NotificationConfigApiModule {
}

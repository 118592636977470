import { HttpClient, HttpEvent, HttpEventType, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Subscriber, Observable } from 'rxjs';
import { map, reduce } from 'rxjs/operators';

import { AssetSearchCriteriaDto, FileItemDto } from '../../dto.module';
import { FileAccessToken } from '../../file/api/file-access-token';
import { FileItem } from '../../file/api/file-item';
import { FileAccessTokenDto } from '../../file/dto/file-access-token-dto';
import { FileMapperService } from '../../file/mapper/file-mapper.service';
import { SharedService } from '../../shared/services/shared.service';
import { Asset } from '../api/asset';
import { AssetSearchCriteria } from '../api/asset-search-criteria';
import { AssetDto } from '../dto/asset-dto';
import { AssetMapperService } from '../mapper/asset-mapper.service';
import { DynamicAssetDto } from '../dto/dynamic-asset-dto';
import { DynamicAsset } from '../api/dynamic-asset';

@Injectable()
export class AssetApiService {

  private assetsUrl: string = 'assets';
  private asset: string = 'asset';
  private groups: string = 'groups';

  public constructor(
    private http: HttpClient,
    private sharedService: SharedService,
    private assetMapperService: AssetMapperService,
    private fileMapperService: FileMapperService,
  ) {
  }

  /**
   * Gets the hierarchy from an asset up to a root asset
   * @param asset
   */
  public getHierarchyForAsset(asset: AssetDto): Observable<AssetDto[]> {
    return this.http.get<Asset[]>(
      this.sharedService.buildApiUrl('assets', 'asset', asset.id, 'path'),
    ).pipe(
      map((assets: Asset[]) => this.assetMapperService.assetArrayToAssetDtoArray(assets)),
    );
  }

  /**
   * Gets the all childrens elements from an asset "down" to the last element in a elements tree hierarchy
   * @param asset
   */
  public getAllChildrensForAsset(assetID: string): Observable<AssetDto[]> {
    return this.http.get<Asset[]>(
      this.sharedService.buildApiUrl('assets', 'asset', assetID, 'childrens'),
    ).pipe(
      map((assets: Asset[]) => this.assetMapperService.assetArrayToAssetDtoArray(assets)),
    );
  }

  /**
   * Gets assets by a given search criteria
   * @returns
   * @param assetSearchCriteriaDto
   * @param withFurtherPages
   */
  public getAssets(assetSearchCriteriaDto: AssetSearchCriteriaDto = new AssetSearchCriteriaDto(), withFurtherPages: boolean = true): Observable<AssetDto[]> {
    return this.sharedService.httpGetWithPagination<Asset>(
      this.sharedService.buildApiUrl('assets'),
      this.assetMapperService.assetSearchCriteriaDtoToAssetSearchCriteria(assetSearchCriteriaDto),
      withFurtherPages,
    ).pipe(
      map((assets: Asset[]) => this.assetMapperService.assetArrayToAssetDtoArray(assets)),
      reduce((all: AssetDto[], current: AssetDto[]) => all.concat(current)),
    );
  }

  public getAllAssetsWithMeasurmentPoints(assetSearchCriteriaDto: AssetSearchCriteriaDto = new AssetSearchCriteriaDto(), withFurtherPages: boolean = true): Observable<AssetDto[]> {
    return this.sharedService.httpGetWithPagination<Asset>(
      this.sharedService.buildApiUrl('rrp', 'asset', 'measurementPoints'),
      this.assetMapperService.assetSearchCriteriaDtoToAssetSearchCriteria(assetSearchCriteriaDto),
      withFurtherPages,
    ).pipe(
      map((assets: Asset[]) => this.assetMapperService.assetArrayToAssetDtoArray(assets)),
      reduce((all: AssetDto[], current: AssetDto[]) => all.concat(current)),
    );
  }


  /**
   * Gets the all asset branches for logged in user and given tenant
   * @param asset
   */
  public getAssetBranches(userId: string): Observable<AssetDto[]> {
    return this.http.get<Asset[]>(
      this.sharedService.buildApiUrl('assets', 'branches', userId.toString()),
    ).pipe(
      map((assets: Asset[]) => this.assetMapperService.assetArrayToAssetDtoArray(assets)),
    );
  }

  /**
   * Gets an asset by given ID with references.
   * @param id
   * @returns
   */
  public getAssetBranch(id: string): Observable<AssetDto[]> {
    return this.http.get<Asset[]>(
      this.sharedService.buildApiUrl('assets', 'branches', 'branch', id),
    ).pipe(
      map((asset: Asset[]) => this.assetMapperService.assetArrayToAssetDtoArray(asset)),
    );
  }

  /**
   * Returns if the user has permission for the asset.
   * @param id
   * @returns
   */
  public hasPermissionForAsset(id: string): Observable<boolean> {
    return this.http.get<boolean>(
      this.sharedService.buildApiUrl('assets', 'asset', id, 'hasPermission'),
    );
  }

  /**
   * Gets all assets, by search with partialname  the parend -children hierarchy starts from node with parentId
   * @returns
   * @param assetSearchCriteriaDto
   * @param withFurtherPages
   */
  public filterAssetsInHierarchy(assetSearchCriteriaDto: AssetSearchCriteriaDto = new AssetSearchCriteriaDto(), withFurtherPages: boolean = true): Observable<AssetDto[]> {
    return this.sharedService.httpGetWithPagination<Asset>(
      this.sharedService.buildApiUrl('assets', 'filter'),
      this.assetMapperService.assetSearchCriteriaDtoToAssetSearchCriteria(assetSearchCriteriaDto),
      withFurtherPages,
    ).pipe(
      map((assets: Asset[]) => this.assetMapperService.assetArrayToAssetDtoArray(assets)),
      reduce((all: AssetDto[], current: AssetDto[]) => all.concat(current)),
    );
  }

  /**
   * Gets an asset by given ID with references.
   * @param id
   * @returns
   */
  public getAssetById(id: string): Observable<AssetDto> {

    return this.http.get<Asset>(
      this.sharedService.buildApiUrl('assets', 'asset', id),
    ).pipe(
      map((asset: Asset) => this.assetMapperService.assetToAssetDto(asset)),
    );
  }

  /**
   * Gets an asset by given ID with references.
   * @param id
   * @returns
   */
  public getDynamicAssets(assetSearchCriteriaDto?: AssetSearchCriteriaDto): Observable<DynamicAssetDto[]> {

    return this.sharedService.httpGet<DynamicAsset>(
      this.sharedService.buildApiUrl('rrp', 'list'),
      this.assetMapperService.assetSearchCriteriaDtoToAssetSearchCriteria(assetSearchCriteriaDto),

    ).pipe(
      map((asset) => this.assetMapperService.dynamicAssetsToDynamicAssetsDto(asset)),
    );
  }

  /**
   * Gets all assets that or not under another asset
   * @param searchCriteriaDto optional
   */
  public getRootAssets(searchCriteriaDto?: AssetSearchCriteriaDto): Observable<AssetDto[]> {
    if (searchCriteriaDto) {
      searchCriteriaDto.parentId = 'root';
    } else {
      searchCriteriaDto = new AssetSearchCriteria({ parentId: 'root' });
    }
    return this.getAssets(searchCriteriaDto);
  }

  /**
   * Creates an asset by given assetDto
   * @param assetDto
   * @returns
   */
  public createAsset(assetDto: AssetDto): Observable<AssetDto> {
    return this.http.post<Asset>(
      this.sharedService.buildApiUrl('assets', 'asset'),
      this.assetMapperService.assetDtoToAsset(assetDto),
    ).pipe(
      map((asset: Asset) => this.assetMapperService.assetToAssetDto(asset)),
    );
  }

  /**
   * Creates an dynamic asset by given dynamicAssetDto
   * @param assetDto
   * @returns
   */
  public createDynamicAsset(assetDto: DynamicAssetDto): Observable<DynamicAssetDto> {
    return this.http.post<DynamicAsset>(
      this.sharedService.buildApiUrl('rrp', 'asset'),
      this.assetMapperService.dynamicAssetDtoToDynamicAsset(assetDto),
    ).pipe(
      map((asset: DynamicAsset) => this.assetMapperService.dynamicAssetToDynamicAssetDto(asset)),
    );
  }

  public updateDynamicAssets(assetsDto: DynamicAssetDto[]): Observable<DynamicAssetDto> {
    return this.http.put<DynamicAsset>(
      this.sharedService.buildApiUrl('rrp', 'assets'),
      this.assetMapperService.dynamicAssetsDtoToDynamicAssets(assetsDto),
    ).pipe(
      map((asset: DynamicAsset) => this.assetMapperService.dynamicAssetToDynamicAssetDto(asset)),
    );
  }


  /**
   * Clones all checkpoints in parent->children hierarchy starts from assetDto
   * @param assetDto
   * @returns
   */
  public cloneAssetsHierarchy(assetDto: AssetDto): Observable<void> {
    return this.http.post<void>(
      this.sharedService.buildApiUrl('assets', 'asset', 'clone'),
      this.assetMapperService.assetDtoToAsset(assetDto));
  }

  /**
   * Deletes an asset by given assetDto
   * @param assetDto
   * @returns
   */
  public deleteAsset(assetDto: AssetDto): Observable<void> {
    return this.http.delete<void>(
      this.sharedService.buildApiUrl('assets', 'asset', assetDto.id),
    );
  }

  /**
   * Deletes an asset by given ID
   * @param id
   * @returns
   */
  public deleteAssetById(id: string): Observable<void> {
    return this.deleteAsset(new AssetDto({ id }));
  }

  /**
   * Updates an assetDto by given assetDto
   * @param assetDto
   * @returns
   */
  public updateAsset(assetDto: AssetDto): Observable<AssetDto> {
    return this.http.put<Asset>(
      this.sharedService.buildApiUrl('assets', 'asset', assetDto.id),
      this.assetMapperService.assetDtoToAsset(assetDto),
    ).pipe(
      map((asset: Asset) => this.assetMapperService.assetToAssetDto(asset)),
    );
  }

  /**
   * Updates/Replaces a file of a measurement
   * @param assetDto
   * @param fileLabel
   * @param contentType
   * @param contentEncoding
   * @param file
   * @returns
   */
  public addFileToAsset(assetDto: AssetDto, fileLabel: string, contentType: string, contentEncoding: string, file: File): Observable<number> {
    return Observable.create((subscriber: Subscriber<number>) => {
      const data: FormData = new FormData();
      data.append('file', file, file.name);
      data.append('label', fileLabel);
      data.append('contentType', contentType);
      data.append('contentEncoding', contentEncoding);
      const req: HttpRequest<FormData> = new HttpRequest<FormData>(
        'POST',
        this.sharedService.buildApiUrl('assets', 'asset', assetDto.id, 'files', 'attachments', file.name),
        data,
      );
      this.http.request(req)
        // tslint:disable-next-line
        .subscribe((event: HttpEvent<any>) => {
          switch (event.type) {
            case HttpEventType.UploadProgress:
              subscriber.next(event.loaded / event.total);
              break;
            case HttpEventType.Response:
              subscriber.next(1);
              subscriber.complete();
              break;
          }
        }, subscriber.error, subscriber.complete);
    });
  }


  /**
   * Deletes an asset by given assetDto
   * @param assetDto
   * @param fileName
   * @returns
   */
  public removeFileFromAsset(assetDto: AssetDto, fileName: string): Observable<void> {
    return this.http.delete<void>(
      this.sharedService.buildApiUrl('assets', 'asset', assetDto.id, 'files', 'attachments', fileName),
    );
  }


  /**
   * Gets a file by given path string.
   * @returns
   * @param assetDto
   * @param fileName
   */
  public getFileFromAsset(assetDto: AssetDto, fileName: string): Observable<FileItemDto> {
    return this.http.get<FileItem>(
      this.sharedService.buildApiUrl('assets', 'asset', assetDto.id, 'files', 'attachments', fileName),
    ).pipe(
      map((fileItem: FileItem) => this.fileMapperService.fileItemToFileItemDto(fileItem)),
    );
  }


  /**
   * Gets a download token for a file by given asset and filename.
   * @param assetDto the asset dto
   * @param fileName name of the file the user wants to download
   * @returns
   */
  public getDownloadToken(assetDto: AssetDto, fileName: string): Observable<FileAccessTokenDto> {
    return this.http.get<FileAccessToken>(
      this.sharedService.buildApiUrl('downloadToken', 'assets', assetDto.id, 'attachments', fileName),
    ).pipe(
      map((fileAccessToken: FileAccessTokenDto) => this.fileMapperService.fileAccessTokenDtoToFileAccessToken(fileAccessToken)),
    );
  }

  /**
   * Gets a download token for a file by given path string.
   * @param assetDto the asset dto
   * @param fileName name of the file the user wants to download
   * @param token optional, token to authenticate download
   * @returns
   */
  public getDownloadUrl(assetDto: AssetDto, fileName: string, token?: string): string {
    if (token) {
      return this.sharedService.buildApiUrl('download', 'assets', assetDto.id, 'attachments', fileName) + '?token=' + token;
    } else {
      return this.sharedService.buildApiUrl('download', 'assets', assetDto.id, 'attachments', fileName);
    }
  }


  /**
   * Updates an userGroups for asset with assetId
   * @param assetDto
   * @returns
   */
  public updateUserGroupsForAsset(userGroupsIds: string[], assetId: string): Observable<AssetDto> {
    return this.http.put<Asset>(

      this.sharedService.buildApiUrl(this.assetsUrl, this.asset, assetId, this.groups),
      userGroupsIds,
    ).pipe(
      map((asset: Asset) => this.assetMapperService.assetToAssetDto(asset)),
    );
  }


}

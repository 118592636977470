import { Component, EventEmitter, Input, Output } from '@angular/core';

import { ToolbarMenuItem } from './toolbar-menu-item';

/**
 * ToolbarMenuItemComponent
 */
@Component({
  selector: 'phoenix-toolbar-menu-item',
  templateUrl: './toolbar-menu-item.component.html',
  styleUrls: ['./toolbar-menu-item.component.scss'],
})
export class ToolbarMenuItemComponent {
  @Input()
  public entry: ToolbarMenuItem;

  @Output()
  public clicked: EventEmitter<ToolbarMenuItem> = new EventEmitter();

  /**
   * hands up the clickEvent
   */
  public entryClicked(): void {
    this.clicked.emit(this.entry);
  }
}

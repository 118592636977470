import {CommonModule} from '@angular/common';
import {HTTP_INTERCEPTORS} from '@angular/common/http';
import {NgModule} from '@angular/core';

import {AuthApiModule} from '../auth/auth-api.module';

import {TenantInterceptor} from './interceptors/tenant-interceptor.service';

@NgModule({
  imports: [
    CommonModule,
    AuthApiModule,
  ],
  providers: [
    TenantInterceptor,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TenantInterceptor,
      multi: true,
    },
  ],
})
export class TenantInterceptorModule {
}

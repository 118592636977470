import {PropertyDto} from '../../property/dto/property-dto';

import {AccountInfoDto} from './account-info-dto';
import {UserConfigDto} from './user-config-dto';

export class UserDto {
  public accountInfo: AccountInfoDto = undefined;
  public company: string = undefined;
  public configurations: UserConfigDto[] = [];
  public email: string = undefined;
  public firstName: string = undefined;
  public id: string = undefined;
  public lastName: string = undefined;
  public properties: PropertyDto[] = undefined;
  public roles: { [key: string]: string[] } = undefined;

  public constructor(userDto?: Partial<UserDto>) {
    Object.assign(this, userDto);
  }
}


import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import * as _ from 'lodash-es';

import { PhoenixDynamicFormControl } from '../../classes/phoenix-dynamic-form-control';

@Component({
  selector: 'phoenix-dynamic-tabswitcher',
  templateUrl: './phoenix-dynamic-tabswitcher.component.html',
  styleUrls: ['./phoenix-dynamic-tabswitcher.component.scss'],
})
export class PhoenixDynamicTabswitcherComponent implements OnInit {
  public tabs: { key: string, label: string, content: string, formControls?: PhoenixDynamicFormControl<string | boolean>[] }[];
  @Input()
  public phoenixFormControl: PhoenixDynamicFormControl<string>;
  @Input()
  public form: UntypedFormGroup;
  private _selectedIndex: number;

  public get selectedIndex(): number {
    return this._selectedIndex;
  }

  public set selectedIndex(value: number) {
    this._selectedIndex = value;
    this.updateHiddenInputValue();
  }

  public ngOnInit(): void {
    if (this.phoenixFormControl.options['tabs']) {
      this.tabs = this.phoenixFormControl.options['tabs'];

      this.selectedIndex = 0;

      _.forEach(this.tabs, (tab: { key: string, label: string, content: string }, index: number) => {
        if (tab.key === this.phoenixFormControl.value) {
          this.selectedIndex = index;
        }
      });
    }
  }

  private updateHiddenInputValue(): void {
    this.form.get(this.phoenixFormControl.key).setValue(
      this.tabs[this.selectedIndex].key,
    );
  }
}


import {JsonExportable} from '../../shared/classes/json-exportable';

import {SensorConfig} from './sensor-config';

export class Sensor implements JsonExportable {
  public assetId: string = undefined;
  public currentConfiguration: SensorConfig = undefined;
  public wantedConfiguration: SensorConfig = undefined;
  public sensorId: string = undefined;
  public type: string = undefined;

  public constructor(sensor?: Partial<Sensor>) {
    Object.assign(this, sensor);
  }

  public toJSON(): Object {
    return {
      assetId: this.assetId,
      currentConfiguration: this.currentConfiguration,
      wantedConfiguration: this.wantedConfiguration,
      sensorId: this.sensorId,
      type: this.type,
    };
  }
}

import {SearchCriteriaPageable} from '../../shared/classes/search-criteria-pageable';

export class ThresholdSearchCriteria extends SearchCriteriaPageable {
  public tags: string[] = undefined;
  public name: string = undefined;
  public partialName: string = undefined;

  public constructor(thresholdSearchCriteria?: Partial<ThresholdSearchCriteria>) {
    super(thresholdSearchCriteria);
    Object.assign(this, thresholdSearchCriteria);
  }

  public toJSON(): Object {
    return {
      tags: this.tags,
      name: this.name,
      partialName: this.partialName,
      page: this.page,
      size: this.size,
    };
  }
}

import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';

import {AssetApiModule} from '../../gapicon/asset/asset-api.module';
import {PhoenixCommunicationServiceModule} from '../phoenix-communication-service/phoenix-communication-service.module';

import {PhoenixNavigationService} from './phoenix-navigation.service';

@NgModule({
  imports: [
    CommonModule,
    AssetApiModule,
    PhoenixCommunicationServiceModule,
  ],
  declarations: [],
  providers: [
    PhoenixNavigationService,
  ],
})
export class PhoenixNavigationServiceModule {
}

import { Injectable } from '@angular/core';
import { plainToClass } from 'class-transformer';
import * as _ from 'lodash-es';

import { Sensor } from '../api/sensor';
import { SensorConfig } from '../api/sensor-config';
import { SensorConfigValue } from '../api/sensor-config-value';
import { SensorSearchCriteria } from '../api/sensor-search-criteria';
import { SensorConfigDto } from '../dto/sensor-config-dto';
import { SensorConfigValueDto } from '../dto/sensor-config-value-dto';
import { SensorDto } from '../dto/sensor-dto';
import { SensorSearchCriteriaDto } from '../dto/sensor-search-criteria-dto';

@Injectable({
  providedIn: 'root',
})
export class SensorMapperService {

  public constructor() {
  }

  /*
  Sensor <=> SensorDto
   */
  public sensorToSensorDto(sensor: Sensor): SensorDto {
    const sensorDto: SensorDto = plainToClass(SensorDto, sensor);
    sensorDto.currentConfiguration = sensorDto.currentConfiguration ? this.sensorConfigToSensorConfigDto(sensor.currentConfiguration) : undefined;
    sensorDto.wantedConfiguration = sensorDto.wantedConfiguration ? this.sensorConfigToSensorConfigDto(sensor.wantedConfiguration) : undefined;

    return sensorDto;
  }

  public sensorDtoToSensor(sensorDto: SensorDto): Sensor {
    const sensor: Sensor = plainToClass(Sensor, sensorDto);
    sensor.currentConfiguration = sensor.currentConfiguration ? this.sensorConfigDtoToSensorConfig(sensorDto.currentConfiguration) : undefined;
    sensor.wantedConfiguration = sensor.wantedConfiguration ? this.sensorConfigDtoToSensorConfig(sensorDto.wantedConfiguration) : undefined;

    return sensor;
  }

  public sensorArrayToSensorDtoArray(sensors: Sensor[]): SensorDto[] {
    return _.map(sensors, (sensor: Sensor) => this.sensorToSensorDto(sensor));
  }

  public sensorDtoArrayToSensorArray(sensorDtos: SensorDto[]): Sensor[] {
    return _.map(sensorDtos, (sensorDto: SensorDto) => this.sensorDtoToSensor(sensorDto));
  }

  /*
 SensorSearchCriteria <=> SensorSearchCriteriaDto
  */
  public sensorSearchCriteriaDtoToSensorSearchCriteria(sensorSearchCriteriaDto: SensorSearchCriteriaDto): SensorSearchCriteria {
    return plainToClass(SensorSearchCriteria, sensorSearchCriteriaDto);
  }

  public sensorSearchCriteriaToSensorSearchCriteriaDto(sensorSearchCriteria: SensorSearchCriteria): SensorSearchCriteriaDto {
    return plainToClass(SensorSearchCriteriaDto, sensorSearchCriteria);
  }

  /*
  SensorConfig <=> SensorConfigDto
   */
  public sensorConfigDtoToSensorConfig(sensorConfigDto: SensorConfigDto): SensorConfig {
    const sensorConfig: SensorConfig = plainToClass(SensorConfig, sensorConfigDto);
    sensorConfig.configurations = this.sensorConfigValueDtoArrayToSensorConfigValueArray(sensorConfigDto.configurations);

    return sensorConfig;
  }

  public sensorConfigToSensorConfigDto(sensorConfig: SensorConfig): SensorConfigDto {
    const sensorConfigDto: SensorConfigDto = plainToClass(SensorConfigDto, sensorConfig);
    sensorConfigDto.configurations = this.sensorConfigValueArrayToSensorConfigValueDtoArray(sensorConfig.configurations);

    return sensorConfigDto;
  }

  /*
  SensorConfigValue <=> SensorConfigValueDto
  */
  public sensorConfigValueDtoToSensorConfigValue(sensorConfigValueDto: SensorConfigValueDto): SensorConfigValue {
    return plainToClass(SensorConfigValue, sensorConfigValueDto);
  }

  public sensorConfigValueToSensorConfigValueDto(sensorConfigValue: SensorConfigValue): SensorConfigValueDto {
    return plainToClass(SensorConfigValueDto, sensorConfigValue);
  }

  public sensorConfigValueDtoArrayToSensorConfigValueArray(sensorConfigValueDtos: SensorConfigValueDto[]): SensorConfigValue[] {
    return _.map(sensorConfigValueDtos, (sensorConfigValueDto: SensorConfigValueDto) => this.sensorConfigValueDtoToSensorConfigValue(sensorConfigValueDto));
  }

  public sensorConfigValueArrayToSensorConfigValueDtoArray(sensorConfigValues: SensorConfigValue[]): SensorConfigValueDto[] {
    return _.map(sensorConfigValues, (sensorConfigValue: SensorConfigValue) => this.sensorConfigValueToSensorConfigValueDto(sensorConfigValue));
  }
}

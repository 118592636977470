export class PhoenixTableLink {
  public label: string;
  public click: () => void;

  // compatibility to old constructors
  public constructor(label: string, click: () => void) {
    this.label = label;
    this.click = click;
  }
}

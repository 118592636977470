import {Injectable} from '@angular/core';
import {plainToClass} from 'class-transformer';

import {AssetMapperService} from '../../asset/mapper/asset-mapper.service';
import {SensorRegistration} from '../api/sensor-registration';
import {SensorRegistrationDto} from '../dto/sensor-registration-dto';

import {SensorMapperService} from './sensor-mapper.service';

@Injectable({
  providedIn: 'root',
})
export class SensorRegistrationMapperService {

  public constructor(
    private sensorMapper: SensorMapperService,
    private assetMapper: AssetMapperService,
  ) {
  }

  /*
 SensorRegistration <=> SensorRegistrationDto
  */
  public sensorRegistrationDtoToSensorRegistration(sensorRegistrationDto: SensorRegistrationDto): SensorRegistration {
    const sensorRegistration: SensorRegistration = plainToClass(SensorRegistration, sensorRegistrationDto);
    sensorRegistration.registrationParams = this.sensorMapper.sensorConfigValueDtoArrayToSensorConfigValueArray(sensorRegistrationDto.registrationParams);
    sensorRegistration.sensorAsset = this.assetMapper.assetDtoToAsset(sensorRegistrationDto.sensorAsset);

    return sensorRegistration;
  }

  public sensorRegistrationToSensorRegistrationDto(sensorRegistration: SensorRegistration): SensorRegistrationDto {
    const sensorRegistrationDto: SensorRegistrationDto = plainToClass(SensorRegistrationDto, sensorRegistration);
    sensorRegistrationDto.registrationParams = this.sensorMapper.sensorConfigValueArrayToSensorConfigValueDtoArray(sensorRegistration.registrationParams);
    sensorRegistrationDto.sensorAsset = this.assetMapper.assetToAssetDto(sensorRegistration.sensorAsset);

    return sensorRegistrationDto;
  }
}


import {JsonExportable} from '../../shared/classes/json-exportable';

export class UserConfig implements JsonExportable {
  public type: string = undefined;
  public value: string = undefined;

  public constructor(userConfig?: Partial<UserConfig>) {
    Object.assign(this, userConfig);
  }

  public toJSON(): Object {
    return {
      type: this.type,
      value: this.value,
    };
  }
}

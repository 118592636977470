import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {FuseSharedModule} from '@fuse/shared.module';
import {TranslateModule} from '@ngx-translate/core';
import {PhoenixIcon} from '@phoenix/enums/phoenix-icon.enum';
import {PhoenixCommunicationSubject} from '@phoenix/services/phoenix-communication-service/phoenix-communication-subject.enum';
import {PhoenixCommunicationService} from '@phoenix/services/phoenix-communication-service/phoenix-communication.service';
import {takeUntil} from 'rxjs/operators';

import {CanLoadGuard} from '../../guards/can-load.guard';
import {Permission, permissionIds} from '../../guards/permission';
import {navbarGroups} from '../../layout/components/navbar/navbar-groups/navbar-groups.module';
import {NavigationItem} from '../../navigation/classes/navigation-item';
import {NavigationService} from '../../navigation/navigation.service';
import {MainModule} from '../classes/main-module';

const permission: Permission = new Permission(permissionIds.TENANTADMINISTRATION.MEASUREMENTTYPE.read);
const routes: Routes = [
  {
    path: 'measurementtype',
    loadChildren: () => import('./measurement-type-content/measurement-type-content.module').then(m => m.MeasurementTypeContentModule),
    canLoad: [CanLoadGuard],
    data: {permission: permission},
  },
];

@NgModule({
  imports: [
    RouterModule.forChild(routes),
    TranslateModule,
    FuseSharedModule,
  ],
  declarations: [],
})
export class MeasurementTypeModule extends MainModule {
  private translationKey: string = 'NAV.MEASUREMENTTYPE';
  private navItem: NavigationItem = new NavigationItem(
    'measurementtype',
    5,
    false,
    this.translationKey,
    '/measurementtype',
    PhoenixIcon.VIEW_HEADLINE,
  );

  public constructor(
    private navigationService: NavigationService,
    private communicationService: PhoenixCommunicationService,
  ) {
    super();
    this.communicationService.getObservable(PhoenixCommunicationSubject.AssetBranchChanged)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(() => this.navigationService.refreshNavigation(this.navItem, navbarGroups.tenantAdministration.id, permission));
  }
}

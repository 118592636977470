import {JsonExportable} from '../../shared/classes/json-exportable';

export class UserInfo implements JsonExportable {
  public id: number = undefined;
  public email: string = undefined;
  public tenant: string = undefined;
  public firstName: string = undefined;
  public lastName: string = undefined;

  public constructor(userInfo?: Partial<UserInfo>) {
    Object.assign(this, userInfo);
  }

  public toJSON(): Object {
    return {
      id: this.id,
      email: this.email,
      tenant: this.tenant,
      firstName: this.firstName,
      lastName: this.lastName,
    };
  }
}

import { format, isValid } from 'date-fns';

import { Measurement } from '../../measurement/api/measurement';
import { JsonExportable } from '../../shared/classes/json-exportable';
import { UserInfo } from '../../user/api/user-info';

import { AlarmHistory } from './alarm-history';
import { AlarmStatus } from './alarm-status.enum';

export class Alarm implements JsonExportable {
  public status: AlarmStatus = undefined;
  public measurement: Measurement = undefined;
  public id: string = undefined;
  public timestamp: Date = undefined;
  public comment: string = undefined;
  public history: AlarmHistory[] = undefined;
  public updateTimestamp: Date = undefined;
  public updateUser: UserInfo = undefined;

  public constructor(alarm?: Partial<Alarm>) {
    Object.assign(this, alarm);
  }

  public toJSON(): Object {
    return {
      status: this.status,
      measurement: this.measurement,
      id: this.id,
      timestamp: (this.timestamp && isValid(this.timestamp)) ? format(this.timestamp, 'yyyy-MM-dd HH:mm') : undefined,
      comment: this.comment,
      history: this.history,
      updateTimestamp: (this.updateTimestamp && isValid(this.updateTimestamp)) ? format(this.updateTimestamp, 'yyyy-MM-dd HH:mm') : undefined,
      updateUser: this.updateUser,
    };
  }
}

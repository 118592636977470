import {AssetDto} from '../../dto.module';

import {SensorConfigValueDto} from './sensor-config-value-dto';

export class SensorRegistrationDto {
  public registrationParams: SensorConfigValueDto[] = undefined;
  public sensorAsset: AssetDto = undefined;

  public constructor(sensorRegistrationDto?: Partial<SensorRegistrationDto>) {
    Object.assign(this, sensorRegistrationDto);
  }
}

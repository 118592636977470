<div [ngClass]="phoenixFormControl.options['classes'] ? phoenixFormControl.options['classes'] : {}">
  <ng-container *ngIf="phoenixFormControl.label">
    <mat-form-field class="example-full-width" floatLabel="auto">
      <mat-label>{{ phoenixFormControl.label | translate : translationParams }}
      </mat-label>
      <input matInput placeholder=" {{ phoenixFormControl.label | translate : translationParams }}" disabled
        [value]="phoenixFormControl.value | translate : translationParams">
      <mat-hint *ngIf="phoenixFormControl.options['hint']">{{ phoenixFormControl.options['hint'] | translate :
        translationParams }}
      </mat-hint>
    </mat-form-field>
  </ng-container>
  <ng-container *ngIf="!phoenixFormControl.label && !phoenixFormControl.options['hiddenData']">
    <span [ngStyle]="readOnlyTextStyle()">
      {{ phoenixFormControl.value | translate : translationParams}}
    </span>
  </ng-container>
</div>
import {JsonExportable} from '../../shared/classes/json-exportable';
import {User} from '../../user/api/user';

export class RegistrationConfirmation implements JsonExportable {
  public token: string = undefined;
  public password: string = undefined;
  public user: User = undefined;

  public constructor(registrationConfirmation?: Partial<RegistrationConfirmation>) {
    Object.assign(this, registrationConfirmation);
  }

  public toJSON(): Object {
    return {
      token: this.token,
      password: this.password,
      user: this.user,
    };
  }
}

import { SearchCriteriaPageableDto } from '../../shared/classes/search-criteria-pageable-dto';

export class LatestMeasurementFilterDto extends SearchCriteriaPageableDto {
  public objectIds: number[] = undefined;
  //public objectIds: string[] = undefined;
  public objectType: string = undefined;

  public constructor(latestMeasurementFilterDto?: Partial<LatestMeasurementFilterDto>) {
    super(latestMeasurementFilterDto);
    Object.assign(this, latestMeasurementFilterDto);
  }
}

import {Component} from '@angular/core';

import {PhoenixButton} from '../phoenix-button/phoenix-button';

@Component({
  selector: 'phoenix-snackbar',
  templateUrl: './phoenix-snackbar.component.html',
  styleUrls: ['./phoenix-snackbar.component.scss'],
})
export class PhoenixSnackbarComponent {
  private _buttons: PhoenixButton[];
  private _text: string;

  public get text(): string {
    return this._text;
  }

  public set text(value: string) {
    this._text = value;
  }

  public get buttons(): PhoenixButton[] {
    return this._buttons;
  }

  public set buttons(value: PhoenixButton[]) {
    this._buttons = value;
  }
}

import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import * as _ from 'lodash-es';
import { Subscription } from 'rxjs';

import { PhoenixFormElementType } from '../../../components/phoenix-form/enums/phoenix-form-element-type.enum';
import { PhoenixFormSwitch } from '../../../components/phoenix-form/phoenix-form-switch/classes/phoenix-form-switch';
import { PhoenixFormText } from '../../../components/phoenix-form/phoenix-form-text/classes/phoenix-form-text';
import { SensorConfigValueDto } from '../../../gapicon/sensor/dto/sensor-config-value-dto';
import { SensorTypeDto } from '../../../gapicon/sensor/dto/sensor-type-dto';
import { PhoenixWizardHelperService } from '../../../services/phoenix-wizards/phoenix-wizard-helper.service';

import { PhoenixSensormanagementDialog } from './classes/phoenix-sensormanagement-dialog';

@Component({
  selector: 'phoenix-sensormanagement-dialog',
  templateUrl: './phoenix-sensormanagement-dialog.component.html',
  styleUrls: ['./phoenix-sensormanagement-dialog.component.scss'],
})
export class PhoenixSensormanagementDialogComponent implements OnInit, OnDestroy {

  public elementType: typeof PhoenixFormElementType = PhoenixFormElementType;
  public configElements: (PhoenixFormText | PhoenixFormSwitch)[] = [];
  public registrationElements: (PhoenixFormText | PhoenixFormSwitch)[] = [];
  public confirmElements: (PhoenixFormText | PhoenixFormSwitch)[] = [];
  public confirmEditElements: (PhoenixFormText | PhoenixFormSwitch)[] = [];
  public randomString: string;

  public sensorTypeConfigInfoText: string;
  private sensorTypeSubscription: Subscription;

  public constructor(
    @Inject(MAT_DIALOG_DATA) public data: PhoenixSensormanagementDialog,
    private translateService: TranslateService,
    private wizardHelper: PhoenixWizardHelperService,

  ) {
  }

  public ngOnInit(): void {
    this.randomString = this.wizardHelper.getRandomString();

    this.updateSensorTypeControls(
      this.data.formGroup.get(this.data.sensorTypeDropdown.formControlName).value,
    );

    this.sensorTypeSubscription = this.data.formGroup.get(this.data.sensorTypeDropdown.formControlName)
      .valueChanges.subscribe((sensorType: SensorTypeDto) => this.updateSensorTypeControls(sensorType));
  }

  public ngOnDestroy(): void {
    if (this.sensorTypeSubscription) {
      this.sensorTypeSubscription.unsubscribe();
    }
  }

  private updateSensorTypeControls(sensorType: SensorTypeDto): void {
    if (sensorType) {
      this.registrationElements = [];
      this.configElements = [];
      this.confirmElements = [];
      this.confirmEditElements = []

      const translationKey: string = 'CONFIGINFO.' + 'CONFIGINFOTEXT.' + sensorType.key.toUpperCase();
      const infoText: string = this.translateService.instant(translationKey);
      this.sensorTypeConfigInfoText = _.isEqual(infoText, translationKey) ? undefined : infoText;

      if (this.data.showRegistrationParams) {
        this.addSensorConfigControlsToDialog(sensorType.registrationParams, 'REGISTRATIONCONFIG', this.registrationElements);
      }
      this.addSensorConfigControlsToDialog(sensorType.configurations, 'SENSORCONFIG', this.configElements, this.data.sensorConfigPreset);
      if (!this.data.showRegistrationParams) {
        this.addEditControlsToDialog(sensorType.configurations, 'TEST', this.confirmEditElements, this.data.sensorConfigPreset);
      }
    }
  }

  private addConfirmControlsToDialog(
    prefix: string,
    elements: (PhoenixFormText | PhoenixFormSwitch)[],
    preset?: SensorConfigValueDto[]): void {
    const formGroup: UntypedFormGroup = new UntypedFormGroup({});

    this.data.formGroup.removeControl(prefix);
    this.data.formGroup.addControl(prefix, formGroup);

  }

  private addSensorConfigControlsToDialog(
    sensorConfig: SensorConfigValueDto[],
    prefix: string,
    elements: (PhoenixFormText | PhoenixFormSwitch)[],
    preset?: SensorConfigValueDto[]): void {
    const formGroup: UntypedFormGroup = new UntypedFormGroup({});

    this.data.formGroup.removeControl(prefix);
    this.data.formGroup.addControl(prefix, formGroup);

    const sensorType = this.data.formGroup.get(this.data.sensorTypeDropdown.formControlName).value
    const isAutomaticCounter = sensorType ? sensorType.measurementTypes.includes("automaticCounter") : false;


    sensorConfig.forEach((sensorConfigValue: SensorConfigValueDto) => {
      const presetValue: SensorConfigValueDto = preset ? _.find(preset, { key: sensorConfigValue.key }) : undefined;
      if (presetValue !== undefined) {
        if (presetValue.key === 'Offset') {
          const offsetValue = presetValue.value;
          const digitOffsetValue = offsetValue.split('.');
          let firstDigit;
          let secoundDigit;
          if (digitOffsetValue.length > 1) {
            firstDigit = digitOffsetValue[0];
            secoundDigit = digitOffsetValue[1];
            presetValue.value = firstDigit.concat('.').concat(secoundDigit.substring(0, 2));
          }
        }
      }

      const translationKey: string = 'CONFIGINFO.' + 'CONFIGINFOTEXT.' + sensorConfigValue.key.toUpperCase();
      const infoText: string = this.translateService.instant(translationKey);
      const sensorCaption: string = _.isEqual(infoText, translationKey) ? this.getNameAndUnit(sensorConfigValue) : infoText.concat(this.getUnit(sensorConfigValue));

      formGroup.addControl(sensorConfigValue.key, new UntypedFormControl(presetValue ? presetValue.value : sensorConfigValue.defaultValue));
      /* if (isAutomaticCounter) {
         formGroup.addControl("code", new FormControl(this.randomString))
         formGroup.addControl("confirmCode", new FormControl(null, [Validators.required, Validators.pattern(this.randomString)]))
 
       }*/

      switch (sensorConfigValue.type) {
        case 'STRING':
        case 'DECIMAL':
        case 'NUMBER':
          elements.push(
            new PhoenixFormText(formGroup, sensorConfigValue.key, sensorCaption, presetValue ? presetValue.value : sensorConfigValue.defaultValue),
          );
          break;
        case 'BOOLEAN':
          elements.push(
            new PhoenixFormSwitch(formGroup, sensorConfigValue.key, sensorCaption, '', false, (presetValue ? presetValue.value : sensorConfigValue.defaultValue) === '1'),
          );
          break;
      }
    });
    /* if (isAutomaticCounter) {
       elements.push(new PhoenixFormText(formGroup, 'code', 'WIZARDS.SENSOR.DELETE.DELETECODE', this.randomString, undefined, undefined, undefined, false, true, true))
       elements.push(new PhoenixFormText(formGroup, 'confirmCode', 'WIZARDS.SENSOR.DELETE.DELETECODEINPUT', '', 'WIZARDS.SENSOR.DELETE.DELETECODEINPUTHINT'))
     }*/
  }

  private addEditControlsToDialog(
    sensorConfig: SensorConfigValueDto[],
    prefix: string,
    elements: (PhoenixFormText | PhoenixFormSwitch)[],
    preset?: SensorConfigValueDto[]): void {
    const formGroup: UntypedFormGroup = new UntypedFormGroup({});

    this.data.formGroup.removeControl(prefix);
    this.data.formGroup.addControl(prefix, formGroup);

    const sensorType = this.data.formGroup.get(this.data.sensorTypeDropdown.formControlName).value
    const isAutomaticCounter = sensorType ? sensorType.measurementTypes.includes("automaticCounter") : false;

    if (isAutomaticCounter) {
      formGroup.addControl("code", new UntypedFormControl(this.randomString))
      formGroup.addControl("confirmCode", new UntypedFormControl(null, [Validators.required, Validators.pattern(this.randomString)]))

    }

    if (isAutomaticCounter) {
      elements.push(new PhoenixFormText(formGroup, 'code', 'WIZARDS.SENSOR.DELETE.DELETECODE', this.randomString, undefined, undefined, undefined, false, true, true))
      elements.push(new PhoenixFormText(formGroup, 'confirmCode', 'WIZARDS.SENSOR.DELETE.DELETECODEINPUT', '', 'WIZARDS.SENSOR.DELETE.DELETECODEINPUTHINT'))
    }
  }

  private getNameAndUnit(sensorConfigValue: SensorConfigValueDto): string {
    let s: string = sensorConfigValue.name;
    s = s.concat(this.getUnit(sensorConfigValue));
    return s;
  }

  private getUnit(sensorConfigValue: SensorConfigValueDto): string {
    let s: string = '';
    if (!_.isNil(sensorConfigValue.unit) && sensorConfigValue.unit.length > 0) {
      s = s.concat(' (', sensorConfigValue.unit, ')');
    }
    return s;
  }
}

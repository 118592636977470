import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observer, Observable, EMPTY } from 'rxjs';
//import { EmptyObservable } from 'rxjs-compat/observable/EmptyObservable';
import { expand } from 'rxjs/operators';

import { CustomEncoderForHttpParameter } from '../../../classes/custom-encoder-for-http-parameter';
import { SharedService } from '../../shared/services/shared.service';

import { Threshold } from '../api/threshold';
import { ThresholdSearchCriteria } from '../api/threshold-search-criteria';
import { ThresholdDto } from '../dto/threshold-dto';
import { ThresholdSearchCriteriaDto } from '../dto/threshold-search-criteria-dto';
import { ThresholdMapperService } from '../mapper/threshold-mapper.service';

@Injectable()
export class ThresholdApiService {

  private _threshold: string = 'threshold';
  private _thresholds: string = 'thresholds';

  /**
   * @param http
   * @param sharedService
   * @param thresholdMapperService
   */
  public constructor(private http: HttpClient,
    private sharedService: SharedService,
    private thresholdMapperService: ThresholdMapperService,
  ) {
  }

  /**
   * Gets all thresholds matching the search criteria.
   * @param thresholdSearchCriteriaDto
   * @param withFurtherPages
   * @returns
   */
  public getThresholds(thresholdSearchCriteriaDto: ThresholdSearchCriteriaDto = new ThresholdSearchCriteriaDto(), withFurtherPages: boolean = true): Observable<ThresholdDto[]> {

    return new Observable<ThresholdDto[]>((observer: Observer<ThresholdDto[]>): void => {
      const filter: ThresholdSearchCriteria = this.thresholdMapperService.thresholdSearchCriteriaDtoToThresholdSearchCriteria(thresholdSearchCriteriaDto);
      const url: string = this.sharedService.buildApiUrl(this._thresholds);
      const options: { params: HttpParams } = { params: new HttpParams({ encoder: new CustomEncoderForHttpParameter() }) };
      options.params = filter.getRequestQueryParams();

      this.http.get<Threshold[]>(url, options)
        .pipe(
          expand((threshold: Threshold[]): Observable<Threshold[]> => {
            if (withFurtherPages && threshold.length === filter.size) {
              filter.page += 1;
              options.params = filter.getRequestQueryParams();
              return this.http.get<Threshold[]>(url, options);
            } else {
              observer.complete();
              return EMPTY;
            }
          }))
        .subscribe((thresholds: Threshold[]) => {
          observer.next(this.thresholdMapperService.thresholdArrayToThresholdDtoArray(thresholds));
        });
    });
  }

  /**
   * Gets a threshold by given id.
   * @param id
   * @returns
   */
  public getThresholdById(id: string): Observable<ThresholdDto> {
    return new Observable<ThresholdDto>((observer: Observer<ThresholdDto>): void => {
      this.http.get<Threshold>(
        this.sharedService.buildApiUrl(this._thresholds, this._threshold, id.toString()))
        .subscribe((threshold: Threshold) => {
          observer.next(this.thresholdMapperService.thresholdToThresholdDto(threshold));
          observer.complete();
        });
    });
  }

  /**
   * Gets a threshold by given thresholdDto.
   * @param thresholdDto
   * @returns
   */
  public getThreshold(thresholdDto: ThresholdDto): Observable<ThresholdDto> {
    const getThreshold: Threshold = this.thresholdMapperService.thresholdDtoToThreshold(thresholdDto);
    return new Observable<ThresholdDto>((observer: Observer<ThresholdDto>): void => {
      this.http.get<Threshold>(
        this.sharedService.buildApiUrl(this._thresholds, this._threshold, getThreshold.id.toString()))
        .subscribe((threshold: Threshold) => {
          observer.next(this.thresholdMapperService.thresholdToThresholdDto(threshold));
          observer.complete();
        });
    });
  }

  /**
   * Creates a threshold by given thresholdDto.
   * @returns
   * @param thresholdDto
   */
  public createThreshold(thresholdDto: ThresholdDto): Observable<ThresholdDto> {
    const createThreshold: Threshold = this.thresholdMapperService.thresholdDtoToThreshold(thresholdDto);
    return new Observable<ThresholdDto>((observer: Observer<ThresholdDto>): void => {
      this.http.post<Threshold>(
        this.sharedService.buildApiUrl(this._thresholds, this._threshold), createThreshold)
        .subscribe((threshold: Threshold) => {
          observer.next(this.thresholdMapperService.thresholdToThresholdDto(threshold));
          observer.complete();
        });
    });
  }

  /**
   * Deletes a threshold by given thresholdDto.
   * @returns
   * @param thresholdDto
   */
  public deleteThreshold(thresholdDto: ThresholdDto): Observable<void> {
    const deleteThreshold: Threshold = this.thresholdMapperService.thresholdDtoToThreshold(thresholdDto);
    return this.http.delete<void>(
      this.sharedService.buildApiUrl(this._thresholds, this._threshold, deleteThreshold.id.toString()));
  }

  /**
   * Deletes a threshold by given id.
   * @param id
   * @returns
   */
  public deleteThresholdById(id: string): Observable<void> {
    return this.http.delete<void>(
      this.sharedService.buildApiUrl(this._thresholds, this._threshold, id.toString()));
  }

  /**
   * Updates a threshold by given thresholdDto.
   * @returns
   * @param thresholdDto
   */
  public updateThreshold(thresholdDto: ThresholdDto): Observable<ThresholdDto> {
    return new Observable<ThresholdDto>((observer: Observer<ThresholdDto>): void => {
      const updateThreshold: Threshold = this.thresholdMapperService.thresholdDtoToThreshold(thresholdDto);
      this.http.put<Threshold>(
        this.sharedService.buildApiUrl(this._thresholds, this._threshold, updateThreshold.id.toString()), updateThreshold)
        .subscribe((threshold: Threshold) => {
          observer.next(this.thresholdMapperService.thresholdToThresholdDto(threshold));
          observer.complete();
        });
    });
  }
}

export class PhoenixButton {
  private _click?: () => void;
  private _icon: string;
  private _classes: Object;
  private _label: string;
  private _tooltip?: string;
  private _disabled?: boolean = undefined;
  private _spinner?: boolean = false;
  private _badge?: string;

  public constructor(label?: string, icon?: string, click?: () => void, classes?: Object, tooltip?: string, disabled?: boolean, spinner?: boolean, badge?: string) {
    this._click = click;
    this._icon = icon;
    this._classes = classes;
    this._label = label;
    this._tooltip = tooltip;
    this._disabled = disabled;
    this._spinner = spinner;
    this._badge = badge;
  }

  public get badge(): string {
    return this._badge;
  }

  public set badge(value: string) {
    this._badge = value;
  }

  public get disabled(): boolean {
    return this._disabled;
  }

  public set disabled(value: boolean) {
    this._disabled = value;
  }

  public get tooltip(): string {
    return this._tooltip;
  }

  public set tooltip(value: string) {
    this._tooltip = value;
  }

  // tslint:disable-next-line
  public get click(): (context ?: any) => void {
    return this._click;
  }

  // tslint:disable-next-line
  public set click(value: (context ?: any) => void) {
    this._click = value;
  }

  public get icon(): string {
    return this._icon;
  }

  public set icon(value: string) {
    this._icon = value;
  }

  public get classes(): Object {
    return this._classes;
  }

  public set classes(value: Object) {
    this._classes = value;
  }

  public get label(): string {
    return this._label;
  }

  public set label(value: string) {
    this._label = value;
  }

  public get spinner(): boolean {
    return this._spinner;
  }

  public set spinner(value: boolean) {
    this._spinner = value;
  }
}

import {Injectable} from '@angular/core';
import {plainToClass} from 'class-transformer';

import {Email} from '../api/email';
import {EmailRequest} from '../api/email-request';
import {EmailDto} from '../dto/email-dto';
import {EmailRequestDto} from '../dto/email-request-dto';

@Injectable({
  providedIn: 'root',
})
export class EmailMapperService {

  public constructor() {
  }

  public emailToEmailDto(email: Email): EmailDto {
    return plainToClass(EmailDto, email);
  }

  public emailDtoToEmail(emailDto: EmailDto): Email {
    return plainToClass(Email, emailDto);
  }

  public emailRequestToEmailRequestDto(emailRequest: EmailRequest): EmailRequestDto {
    return plainToClass(EmailRequestDto, emailRequest);
  }

  public emailRequestDtoToEmailRequest(emailRequestDto: EmailRequestDto): EmailRequest {
    return plainToClass(EmailRequest, emailRequestDto);
  }
}

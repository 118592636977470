/**
 * Class for Toolbarmenu.
 */
export class ToolbarMenu {

  private _id: string;
  private _title: string;
  private _iconClass: string;
  private _iconName: string;
  private _imageClass: string;
  private _imageSrc: string;
  private _spanClass: string;

  public constructor(id: string, title: string, iconClass: string, iconName: string, imageClass: string, imageSrc: string, spanClass: string) {
    this.id = id;
    this.title = title;
    this.iconClass = iconClass;
    this.iconName = iconName;
    this.imageClass = imageClass;
    this.imageSrc = imageSrc;
    this.spanClass = spanClass;
  }

  public get id(): string {
    return this._id;
  }

  public set id(value: string) {
    this._id = value;
  }

  public get title(): string {
    return this._title;
  }

  public set title(value: string) {
    this._title = value;
  }

  public get iconClass(): string {
    return this._iconClass;
  }

  public set iconClass(value: string) {
    this._iconClass = value;
  }

  public get iconName(): string {
    return this._iconName;
  }

  public set iconName(value: string) {
    this._iconName = value;
  }

  public get imageClass(): string {
    return this._imageClass;
  }

  public set imageClass(value: string) {
    this._imageClass = value;
  }

  public get imageSrc(): string {
    return this._imageSrc;
  }

  public set imageSrc(value: string) {
    this._imageSrc = value;
  }

  public get spanClass(): string {
    return this._spanClass;
  }

  public set spanClass(value: string) {
    this._spanClass = value;
  }

}

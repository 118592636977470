<div [formGroup]="form" class="mt-12 mb-12">
  <mat-slide-toggle [formControlName]="phoenixFormControl.key">{{ phoenixFormControl.label | translate }}
  </mat-slide-toggle>
  <mat-error *ngIf="form.get(phoenixFormControl.key).invalid">
    <span data-translate="DIALOG.FORM.INVALID" translate></span>
  </mat-error>
  <div *ngIf="phoenixFormControl.options['hint']" class="mat-hint">
    {{ phoenixFormControl.options['hint'] | translate }}
  </div>
</div>

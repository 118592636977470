import {AssetDto} from '../../../../gapicon/asset/dto/asset-dto';

export class PhoenixTenantSelectorDialogData {
  public title: string;
  public subtitle: string;
  public cancelButton: boolean = false;
  public tenants: AssetDto[];

  public constructor(phoenixTenantSelectorDialogData?: Partial<PhoenixTenantSelectorDialogData>) {
    Object.assign(this, phoenixTenantSelectorDialogData);
  }
}

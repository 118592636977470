import {NgModule} from '@angular/core';

import {SharedModule} from '../../gapicon/shared/shared.module';

import {PhoenixSharedService} from './phoenix-shared.service';

@NgModule({
  imports: [
    SharedModule,
  ],
  exports: [],
  providers: [
    PhoenixSharedService,
  ],
})
export class PhoenixSharedServicesModule {
}

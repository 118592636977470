<mat-form-field [formGroup]="form">
  <input matInput [type]="hidePassword ? 'password' : 'text'" placeholder="{{phoenixFormControl.label | translate}}"
    [formControlName]="phoenixFormControl.key" (focus)="passwordFocus = true" (focusout)="passwordFocus = false"
    (input)="form.get(phoenixFormControl.key).updateValueAndValidity()">
  <mat-icon class="notranslate" matSuffix (click)="hidePassword = !hidePassword">{{hidePassword ? 'visibility' :
    'visibility_off'}}
  </mat-icon>
  <phoenix-password-rules *ngIf="phoenixFormControl.options['passwordRules']" class="mat-tooltip"
    [password]="form.get(phoenixFormControl.key).value" (checkResult)="passwordValidity.isPasswordValid = $event;
                               phoenixShared.validateAllFormGroupMembers(form)"
    [hidden]="!passwordFocus"></phoenix-password-rules>
  <mat-error *ngIf="form.get(phoenixFormControl.key).invalid">
    {{ phoenixShared.getMostRecentErrorText(form.get(phoenixFormControl.key), 'DIALOG.FORM.PASSWORD',
    'PASSWORD') | translate }}
  </mat-error>
</mat-form-field>
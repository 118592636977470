import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UserGroupDto } from '@phoenix/gapicon/group/dto/user-group-dto';
import _ from 'lodash-es';

import { PhoenixTaskConfigDialog } from '../classes/phoenix-task-config-dialog';

@Component({
  selector: 'phoenix-task-config-dialog-annually',
  templateUrl: './phoenix-task-config-dialog-annually.component.html',
  styleUrls: ['./phoenix-task-config-dialog-annually.component.scss'],
})
export class PhoenixTaskConfigDialogAnnuallyComponent implements OnInit {
  public months: { label: string, value: number, maxDays: number }[] = [
    { label: 'January', value: 1, maxDays: 31 },
    { label: 'February', value: 2, maxDays: 29 },
    { label: 'March', value: 3, maxDays: 31 },
    { label: 'April', value: 4, maxDays: 30 },
    { label: 'May', value: 5, maxDays: 31 },
    { label: 'June', value: 6, maxDays: 30 },
    { label: 'July', value: 7, maxDays: 31 },
    { label: 'August', value: 8, maxDays: 31 },
    { label: 'September', value: 9, maxDays: 30 },
    { label: 'October', value: 10, maxDays: 31 },
    { label: 'November', value: 11, maxDays: 30 },
    { label: 'December', value: 12, maxDays: 31 },
  ];
  public today: Date = new Date();
  public typeSelector: number;
  public week: { label: string, value: number }[] = [
    { label: 'first', value: 1 },
    { label: 'second', value: 2 },
    { label: 'third', value: 3 },
    { label: 'fourth', value: 4 },
    { label: 'last', value: 999 },
  ];
  public weekdays: { label: string, value: number }[] = [
    { label: 'Monday', value: 1 },
    { label: 'Tuesday', value: 2 },
    { label: 'Wednesday', value: 3 },
    { label: 'Thursday', value: 4 },
    { label: 'Friday', value: 5 },
    { label: 'Saturday', value: 6 },
    { label: 'Sunday', value: 7 },
  ];
  private days: { label: string, value: number }[];
  private maxDays: number = 31;

  public constructor(
    @Inject(MAT_DIALOG_DATA) public data: PhoenixTaskConfigDialog,
  ) {
  }

  public getDays(): { label: string, value: number }[] {
    return this.days;
  }

  public getStartDate(): Date {
    return this.data.formGroup.get('start').value;
  }

  public ngOnInit(): void {
    this.initDays();
  }

  public resetDaysAndMonth2(): void {
    this.resetDays();
    this.resetMonth2();
  }

  public resetWeekdays(): void {
    if (!_.isNil(this.data.formGroup.get('month').value)) {
      this.setMaxDays(this.data.formGroup.get('month').value);
    } else {
      this.data.formGroup.get('month').setValue(undefined);
    }
    this.data.formGroup.get('weekdays').setValue(undefined);
    this.data.formGroup.get('week').setValue(undefined);
  }

  public setMaxDays(month: number): void {
    this.maxDays = _.find(this.months, ['value', month]).maxDays;
    this.data.formGroup.get('days').setValue(_.filter(this.data.formGroup.get('days').value, (day: number) => day <= this.maxDays));
    this.initDays();
  }

  private initDays(): void {
    this.days = [];
    for (let i: number = 1; i <= this.maxDays; i++) {
      this.days.push({ label: i < 10 ? '0'.concat(i.toString()) : ''.concat(i.toString()), value: i });
    }
  }

  private resetDays(): void {
    this.data.formGroup.get('days').setValue([]);
  }

  private resetMonth2(): void {
    this.data.formGroup.get('month2').setValue(undefined);
  }

  get assignedUserGroupIds(): Date {
    return this.data.formGroup.get('assignedUserGroupIds').value;
  }

  get userGroups(): UserGroupDto[] {
    return this.data.usergroups.sort((a, b) => a.name.localeCompare(b.name));
  }
}

import {JsonExportable} from '../../shared/classes/json-exportable';

export class RegistrationRequest implements JsonExportable {
  public email: string = undefined;
  public roles: { [key: string]: string[] } = undefined;

  public constructor(registrationRequest?: Partial<RegistrationRequest>) {
    Object.assign(this, registrationRequest);
  }

  public toJSON(): Object {
    return {
      email: this.email,
      roles: this.roles,
    };
  }
}

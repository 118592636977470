import {SearchCriteriaPageableDto} from '../../shared/classes/search-criteria-pageable-dto';

export class RoleSearchCriteriaDto extends SearchCriteriaPageableDto {
  public name: string = undefined;

  public constructor(roleSearchCriteriaDto?: Partial<RoleSearchCriteriaDto>) {
    super(roleSearchCriteriaDto);
    Object.assign(this, roleSearchCriteriaDto);
  }
}

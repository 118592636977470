import {Component, Input} from '@angular/core';

import {PhoenixFormSwitch} from './classes/phoenix-form-switch';

@Component({
  selector: 'phoenix-form-switch',
  templateUrl: './phoenix-form-switch.component.html',
  styleUrls: ['./phoenix-form-switch.component.scss'],
})
export class PhoenixFormSwitchComponent {
  @Input() public element: PhoenixFormSwitch;
}

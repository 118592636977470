export class FileAttachmentDto {
  public label: string;
  public name: string;
  public contentEncoding: string;
  public contentType: string;

  public constructor(fileAttachmentDto?: Partial<FileAttachmentDto>) {
    Object.assign(this, fileAttachmentDto);
  }
}

import { MeasurementDto } from '../../dto.module';
import { UserInfoDto } from '../../user/dto/user-info-dto';

import { AlarmHistoryDto } from './alarm-history-dto';
import { AlarmStatusDto } from './alarm-status-dto.enum';
import { AlarmTypeDto } from './alarm-type-dto.enum';

export class AlarmDto {
  public status: AlarmStatusDto = undefined;
  public measurement: MeasurementDto = undefined;
  public id: string = undefined;
  public timestamp: Date = undefined;
  public comment: string = undefined;
  public history: AlarmHistoryDto[] = undefined;
  public updateTimestamp: Date = undefined;
  public updateUser: UserInfoDto = undefined;
  public type: AlarmTypeDto = undefined;

  public constructor(alarmDto?: Partial<AlarmDto>) {
    Object.assign(this, alarmDto);
  }
}

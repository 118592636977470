export enum PhoenixDynamicFormControlType {
  TextBox,
  TextArea,
  CheckBox,
  Text,
  Switch,
  Password,
  Icon,
  Dropdown,
  Chips,
  TabSwitcher,
  Button,
  ThresholdLimit,
  FormControlArray,
  IconButton,
  TimeWindowInterval,
}

import {TypeCountDto} from './type-count-dto';

export class AssetStatisticsDto {
  public activeNotificationTypesCount: TypeCountDto[];
  public inactiveNotificationTypesCount: TypeCountDto[];
  public violationCount: number;
  public positiveCount: number;
  public assetTypesCount: TypeCountDto[];

  public constructor(assetStatisticsDto?: Partial<AssetStatisticsDto>) {
    Object.assign(this, assetStatisticsDto);
  }
}

import {SearchCriteriaPageableDto} from '../../shared/classes/search-criteria-pageable-dto';

export class MeasurementSearchCriteriaDto extends SearchCriteriaPageableDto {
  public measurementPointType: string = undefined;
  public sensorId: string = undefined;
  public dateFrom: Date = undefined;
  public dateTo: Date = undefined;
  public type: string = undefined;
  public userIds: number[] = undefined;
  public assetIds: string[] = undefined;
  public thresholdIds: number[] = undefined;
  public ascendingSort: boolean = undefined;
  public ids: string[] = undefined;

  public constructor(measurementSearchCriteriaDto?: Partial<MeasurementSearchCriteriaDto>) {
    super(measurementSearchCriteriaDto);
    Object.assign(this, measurementSearchCriteriaDto);
  }
}

import { TimeWindowConfigDay } from "./time-window-config-day";

export class TimeWindowConfig {
  public monday: TimeWindowConfigDay;
  public tuesday: TimeWindowConfigDay;
  public wednesday: TimeWindowConfigDay;
  public thursday: TimeWindowConfigDay;
  public friday: TimeWindowConfigDay;
  public saturday: TimeWindowConfigDay;
  public sunday: TimeWindowConfigDay;
  public dstOffset: number;

  public constructor(timeWindowConfig?: Partial<TimeWindowConfig>) {
    Object.assign(this, timeWindowConfig);
  }
}
import { AssetDto, MeasurementDto, MeasurementPointDto } from '../../dto.module';

export class DynamicAssetDto extends AssetDto {

  public costCenterCode: string = undefined;
  public costCenterType: string = undefined;
  public language: string = undefined;
  public duration: number = undefined;
  public provider: string = undefined;

  public constructor(assetDto?: Partial<DynamicAssetDto>) {
    super(assetDto);
    Object.assign(this, assetDto);
  }
}

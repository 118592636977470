import {SearchCriteriaPageable} from '../../shared/classes/search-criteria-pageable';

export class TenantSearchCriteria extends SearchCriteriaPageable {
  public name: string = undefined;
  public description: string = undefined;

  public constructor(tenantSearchCriteria?: Partial<TenantSearchCriteria>) {
    super(tenantSearchCriteria);
    Object.assign(this, tenantSearchCriteria);
  }

  public toJSON(): Object {
    return {
      name: this.name,
      description: this.description,
      page: this.page,
      size: this.size,
    };
  }
}

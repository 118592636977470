import {Component, Input} from '@angular/core';

import {PhoenixSharedService} from '../../../services/phoenix-shared/phoenix-shared.service';

import {PhoenixFormPassword} from './classes/phoenix-form-password';

@Component({
  selector: 'phoenix-form-password',
  templateUrl: './phoenix-form-password.component.html',
  styleUrls: ['./phoenix-form-password.component.scss'],
})
export class PhoenixFormPasswordComponent {
  @Input() public element: PhoenixFormPassword;
  public hidePassword: boolean = true;

  public constructor(
    private sharedService: PhoenixSharedService,
  ) {
  }

  public generatePassword(): void {
    this.element.formGroup.get(this.element.formControlName).setValue(
      this.sharedService.generatePassword(),
    );
    this.hidePassword = false;
  }
}

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { PhoenixDialogModule } from '../../components/phoenix-dialog/phoenix-dialog.module';
import { PhoenixDialogComponent } from '../../components/phoenix-dialog/phoenix-dialog/phoenix-dialog.component';
import { PhoenixSnackbarService } from '../../components/phoenix-snackbar/phoenix-snackbar.service';
import { AccountApiModule } from '../../gapicon/account/account-api.module';
import { NotificationConfigApiModule } from '../../gapicon/notification-config/notification-config-api.module';
import { SensorApiModule } from '../../gapicon/sensor/sensor-api.module';
import { ThresholdApiModule } from '../../gapicon/threshold/threshold-api.module';
import { PhoenixMeasurementTypeDialogModule } from '../../templates/phoenix-dialog/phoenix-measurement-type-dialog/phoenix-measurement-type-dialog.module';
import { PhoenixSensormanagementDialogModule } from '../../templates/phoenix-dialog/phoenix-sensormanagement-dialog/phoenix-sensormanagement-dialog.module';
import { PhoenixTaskConfigDialogModule } from '../../templates/phoenix-dialog/phoenix-task-config-dialog/phoenix-task-config-dialog.module';
import { PhoenixCommunicationServiceModule } from '../phoenix-communication-service/phoenix-communication-service.module';

import { PhoenixAssetWizardService } from './phoenix-asset-wizard.service';
import { PhoenixDynamicAssetWizardService } from './phoenix-dynamic-asset-wizard.service';
import { PhoenixCommonWizardService } from './phoenix-common-wizard.service';
import { PhoenixTimeWindowConfigWizardService } from './phoenix-time-window-config-wizard.service';
import { PhoenixMeasurementPointWizardService } from './phoenix-measurement-point-wizard.service';
import { PhoenixThresholdWizardService } from './phoenix-threshold-wizard.service';
import { PhoenixUserWizardService } from './phoenix-user-wizard.service';
import { PhoenixWizardHelperService } from './phoenix-wizard-helper.service';
import { PhoenixTimeWindowConfigDayWizardService } from './phoenix-time-window-config-day-wizard.service';

@NgModule({
    imports: [
        CommonModule,
        PhoenixDialogModule,
        PhoenixCommunicationServiceModule,
        PhoenixMeasurementTypeDialogModule,
        PhoenixSensormanagementDialogModule,
        PhoenixTaskConfigDialogModule,
        // Gapicon
        NotificationConfigApiModule,
        ThresholdApiModule,
        AccountApiModule,
        SensorApiModule,
    ],
    providers: [
        PhoenixAssetWizardService,
        PhoenixDynamicAssetWizardService,
        PhoenixCommonWizardService,
        PhoenixTimeWindowConfigDayWizardService,
        PhoenixTimeWindowConfigWizardService,
        PhoenixMeasurementPointWizardService,
        PhoenixThresholdWizardService,
        PhoenixUserWizardService,
        PhoenixWizardHelperService,
        PhoenixSnackbarService,
    ]
})
export class PhoenixWizardsModule {
}

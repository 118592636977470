import {Component, Input} from '@angular/core';

@Component({
  selector: 'phoenix-page-content',
  templateUrl: './phoenix-page-content.component.html',
  styleUrls: ['./phoenix-page-content.component.scss'],
})
export class PhoenixPageContentComponent {
  @Input()
  public isLoggedIn: boolean;

  public constructor() {
  }


}

import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';

import {SharedModule} from '../shared/shared.module';

import {SensorMapperService} from './mapper/sensor-mapper.service';
import {SensorRegistrationMapperService} from './mapper/sensor-registration-mapper.service';
import {SensorTypeMapperService} from './mapper/sensor-type-mapper.service';
import {SensorApiService} from './services/sensor-api.service';

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
  ],
  providers: [
    SensorApiService,
    SensorMapperService,
    SensorTypeMapperService,
    SensorRegistrationMapperService,
  ],
})
export class SensorApiModule {
}

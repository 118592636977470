import {ExtendedInformationTypeDto} from './extended-information-type-dto.enum';

export class ExtendedInformationDto {
  public key: string;
  public name: string;
  public type: ExtendedInformationTypeDto;
  public value: string;

  public constructor(partial?: Partial<ExtendedInformationDto>) {
    Object.assign(this, partial);
  }
}

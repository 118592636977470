import { SearchCriteriaPageable } from '../../shared/classes/search-criteria-pageable';

export class UserSearchCriteria extends SearchCriteriaPageable {
  public firstname: string = undefined;
  public lastname: string = undefined;
  public email: string = undefined;
  public company: string = undefined;
  public role: string = undefined;
  public name: string = undefined;

  public constructor(userSearchCriteria?: Partial<UserSearchCriteria>) {
    super(userSearchCriteria);
    Object.assign(this, userSearchCriteria);
  }

  public toJSON(): Object {
    return {
      firstname: this.firstname,
      lastname: this.lastname,
      email: this.email,
      company: this.company,
      role: this.role,
      page: this.page,
      size: this.size,
      name: this.name
    };
  }
}

import { NgModule } from '@angular/core';


import { PhoenixSharedComponentsModule } from './components/phoenix-shared-components.module';
import { PhoenixSharedDirectivesModule } from './directives/phoenix-shared-directives.module';
import { AccountApiModule } from './gapicon/account/account-api.module';
import { AlarmApiModule } from './gapicon/alarm/alarm-api.module';
import { AssetApiModule } from './gapicon/asset/asset-api.module';
import { EmailApiModule } from './gapicon/email/email-api.module';
import { FileApiModule } from './gapicon/file/file-api.module';
import { MeasurementTypeApiModule } from './gapicon/measurement-type/measurement-type-api.module';
import { MeasurementApiModule } from './gapicon/measurement/measurement-api.module';
import { ReportApiModule } from './gapicon/report/report-api.module';
import { RoleApiModule } from './gapicon/role/role-api.module';
import { StatisticsApiModule } from './gapicon/statistics/statistics-api.module';
import { TenantApiModule } from './gapicon/tenant/tenant-api.module';
import { ThresholdApiModule } from './gapicon/threshold/threshold-api.module';
import { UserApiModule } from './gapicon/user/user-api.module';
import { PhoenixSharedServicesModule } from './services/phoenix-shared/phoenix-shared-services.module';

@NgModule({
  imports: [
    AccountApiModule,
    AlarmApiModule,
    AssetApiModule,
    ReportApiModule,
    EmailApiModule,
    FileApiModule,
    MeasurementApiModule,
    MeasurementTypeApiModule,
    RoleApiModule,
    StatisticsApiModule,
    ThresholdApiModule,
    UserApiModule,
    TenantApiModule,
    PhoenixSharedComponentsModule,
    PhoenixSharedDirectivesModule,
    PhoenixSharedServicesModule,
  ],
  exports: [
    AccountApiModule,
    AlarmApiModule,
    AssetApiModule,
    EmailApiModule,
    FileApiModule,
    MeasurementApiModule,
    MeasurementTypeApiModule,
    RoleApiModule,
    StatisticsApiModule,
    ThresholdApiModule,
    UserApiModule,
    TenantApiModule,
    PhoenixSharedComponentsModule,
    PhoenixSharedDirectivesModule,
    PhoenixSharedServicesModule,
  ],
  declarations: [],
})
export class PhoenixSharedModule {
}

import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observer, Observable, EMPTY } from 'rxjs';
//import { EmptyObservable } from 'rxjs-compat/observable/EmptyObservable';
import { expand } from 'rxjs/operators';

import { CustomEncoderForHttpParameter } from '../../../classes/custom-encoder-for-http-parameter';

import { Asset } from '../../asset/api/asset';
import { AssetDto } from '../../asset/dto/asset-dto';
import { AssetMapperService } from '../../asset/mapper/asset-mapper.service';
import { UserGroupUpdateDto } from '../../group/dto/user-group-update-dto';
import { SharedService } from '../../shared/services/shared.service';
import { TenantRequest } from '../api/tenant-request';
import { TenantSearchCriteria } from '../api/tenant-search-criteria';
import { TenantUpdateRequest } from '../api/tenant-update-request';
import { TenantRequestDto } from '../dto/tenant-request-dto';
import { TenantSearchCriteriaDto } from '../dto/tenant-search-criteria-dto';
import { TenantUpdateRequestDto } from '../dto/tenant-update-request-dto';
import { TenantRequestMapperService } from '../mapper/tenant-request-mapper.service';
import { TenantJobDto } from '../dto/tenant-job-dto';
import { TenantJob } from '../api/tenant-job';

@Injectable()
export class TenantApiService {

  private _tenant: string = 'tenant';
  private _tenants: string = 'tenants';
  private _assignedGroups: string = 'assigned-groups';
  private _rrp: string = 'rrp';
  private _providers: string = 'providers';
  private _jobs: string = 'jobs';

  /**
   * @param http
   * @param sharedService
   * @param tenantRequestMapperService
   * @param assetMapperService
   */
  public constructor(private http: HttpClient,
    private sharedService: SharedService,
    private tenantRequestMapperService: TenantRequestMapperService,
    private assetMapperService: AssetMapperService,
  ) {
  }

  /**
   * Gets all tenants matching the search criteria.
   * @param tenantSearchCriteriaDto
   * @param withFurtherPages
   * @returns
   */
  public getTenants(tenantSearchCriteriaDto: TenantSearchCriteriaDto = new TenantSearchCriteriaDto(), withFurtherPages: boolean = true): Observable<AssetDto[]> {

    return new Observable<AssetDto[]>((observer: Observer<AssetDto[]>): void => {
      const filter: TenantSearchCriteria = this.tenantRequestMapperService.tenantSearchCriteriaDtoToTenantSearchCriteria(tenantSearchCriteriaDto);
      const url: string = this.sharedService.buildApiUrl(this._tenants);
      const options: { params: HttpParams } = { params: new HttpParams({ encoder: new CustomEncoderForHttpParameter() }) };
      options.params = filter.getRequestQueryParams();

      this.http.get<Asset[]>(url, options)
        .pipe(
          expand((asset: Asset[]): Observable<Asset[]> => {
            if (withFurtherPages && asset.length === filter.size) {
              filter.page += 1;
              options.params = filter.getRequestQueryParams();
              return this.http.get<Asset[]>(url, options);
            } else {
              observer.complete();
              return EMPTY;
            }
          }))
        .subscribe((assets: Asset[]) => {
          observer.next(this.assetMapperService.assetArrayToAssetDtoArray(assets));
        });
    });
  }

  /**
   * Gets a tenant by given id.
   * @param id
   * @returns
   */
  public getTenantById(id: string): Observable<AssetDto> {
    return new Observable<AssetDto>((observer: Observer<AssetDto>): void => {
      this.http.get<Asset>(
        this.sharedService.buildApiUrl(this._tenants, this._tenant, id.toString()))
        .subscribe((tenant: Asset) => {
          observer.next(this.assetMapperService.assetToAssetDto(tenant));
          observer.complete();
        });
    });
  }

  /**
   * Gets a tenant by given id.
   * @param id
   * @returns
   */
  public getProvidersByTenantId(id: string): Observable<string[]> {
    return new Observable<string[]>((observer: Observer<string[]>): void => {
      this.http.get<string[]>(
        this.sharedService.buildApiUrl(this._rrp, this._providers, id.toString()))
        .subscribe((providers: string[]) => {
          observer.next(providers);
          observer.complete();
        });
    });
  }


  /**
   * Gets a tenant by given id.
   * @param id
   * @returns
   */
  public getAllTenantsForUserWithId(userId: string): Observable<AssetDto[]> {
    return new Observable<AssetDto[]>((observer: Observer<AssetDto[]>): void => {
      this.http.get<Asset[]>(
        this.sharedService.buildApiUrl(this._tenants, userId.toString()))
        .subscribe((tenant: Asset[]) => {
          observer.next(this.assetMapperService.assetArrayToAssetDtoArray(tenant));
          observer.complete();
        });
    });
  }

  /**
   * Gets a tenant by given tenantDto.
   * @returns
   * @param tenantDto
   */
  public getTenant(tenantDto: AssetDto): Observable<AssetDto> {
    const getTenant: Asset = this.assetMapperService.assetDtoToAsset(tenantDto);
    return new Observable<AssetDto>((observer: Observer<AssetDto>): void => {
      this.http.get<Asset>(
        this.sharedService.buildApiUrl(this._tenants, this._tenant, getTenant.id.toString()))
        .subscribe((tenant: Asset) => {
          observer.next(this.assetMapperService.assetToAssetDto(tenant));
          observer.complete();
        });
    });
  }

  /**
   * Creates a tenant by given tenantRequestDto.
   * @returns
   * @param tenantRequestDto
   */
  public createTenant(tenantRequestDto: TenantRequestDto): Observable<AssetDto> {
    const createTenant: TenantRequest = this.tenantRequestMapperService.tenantRequestDtoToTenantRequest(tenantRequestDto);

    return new Observable<AssetDto>((observer: Observer<AssetDto>): void => {
      this.http.post<Asset>(
        this.sharedService.buildApiUrl(this._tenants, this._tenant), createTenant)
        .subscribe((tenant: Asset) => {
          observer.next(this.assetMapperService.assetToAssetDto(tenant));
          observer.complete();
        });
    });
  }

  /**
   * Deletes a tenant by given tenantDto.
   * @returns
   * @param tenantDto
   */
  public deleteTenant(tenantDto: AssetDto): Observable<void> {
    const deleteTenant: Asset = this.assetMapperService.assetDtoToAsset(tenantDto);
    return this.http.delete<void>(
      this.sharedService.buildApiUrl(this._tenants, this._tenant, deleteTenant.id.toString()));
  }

  /**
   * Deletes a tenant by given id.
   * @param id
   * @returns
   */
  public deleteTenantById(id: string): Observable<void> {
    return this.http.delete<void>(
      this.sharedService.buildApiUrl(this._tenants, this._tenant, id.toString()));
  }

  /**
   * Updates a tenant by given tenantDto.
   * @returns
   * @param tenantDto
   * @param tenantUpdateRequestDto
   */
  public updateTenant(tenantDto: AssetDto, tenantUpdateRequestDto?: TenantUpdateRequestDto): Observable<AssetDto> {
    const updateTenant: Asset = this.assetMapperService.assetDtoToAsset(tenantDto);
    let updateTenantRequest: TenantUpdateRequest;
    if (tenantUpdateRequestDto) {
      updateTenantRequest = this.tenantRequestMapperService.tenantUpdateRequestDtoToTenantUpdateRequest(tenantUpdateRequestDto);
    } else {
      updateTenantRequest = this.tenantRequestMapperService.tenantToTenantUpdateRequest(updateTenant);
    }
    return new Observable<AssetDto>((observer: Observer<AssetDto>): void => {
      this.http.put<Asset>(
        this.sharedService.buildApiUrl(this._tenants, this._tenant, updateTenant.id.toString()), updateTenantRequest)
        .subscribe((tenant: Asset) => {
          observer.next(this.assetMapperService.assetToAssetDto(tenant));
          observer.complete();
        });
    });
  }

  public updateAssignedGroupsInTenant(tenantDto: AssetDto): any {
    const tenantId: string = tenantDto.id;
    const userGroupUpdateDto: UserGroupUpdateDto = this.tenantRequestMapperService.assetDtoToUserGroupUpdateDto(tenantDto);
    return new Observable<AssetDto>((observer: Observer<AssetDto>): void => {
      this.http.put<Asset>(
        this.sharedService.buildApiUrl(this._tenants, this._tenant, this._assignedGroups, tenantId), userGroupUpdateDto)
        .subscribe((tenant: Asset) => {
          observer.next(this.assetMapperService.assetToAssetDto(tenant));
          observer.complete();
        });
    });
  }

  public getTenantJobs(): Observable<TenantJobDto[]> {
    return new Observable<TenantJobDto[]>((observer: Observer<TenantJobDto[]>): void => {
      const url: string = this.sharedService.buildApiUrl(this._tenants, this._jobs);

      this.http.get<TenantJobDto[]>(url)
        .pipe()
        .subscribe((tenantJobs: TenantJob[]) => {
          observer.next(this.tenantRequestMapperService.tenantJobDtosToTenantJobs(tenantJobs));
          observer.complete();
        });
    });
  }

  public restartTenantJobs(): Observable<AssetDto[]> {

    return new Observable<AssetDto[]>((observer: Observer<AssetDto[]>): void => {
      this.http.put<Asset[]>(
        this.sharedService.buildApiUrl(this._tenants, this._jobs), null)
        .pipe()
        .subscribe((assets: Asset[]) => {
          observer.next(this.assetMapperService.assetArrayToAssetDtoArray(assets));
          observer.complete();
        });
    });
  }


  /**
   * Deletes a tenant job by given tenantJobDto.
   * @returns
   * @param tenantJobDto
   */
  public deleteTenantJob(tenantJob: TenantJobDto): Observable<void> {
    const deleteTenantJob: TenantJob = this.tenantRequestMapperService.tenantJobDtoToTenantJob(tenantJob);
    return this.http.delete<void>(
      this.sharedService.buildApiUrl(this._tenants, this._tenant, deleteTenantJob.tenantId,
        this._jobs, deleteTenantJob.jobType));
  }

  /**
   * Deletes a tenant job by given tenantJobDto.
   * @returns
   * @param tenantJobDto
   */
  public resumeTenantJob(tenantJob: TenantJobDto): Observable<TenantJobDto> {
    const resumedTenantJob: TenantJob = this.tenantRequestMapperService.tenantJobDtoToTenantJob(tenantJob);
    return new Observable<TenantJobDto>((observer: Observer<TenantJobDto>): void => {
      this.http.put<TenantJob>(
        this.sharedService.buildApiUrl(this._tenants, this._tenant, resumedTenantJob.tenantId,
          this._jobs, resumedTenantJob.jobType), null)
        .pipe()
        .subscribe((job: TenantJob) => {
          observer.next(this.tenantRequestMapperService.tenantJobDtoToTenantJob(job));
          observer.complete();
        });
    });
  }

}

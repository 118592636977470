<div class="navbar-header" fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="10px"
  [ngClass]="fuseConfig.layout.navbar.secondaryBackground">
  <div fxFlex="25%" class="logo"></div>
  <!--<div fxFlex="grow" class="font-size-12 font-weight-300 logo-text" translate data-translate="APP.TITLE"></div>-->
  <div fxFlex="none">
    <button mat-button class="toggle-button-navbar mat-icon-button" (click)="toggleSidebarFolded()" fxHide.lt-lg>
      <mat-icon class="notranslate material-icons color_white">menu</mat-icon>
    </button>

    <button mat-button class="toggle-button-navbar mat-icon-button" (click)="toggleSidebarOpened()" fxHide.gt-md>
      <mat-icon class="notranslate material-icons color_white">arrow_back</mat-icon>
    </button>
  </div>
</div>

<div class="navbar-content" fusePerfectScrollbar [fusePerfectScrollbarOptions]="{suppressScrollX: true}"
  [ngClass]="fuseConfig.layout.navbar.primaryBackground">
  <fuse-navigation layout="vertical"></fuse-navigation>
</div>
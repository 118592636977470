import { Component, EventEmitter, Input, Output } from '@angular/core';
import * as _ from 'lodash-es';

import { PasswordRule } from './password-rule';

@Component({
  selector: 'phoenix-password-rules',
  templateUrl: './phoenix-password-rules.component.html',
  styleUrls: ['./phoenix-password-rules.component.scss'],
})

export class PhoenixPasswordRulesComponent {
  public rules: PasswordRule[] = [
    {
      label: 'PASSWORDRULES.UPPERCASE',
      status: false,
      regex: /[A-Z]/,
    }, {
      label: 'PASSWORDRULES.LOWERCASE',
      status: false,
      regex: /[a-z]/,
    }, {
      label: 'PASSWORDRULES.NUMBERS',
      status: false,
      regex: /\d/,
    }, {
      label: 'PASSWORDRULES.SPECCHAR',
      status: false,
      regex: /[!§$%&?#*]/,
    }, {
      label: 'PASSWORDRULES.LENGTH',
      status: false,
      regex: /^.{8,64}$/,
      mandatory: true,
    },
  ];
  @Output()
  public checkResult: EventEmitter<boolean> = new EventEmitter();
  private _password: string;

  public get password(): string {
    return this._password;
  }

  @Input()
  public set password(str: string) {
    this._password = str ? str : '';
    this.checkPassword();
    this.checkResult.emit(this.checkRules());
  }

  public checkPassword(): void {
    this.rules.forEach((rule: PasswordRule) => {
      rule.status = rule.regex.test(this.password);
    });
  }

  public checkRules(): boolean {
    const requiredMatchingRules: number = 4;
    const countMatchingRules: { [key: string]: number } = _.countBy(this.rules, (element: PasswordRule) => {
      return element.status;
    });
    const countMandatoryRules: { [key: string]: number } = _.countBy(this.rules, (element: PasswordRule) => {
      if (element.mandatory) {
        return element.status;
      }
    });

    return !countMandatoryRules.false && countMatchingRules.true >= requiredMatchingRules;
  }
}

<ng-container [formGroup]="element.formGroup">
  <mat-form-field>
    <mat-label>{{element.placeholder | translate}}</mat-label>
    <mat-select [compareWith]="element.comparator"
                [formControlName]="element.formControlName"
                [placeholder]="element.placeholder | translate">
      <mat-option *ngFor="let entry of element.entries" [value]="entry.value">
        {{entry.viewValue}}
      </mat-option>
    </mat-select>
  </mat-form-field>
</ng-container>

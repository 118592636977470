import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {SharedService} from '../../shared/services/shared.service';
import {Group} from '../api/group';
import {UserGroupDto} from '../dto/user-group-dto';
import {UserGroupSearchCriteriaDto} from '../dto/user-group-seacrh-criteria-dto';
import {UserGroupMapperService} from '../mapper/usergroup-mapper.service';

@Injectable({
  providedIn: 'root',
})
export class GroupApiService {

  private groupsUrl: string = 'userGroups';
  private group: string = 'userGroup';

  public constructor(
    private http: HttpClient,
    private sharedService: SharedService,
    private userGroupMapperService: UserGroupMapperService,
  ) {
  }

  public getGroups(allGroupsVisibility: boolean = true, userGroupSearchCriteriaDto?: UserGroupSearchCriteriaDto): Observable<UserGroupDto[]> {
    let params: string = '';
    if (userGroupSearchCriteriaDto !== undefined && userGroupSearchCriteriaDto.partialName !== undefined && userGroupSearchCriteriaDto.partialName !== null) {
      params = '?partialName=' + userGroupSearchCriteriaDto.partialName;
    }
    return this.http.get<Group[]>(this.sharedService.buildApiUrl(this.groupsUrl) + params).pipe(
      map((groups: Group[]) => {
        return this.userGroupMapperService.groupsToGroupsDto(groups, allGroupsVisibility);
      }),
    );
  }

  /**
   * Creates a userGroup by given userGroupDto.
   * @returns
   * @param groupDto
   */
  public createNewGroup(userGroupDto: UserGroupDto): Observable<void> {
    return this.http.post<void>(
      this.sharedService.buildApiUrl(this.groupsUrl, this.group),
      this.userGroupMapperService.groupDtoToGroup(userGroupDto));
    // .pipe(
    //     map((gr: Group) => this.groupsToGroupsDto([gr])),
    // );
  }

  /**
   * updates userGroup by given userGroupDto.
   * @returns
   * @param groupDto
   */
  public updateUserGroup(groupDto: UserGroupDto): Observable<UserGroupDto> {
    return this.http.put<Group>(
      this.sharedService.buildApiUrl(this.groupsUrl, this.group, groupDto.id.toString()),

      this.userGroupMapperService.groupDtoToGroup(groupDto),
    ).pipe(
      map((gr: Group) => this.userGroupMapperService.groupToGroupDto(gr)),
    );
  }

  /**
   * deleted a userGroup by given userGroupDto.
   * @returns
   * @param groupDto
   */
  public deleteUserGroup(groupDto: UserGroupDto): Observable<void> {
    return this.http.delete<void>(
      this.sharedService.buildApiUrl(this.groupsUrl, this.group, groupDto.id.toString()),
    );
  }
}

import {SearchCriteriaPageableDto} from '../../shared/classes/search-criteria-pageable-dto';

export class SensorTypeSearchCriteriaDto extends SearchCriteriaPageableDto {
  public measurementTypes: string[] = undefined;
  public types: string[] = undefined;

  public constructor(sensorTypeSearchCriteriaDto?: Partial<SensorTypeSearchCriteriaDto>) {
    super(sensorTypeSearchCriteriaDto);
    Object.assign(this, sensorTypeSearchCriteriaDto);
  }
}

import {UserInfoDto} from '../../user/dto/user-info-dto';

import {AlarmStatusDto} from './alarm-status-dto.enum';

export class AlarmHistoryDto {
  public status: AlarmStatusDto = undefined;
  public timestamp: Date = undefined;
  public comment: String = undefined;
  public user: UserInfoDto = undefined;

  public constructor(alarmHistoryDto?: Partial<AlarmHistoryDto>) {
    Object.assign(this, alarmHistoryDto);
  }
}

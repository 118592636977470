import {SearchCriteriaPageable} from '../../shared/classes/search-criteria-pageable';

export class SensorTypeSearchCriteria extends SearchCriteriaPageable {
  public measurementTypes: string[] = undefined;
  public types: string[] = undefined;

  public constructor(sensorTypeSearchCriteria?: Partial<SensorTypeSearchCriteria>) {
    super(sensorTypeSearchCriteria);
    Object.assign(this, sensorTypeSearchCriteria);
  }

  public toJSON(): Object {
    return {
      measurementTypes: this.measurementTypes,
      types: this.types,
      page: this.page,
      size: this.size,
    };
  }
}

import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Observable, of } from 'rxjs';

import { AssetDto } from '../../../gapicon/asset/dto/asset-dto';

import { PhoenixCloneConfirmationDialogData } from './classes/PhoenixCloneConfirmationDialogData';
import { PhoenixCloneConfirmationDialogComponent } from './phoenix-clone-confirmation-dialog.component';

@Injectable({
  providedIn: 'root',
})
export class PhoenixCloneConfirmationDialogService {
  public constructor(
    private dialog: MatDialog,
  ) {
  }
  public showClonedElementConfirmationSelectorDialog(element: AssetDto): Observable<Boolean> {

    const dialogRef: MatDialogRef<PhoenixCloneConfirmationDialogComponent> = this.dialog.open(PhoenixCloneConfirmationDialogComponent, {
      closeOnNavigation: false,
      autoFocus: false,
      disableClose: false,
      data: new PhoenixCloneConfirmationDialogData({
        rootClonedElement: element,
        title: 'WIZARDS.CLONE.TITLE',
        description: 'WIZARDS.CLONE.DESCRIPTION',
        question: 'WIZARDS.CLONE.QUESTION',
      }),
    });

    return dialogRef.afterClosed();
  }
}

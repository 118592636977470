import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import * as _ from 'lodash-es';

import { PhoenixDynamicFormControl } from '../../classes/phoenix-dynamic-form-control';

@Component({
  selector: 'phoenix-dynamic-button',
  templateUrl: './phoenix-dynamic-button.component.html',
  styleUrls: ['./phoenix-dynamic-button.component.scss'],
})
export class PhoenixDynamicButtonComponent implements OnInit {
  public buttonColor: string;
  @Input()
  public form: UntypedFormGroup;
  @Input()
  public phoenixFormControl: PhoenixDynamicFormControl<boolean>;
  public showSpinner: boolean;

  public buttonCallback(): void {
    if (!this.form.get('buttonClicked')) {
      this.form.addControl('buttonClicked', new UntypedFormControl());
    }
    this.showSpinner = true;
    this.form.get('buttonClicked').setValue(this.phoenixFormControl.key);
  }

  public async callbackFromOptions(): Promise<void> {
    this.phoenixFormControl.disabled = true;
    this.showSpinner = true;
    await this.phoenixFormControl.options['callback'](this.form);
    this.showSpinner = false;
    this.phoenixFormControl.disabled = false;
  }

  public ngOnInit(): void {
    this.buttonColor = _.has(this.phoenixFormControl.options, 'color') ? this.phoenixFormControl.options['color'] : 'primary';
  }

  public getIconStyle(): Object {
    let style = {
      'color': this.phoenixFormControl.options['iconColor'] ? this.phoenixFormControl.options['iconColor'] : 'white',
    }
    return style;
  }
}

export class PhoenixDialogButton {
  public click: (args?: any) => void | Promise<void>;
  public color: string;
  public disabled: () => boolean;
  public label: string;
  public raised: boolean;
  public spinner: boolean;

  public constructor(phoenixDialogButton?: Partial<PhoenixDialogButton>) {
    this.color = 'primary';
    this.raised = false;
    this.spinner = false;
    this.disabled = (): boolean => false;
    Object.assign(this, phoenixDialogButton);
  }

  public async clickCallback(): Promise<void> {
    this.spinner = true;
    await this.click();
    this.spinner = false;
  }
}

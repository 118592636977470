import {JsonExportable} from '../../shared/classes/json-exportable';

export class PasswordReset implements JsonExportable {
  public token: string = undefined;
  public password: string = undefined;

  public constructor(passwordReset?: Partial<PasswordReset>) {
    Object.assign(this, passwordReset);
  }

  public toJSON(): Object {
    return {
      token: this.token,
      password: this.password,
    };
  }
}

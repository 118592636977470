<phoenix-dialog-new [color]="data.color" [buttons]="data.buttons" [subtitle]="data.subtitle" [title]="data.title">

  <phoenix-form [formGroup]="data.formGroup">
    <div fxLayout="column" fxLayoutGap="10px">
      <div *ngFor="let row of data.elements" class="phoenixDialogFormControlWrapper" fxLayout="row" fxLayoutGap="10px">
        <div *ngFor="let element of row" [ngSwitch]="element.type" fxFlex="">
          <phoenix-form-text *ngSwitchCase="elementType.Text" [element]="element"></phoenix-form-text>
          <phoenix-form-textarea *ngSwitchCase="elementType.TextArea" [element]="element"></phoenix-form-textarea>
          <phoenix-form-switch *ngSwitchCase="elementType.Switch" [element]="element"></phoenix-form-switch>
          <phoenix-form-icon-button *ngSwitchCase="elementType.IconButton"
            [element]="element"></phoenix-form-icon-button>
          <phoenix-form-password *ngSwitchCase="elementType.Password" [element]="element"></phoenix-form-password>
          <phoenix-form-dropdown *ngSwitchCase="elementType.DropDown" [element]="element"></phoenix-form-dropdown>
        </div>
      </div>


      <h3 *ngIf="registrationElements.length">{{ 'WIZARDS.SENSOR.REGISTRATIONPARAMS' | translate }}</h3>
      <div *ngFor="let element of registrationElements" [ngSwitch]="element.type" fxFlex="">
        <phoenix-form-text *ngSwitchCase="elementType.Text" [element]="element"></phoenix-form-text>
        <phoenix-form-switch *ngSwitchCase="elementType.Switch" [element]="element"></phoenix-form-switch>
      </div>

      <h3 *ngIf="configElements.length">{{ 'WIZARDS.SENSOR.SENSORCONFIG' | translate}}</h3>
      <h5 *ngIf="sensorTypeConfigInfoText">{{sensorTypeConfigInfoText | translate}}</h5>
      <div *ngFor="let element of configElements" [ngSwitch]="element.type" fxFlex="">
        <phoenix-form-text *ngSwitchCase="elementType.Text" [element]="element"></phoenix-form-text>
        <phoenix-form-switch *ngSwitchCase="elementType.Switch" [element]="element"></phoenix-form-switch>
      </div>

      <h3 *ngIf="confirmEditElements.length">{{ 'WIZARDS.SENSOR.CONFIRMATION.TITLE' | translate }}</h3>
      <h5 *ngIf="sensorTypeConfigInfoText">{{sensorTypeConfigInfoText | translate}}</h5>
      <span *ngIf="confirmEditElements.length" class="font-size-12 hint-text primary-300-fg mt-4 mb-4">{{
        'WIZARDS.SENSOR.CONFIRMATION.INFO' | translate }}
      </span><br />
      <div *ngFor="let element of confirmEditElements" [ngSwitch]="element.type" fxFlex="">
        <phoenix-form-text *ngSwitchCase="elementType.Text" [element]="element"></phoenix-form-text>
        <phoenix-form-switch *ngSwitchCase="elementType.Switch" [element]="element"></phoenix-form-switch>
      </div>


      <div *ngFor="let element of confirmElements" [ngSwitch]="element.type" fxFlex="">
        <phoenix-form-text *ngSwitchCase="elementType.Text" [element]="element"></phoenix-form-text>
        <phoenix-form-switch *ngSwitchCase="elementType.Switch" [element]="element"></phoenix-form-switch>
      </div>
    </div>

    <div>

    </div>


  </phoenix-form>

</phoenix-dialog-new>
export class AlarmCountDto {
    public monthYear: string = undefined;
    public activeInactiveCount: number = undefined;
    public closedCount: number = undefined;

    public constructor(alarmCountDto?: Partial<AlarmCountDto>) {
        Object.assign(this, alarmCountDto);
    }

}

import {Property} from '../../property/api/property';
import {JsonExportable} from '../../shared/classes/json-exportable';

import {AccountInfo} from './account-info';
import {UserConfig} from './user-config';

export class User implements JsonExportable {
  public accountInfo: AccountInfo = undefined;
  public company: string = undefined;
  public configurations: UserConfig[] = [];
  public email: string = undefined;
  public firstName: string = undefined;
  public id: string = undefined;
  public lastName: string = undefined;
  public properties: Property[] = undefined;
  public roles: { [key: string]: string[] } = undefined;

  public constructor(user?: Partial<User>) {
    Object.assign(this, user);
  }

  public toJSON(): Object {
    return {
      id: this.id,
      firstName: this.firstName,
      lastName: this.lastName,
      email: this.email,
      company: this.company,
      accountInfo: this.accountInfo,
      properties: this.properties,
      roles: this.roles,
      configurations: this.configurations,
    };
  }
}


import {TypeCount} from './type-count';

export class AssetStatistics {
  public activeNotificationTypesCount: TypeCount[];
  public inactiveNotificationTypesCount: TypeCount[];
  public violationCount: number;
  public positiveCount: number;
  public assetTypesCount: TypeCount[];

  public constructor(assetStatistics?: Partial<AssetStatistics>) {
    Object.assign(this, assetStatistics);
  }
}

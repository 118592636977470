<div fxLayout="row" class="font-size-16 fuse-white-fg" fxLayoutAlign="start center">
  <ng-container *ngFor="let breadcrumb of breadcrumbs; let last = last">
    <ng-container *ngIf="(breadcrumb.label | async) || breadcrumb.icon">
      <mat-icon *ngIf="breadcrumb.icon" class="s-16 notranslate" [routerLink]="breadcrumb.url">{{ breadcrumb.icon }}
      </mat-icon>
      <mat-spinner *ngIf="(breadcrumb.label | async) === null" [diameter]="15" class="fuse-white-fg"></mat-spinner>
      <span [routerLink]="breadcrumb.url">{{ breadcrumb.label | async | translate }}</span>
      <mat-icon *ngIf="!last" class="s-16 notranslate">chevron_right</mat-icon>
    </ng-container>
  </ng-container>
</div>
import { SearchCriteriaPageable } from '../../shared/classes/search-criteria-pageable';
import { isValid } from 'date-fns';

export class AssetSearchCriteria extends SearchCriteriaPageable {
  public name: string = undefined;
  public partialName: string = undefined;
  public tags: string[] = undefined;
  public sensorId: string = undefined;
  public measurementType: string = undefined;
  public parentId: string = undefined;
  public code: string = undefined;
  public thresholdId: string = undefined;
  public dateFrom?: Date = undefined;
  public dateTo?: Date = undefined;
  public assetId?: string = undefined;

  public constructor(assetSearchCriteria?: Partial<AssetSearchCriteria>) {
    super(assetSearchCriteria);
    Object.assign(this, assetSearchCriteria);
  }

  public toJSON(): Object {
    return {
      name: this.name,
      partialName: this.partialName,
      tags: this.tags,
      sensorId: this.sensorId,
      measurementType: this.measurementType,
      parentId: this.parentId,
      code: this.code,
      thresholdId: this.thresholdId,
      //dateFrom: this.dateFrom,
      //dateTo: this.dateTo,
      page: this.page,
      size: this.size,
      assetId: this.assetId,
      dateFrom: (this.dateFrom && isValid(this.dateFrom)) ? this.dateFrom.toISOString() : undefined,
      //dateFrom: (this.dateFrom && isValid(this.dateFrom)) ? moment(new Date(this.dateFrom)).toISOString(true) : undefined,
      dateTo: (this.dateTo && isValid(this.dateTo)) ? this.dateTo.toISOString() : undefined,
      //dateTo: (this.dateTo && isValid(this.dateTo)) ? moment(new Date(this.dateTo)).toISOString(true) : undefined,
    };
  }
}

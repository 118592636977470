import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';

import {SharedModule} from '../shared/shared.module';

import {StatisticsMapperService} from './mapper/statistics-mapper.service';
import {StatisticsApiService} from './services/statistics-api.service';

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
  ],
  providers: [
    StatisticsApiService,
    StatisticsMapperService,
  ],
})
export class StatisticsApiModule {
}

import { NgModule } from '@angular/core';
import { FuseSharedModule } from '@fuse/shared.module';
import { TranslateModule } from '@ngx-translate/core';
import { PhoenixIcon } from '@phoenix/enums/phoenix-icon.enum';
import { PhoenixCommunicationSubject } from '@phoenix/services/phoenix-communication-service/phoenix-communication-subject.enum';
import { PhoenixCommunicationService } from '@phoenix/services/phoenix-communication-service/phoenix-communication.service';
import { PhoenixCommonWizardService } from '@phoenix/services/phoenix-wizards/phoenix-common-wizard.service';
import { takeUntil } from 'rxjs/operators';

import { Permission, permissionIds } from '../../guards/permission';
import { navbarGroups } from '../../layout/components/navbar/navbar-groups/navbar-groups.module';
import { NavigationItem } from '../../navigation/classes/navigation-item';
import { NavigationService } from '../../navigation/navigation.service';
import { MainModule } from '../classes/main-module';


const permission: Permission = new Permission(permissionIds.TENANTADMINISTRATION.SETUP.read);

@NgModule({
  imports: [
    TranslateModule,
    FuseSharedModule,
  ],
  declarations: [],
})
export class SetupModule extends MainModule {
  private translationKey: string = 'NAV.SETUP';

  private navItem: NavigationItem = new NavigationItem(
    'wizard',
    0,
    true,
    this.translationKey,
    (): void => {
      this.phoenixCommonWizardService.openNewElementWizard();
    },
    PhoenixIcon.STAR_RATE,
  );

  public constructor(
    private navigationService: NavigationService,
    private communicationService: PhoenixCommunicationService,
    private phoenixCommonWizardService: PhoenixCommonWizardService,
  ) {
    super();
    this.communicationService.getObservable(PhoenixCommunicationSubject.AssetBranchChanged)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(() => this.navigationService.refreshNavigation(this.navItem, navbarGroups.tenantAdministration.id, permission));
  }
}

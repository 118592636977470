export class StatisticsSearchCriteriaDto {
  public measurementPointIds: number[] = undefined;
  public assetIds: string[] = undefined;
  public thresholdIds: number[] = undefined;
  public dateFrom: Date = undefined;
  public dateTo: Date = undefined;

  public constructor(statisticsSearchCriteriaDto?: Partial<StatisticsSearchCriteriaDto>) {
    Object.assign(this, statisticsSearchCriteriaDto);
  }
}

import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Observable, of } from 'rxjs';

import { AssetDto } from '../../../gapicon/asset/dto/asset-dto';

import { PhoenixAssetBranchSelectorDialogData } from './classes/phoenix-asset-branchselector-dialog-data';
import { PhoenixAsseBranchSelectorDialogComponent } from './phoenix-asset-branchselector-dialog.component';

@Injectable({
  providedIn: 'root',
})
export class PhoenixAssetBranchselectorDialogService {

  public constructor(
    private dialog: MatDialog,
  ) {
  }

  public showAssetBranchSelectorDialog(branches: AssetDto[], showCancelButton: boolean = false): Observable<AssetDto[]> {
    if (branches.length <= 1) {
      return of(branches);
    }

    const dialogRef: MatDialogRef<PhoenixAsseBranchSelectorDialogComponent> = this.dialog.open(PhoenixAsseBranchSelectorDialogComponent, {
      closeOnNavigation: false,
      autoFocus: false,
      disableClose: !showCancelButton,
      data: new PhoenixAssetBranchSelectorDialogData({
        assetBranchRootElement: branches,
        cancelButton: showCancelButton,
        title: 'WIZARDS.ASSETBRANCH.TITLE',
        subtitle: 'WIZARDS.ASSETBRANCH.SUBTITLE',
      }),
    });

    return dialogRef.afterClosed();
  }
}

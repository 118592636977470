import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

import { PhoenixSensormanagementInformationDialog } from '../classes/phoenix-sensormanagement-information-dialog';


@Component({
  selector: 'phoenix-sensormanagement-information-dialog',
  templateUrl: './phoenix-sensormanagement-information-dialog.component.html',
  styleUrls: ['./phoenix-sensormanagement-information-dialog.component.scss'],
})
export class PhoenixSensormanagementInformationDialogComponent implements OnInit {

  public constructor(
    @Inject(MAT_DIALOG_DATA) public data: PhoenixSensormanagementInformationDialog,
  ) {
  }

  public ngOnInit(): void {
  }
}
